import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import Footer from '../components/Footer';
import AdvBenft from '../display/AdvBenft';
import NavBar from '../navBar';



class AdvisorBenefits extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        const ParentProps = this.props.ParentProps
        return (
            <>
                <AdvBenft />
                {/* <AdsSystem ParentProps={ParentProps}/> */}
                <Footer parent="" styles={{marginTop:0}}/>
                <NavBar parent="" />
            </>
        );
    }
}

export default AdvisorBenefits;
