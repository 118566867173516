import React, { Component } from 'react';
import NavBar from '../navBar';
import Footer from '../components/Footer';
import TripPackages from '../display/TripPackages';

class PackagesG extends Component {
	componentDidMount() {
        window.scrollTo(0, 0)
      }

	render() {
		return (
			<div>
				{/* <PageTitle title="Packages"/> */}
				<TripPackages />
                <Footer parent="Packages" styles={{marginTop:0}}/>
                <NavBar parent="Packages" />
			</div>
		)
	}
}

export default PackagesG