import React from "react";


export default class Nav extends React.Component {
    render() {
        const { change_settingsFullScreen, hideSettings, fllScreen, addElement } = this.props.fn;
        const fllScreenIconStyle = {
            fontSize: fllScreen === true ? 28 : 15, paddingTop: fllScreen === true ? 0 : 2,
        };
        return (
            <div class="settings_nav">
                <div class="settings_indicator"></div>
                <div class="settings_content">
                    <p class="settings_nav_icons" onClick={() => { hideSettings() }} style={{ fontSize: 30, margin: 0 }}>
                        <i class="fa fa-times-circle"></i>
                    </p>
                    <p class="settings_nav_icons sni" onClick={() => { change_settingsFullScreen() }} style={fllScreenIconStyle}>
                        {fllScreen === true ? <i class="fa fa-mobile"></i> : <i class="fa fa-desktop"></i>}
                    </p>
                    <p class="settings_nav_icons sni" onClick={() => { addElement() }}>
                        <i class="fa fa-plus"></i>
                    </p>
                </div>
            </div>
        )
    }
}