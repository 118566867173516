
'use strict';

import React from 'react';
import Elements from '../../Settings/elements';
// import Elements from './elements';
const DATA_EG = {
    preset: "default",
    Title: "Social Injustice",
    subTitle: "EndSars",
    coverImage: require('../../assets/img/blog/6-1430x715.jpg'),
    Data: [
        {
            elementType: "text",
            value: "In June of this year an article was published entitled, “As the world marches for American victims, police brutality in Africa goes unnoticed”. That was the first time I realized that black americans were not the only people suffering with injustice. The black race as a whole has encountered injustice for over 2000 years. In 1994 my uncle was a victum of police brutality. He was shot and killed in front of my childhood home by the police. The police were never held accountable for his brutal killing. "
        },
        {
            elementType: "text",
            value: "In Nigeria, innocent young bodies are laying dead in the streets by senseless police brutality. Nerigian police officers justify their killings sighting self-defense after being provoked and attacked by the young men when they try to disperse them. Innocent on lookers protest stating these killings are not warrented because there is no provacation by the innocent that lie dead in the streets. "
        },
        {
            elementType: "text",
            value: "As protests mount to an all time precident high in Africa, the world joins in matching the same momentum that was used to speak out against the police brutality that killed the American George Floyd. George Floyd’s murder was captured on video and streamed live by onlookers. There was no reasonable explaniation for his death. Protests has since sparked normous changes to the law around America. What will the protests in Nigeria produce? Prior until two weeks ago, whenever anyone was killed by the police in Nigeria, fancy hashtag activism for justice trends for some days. We have entered a new era. Protesters from near and far have joined together to raise their voices in the streets of Nigeria and around the world. Thousands march and chant “Enough is Enough” against police brutality and violence. "
        },
        {
            elementType: "file-image",
            value: require('../../assets/img/blog/Social_Injustice_Blog2.png')
        },
        {
            elementType: "text",
            value: "Nigerians are campaigning for police reform and an end to bad goverance in Africa’s most populous country. They are calling for everyone to speak up and to speak out. ENDSAR!!!! Enough is Enough "
        },
        // {
        //     elementType: "text",
        //     value: ""
        // },
    ],
    metaData: {
        date: new Date().toDateString(),
        by: "admin",
        Tags: [
            { name: "Social Injustice" },
            { name: "EndSars" },
        ]
    },
}


class ElementCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsType: "",
            elements: [],
        }
    }



    render() {
        const { boostrapWidth, value, index, parentData } = this.props;
        const class_ = boostrapWidth === true ? "col-md-12 readSection_screen" : "col-md-4 readSection_box"
        const Preset = boostrapWidth === true ? this.returnPreset(value).screen : this.returnPreset(value).box;
        return (
            <>
                <div className={class_}>
                    <Elements elements={Preset} index={index} boostrapWidth={boostrapWidth} parentData={value} />
                    {/* <img style={{width: "100%", height: "40%", objectFit: "cover",}} /> */}
                </div>
            </>
        )
    }

    returnPreset = (value) => {
        if (value.preset === "default") {
            const Preset = this.defaultPreset(value);
            return Preset;
        } else if (value.preset === "manga") {
            const Preset = this.mangaPreset(value);
            return Preset;
        } {
            const Preset = this.defaultPreset(value);
            return Preset;
        }
    }

    defaultPreset = (value_) => {
        const value = value_
        // const value = DATA_EG
        const preveiwText = value.Data.filter(data => data.elementType === "text")[0].value;
        const data = {
            box: {
                elementType: "container",
                style: {
                    width: "100%",
                    height: 590,
                    backgroundColor: "rgba(255,255,255,0.4)",
                    padding: 10
                },
                class: "defaultPreset",
                children: [
                    {
                        elementType: "div",
                        style: {
                            width: "100%", height: "35%",
                            backgroundColor: "rgba(173, 170, 170, 0.4)",
                        },
                        class: "element_coverImage_div",
                        link: true,
                        children: [
                            {
                                elementType: "img",
                                style: {
                                    width: "100%", height: "100%", objectFit: "cover",
                                },
                                class: "element_coverImage_image",
                                value: value.coverImage,
                            },
                            {
                                elementType: "div",
                                style: {
                                    width: "100%", height: "100%", objectFit: "cover",
                                },
                                class: "element_coverImage_image_glass",
                                children: []
                            },
                        ]
                    },
                    {
                        elementType: "h4",
                        style: {
                            color: "black",
                            fontFamily: "sans-seri"
                        },
                        class: "",
                        value: value.Title,
                        link: true,
                    },
                    {
                        elementType: "h6",
                        style: {
                            color: "black",
                            fontFamily: "sans-seri"
                        },
                        class: "",
                        value: value.subTitle
                    },
                    {
                        elementType: "metaDataDiv",
                        style: { fontFamily: "inherit" },
                        class: "metaDataDiv",
                        value: ""
                    },
                    {
                        elementType: "p",
                        style: { position: "inherit" },
                        class: "element_p",
                        value: preveiwText.slice(0, 200) + "..."
                    },
                    // {
                    //     elementType: "div",
                    //     style: {
                    //         // width: "60%",
                    //         height: 200,
                    //         backgroundColor: "yellow",
                    //     },
                    //     class: "col-md-6",
                    //     children: [{ elementType: "gg" }]
                    // }
                ],
                metaData: value.metaData,
            },
            screen: {
                elementType: "container",
                hideDetailsButton: true,
                style: {
                    width: "100%",
                    // height: 590,
                    backgroundColor: "rgba(255,255,255,0.4)",
                    padding: 10
                },
                class: "",
                children: [
                    {
                        elementType: "h2",
                        style: {
                            color: "black",
                            fontFamily: "Impact",
                            marginTop: 0, color: "rgba(100, 10, 10, 0.8)"
                        },
                        class: "",
                        value: value.Title
                    },
                    {
                        elementType: "div",
                        style: {
                            // width: "100%", height: "35%",
                            backgroundColor: "rgba(173, 170, 170, 0.4)",
                        },
                        class: "element_coverImage_div",
                        link: true,
                        children: [
                            {
                                elementType: "img",
                                style: {
                                    width: "100%", objectFit: "cover",
                                },
                                class: "element_coverImage_image",
                                value: value.coverImage,
                            },
                            {
                                elementType: "div",
                                style: {objectFit: "cover",},
                                class: "dateTag",
                                children: []
                            },
                        ]
                    },
                    {
                        elementType: "shareMediaDiv",
                        style: { fontFamily: "inherit" },
                        class: "metaDataDiv",
                        value: ""
                    },
                    {
                        elementType: "div",
                        style: {
                            width: "100%",
                        },
                        class: "col-md-6",
                        children: value.Data.map((value,index,array) => {
                            const elementType = value.elementType === "file-image" ? "img" : "p" 
                            const style = value.elementType === "file-image" ? {width: "50%", objectFit: "cover", marginTop: 50, marginBottom: 30} : {marginTop: 10, marginBottom: 10} 
                            const class_ = value.elementType === "file-image" ? "" : "element_p" 
                            const value_ = value.elementType === "file-image" ? value.value : '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + value.value
                            return {
                                elementType, style, class:class_,value: value_
                            }
                        })
                    },
                    // {
                    //     elementType: "p",
                    //     style: { position: "inherit" },
                    //     class: "element_p",
                    //     value: "Reparer systemfeil i Windows enkelt Kjenner du til kommandoene Ping, Sfc /scannow og Chkdsk? I Ledetekst er det enkelt å utføre oppgaver som normalt er forbeholdt de største pc-ekspertene. Jeg går med planer om å skaffe meg en gps til primært sykkelbruk. Navigator Collapsible Backpack er en praktisk sammenleggbar dagstursekk. Interne låste side sporings strukturer…"
                    // },
                    // {
                    //     elementType: "div",
                    //     style: {
                    //         // width: "60%",
                    //         height: 200,
                    //         backgroundColor: "yellow",
                    //     },
                    //     class: "col-md-6",
                    //     children: [{ elementType: "gg" }]
                    // }
                ],
                metaData: value.metaData,
            }
        }
        return data;
    }

    mangaPreset = (value_) => {
        const value = value_
        // const value = DATA_EG
        const preveiwText = value.Data.filter(data => data.elementType === "text")[0].value;
        const data = {
            box: {
                elementType: "container",
                hideDetailsButton: true,
                style: {
                    width: "100%",
                    height: 500,
                    backgroundColor: "rgba(255,255,255,0.4)",
                    padding: 10,
                },
                class: "defaultPreset",
                children: [
                    {
                        elementType: "div",
                        style: {
                            width: "100%", height: "100%",
                            display: "flex"
                        },
                        class: "",
                        children: [
                            {
                                elementType: "div",
                                style: {
                                    width: "48%", height: "100%",
                                },
                                link: true,
                                class: "",
                                children: [
                                    {
                                        elementType: "div",
                                        style: {
                                            width: "100%", height: "100%",
                                            backgroundColor: "rgba(173, 170, 170, 0.4)",
                                        },
                                        class: "element_coverImage_div",
                                        children: [
                                            {
                                                elementType: "img",
                                                style: {
                                                    width: "100%", height: "100%", objectFit: "cover",
                                                },
                                                class: "element_coverImage_image",
                                                value: value.coverImage
                                            },
                                            {
                                                elementType: "div",
                                                style: {
                                                    width: "100%", height: "100%", objectFit: "cover",
                                                },
                                                class: "element_coverImage_image_glass",
                                                children: []
                                            },
                                        ]
                                    },
                                ]
                            },

                            {
                                elementType: "div",
                                style: {
                                    width: "50%", height: "100%",
                                    paddingLeft: 10, paddingRight: 10
                                },
                                class: "",
                                children: [
                                    {
                                        elementType: "h4",
                                        style: {
                                            color: "black",
                                            fontFamily: "sans-seri"
                                        },
                                        class: "",
                                        link: true,
                                        value: value.Title
                                    },
                                    {
                                        elementType: "h6",
                                        style: {
                                            color: "black",
                                            fontFamily: "sans-seri"
                                        },
                                        class: "",
                                        value: value.subTitle
                                    },
                                    {
                                        elementType: "metaDataDiv",
                                        style: { fontFamily: "inherit" },
                                        class: "metaDataDiv",
                                        value: ""
                                    },
                                    {
                                        elementType: "p",
                                        style: { position: "inherit", fontSize: "small", marginTop: 10 },
                                        class: "element_p",
                                        value: preveiwText.slice(0, 250) + "..."
                                    },
                                    {
                                        elementType: "Details_btn",
                                    },
                                ]
                            },

                            {
                                elementType: "div",
                                style: {
                                    width: "2%", height: "100%",
                                },
                                class: "",
                                children: [
                                    {
                                        elementType: "div",
                                        style: {
                                            width: "100%", height: "100%",
                                            backgroundColor: "rgba(173, 170, 170, 0.4)",
                                        },
                                        class: "element_coverImage_div",
                                        children: [
                                            {
                                                elementType: "img",
                                                style: {
                                                    width: "100%", height: "100%", objectFit: "cover",
                                                },
                                                class: "element_coverImage_image",
                                                value: value.coverImage
                                            },
                                            {
                                                elementType: "div",
                                                style: {
                                                    width: "100%", height: "100%", objectFit: "cover",
                                                },
                                                class: "element_coverImage_image_glass",
                                                children: []
                                            },
                                        ]
                                    },
                                ]
                            },
                        ]
                    },
                ],
                metaData: value.metaData,
            },
            screen: {
                elementType: "container",
                hideDetailsButton: true,
                style: {
                    width: "100%",
                    // height: 590,
                    backgroundColor: "rgba(255,255,255,0.4)",
                    padding: 10
                },
                class: "",
                children: [
                    {
                        elementType: "h2",
                        style: {
                            color: "black",
                            fontFamily: "Impact",
                            marginTop: 0, color: "rgba(100, 10, 10, 0.8)"
                        },
                        class: "",
                        value: value.Title
                    },
                    {
                        elementType: "div",
                        style: {
                            // width: "100%", height: "35%",
                            backgroundColor: "rgba(173, 170, 170, 0.4)",
                        },
                        class: "element_coverImage_div",
                        link: true,
                        children: [
                            {
                                elementType: "img",
                                style: {
                                    width: "100%", objectFit: "cover",
                                },
                                class: "element_coverImage_image",
                                value: value.coverImage,
                            },
                            {
                                elementType: "div",
                                style: {objectFit: "cover",},
                                class: "dateTag",
                                children: []
                            },
                        ]
                    },
                    {
                        elementType: "shareMediaDiv",
                        style: { fontFamily: "inherit" },
                        class: "metaDataDiv",
                        value: ""
                    },
                    {
                        elementType: "div",
                        style: {
                            width: "100%",
                        },
                        class: "col-md-6",
                        children: value.Data.map((value,index,array) => {
                            const elementType = value.elementType === "file-image" ? "img" : "p" 
                            const style = value.elementType === "file-image" ? {width: "50%", objectFit: "cover", marginTop: 50, marginBottom: 30} : {marginTop: 10, marginBottom: 10} 
                            const class_ = value.elementType === "file-image" ? "" : "element_p" 
                            const value_ = value.elementType === "file-image" ? value.value : '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + value.value
                            return {
                                elementType, style, class:class_,value: value_
                            }
                        })
                    },
                    // {
                    //     elementType: "p",
                    //     style: { position: "inherit" },
                    //     class: "element_p",
                    //     value: "Reparer systemfeil i Windows enkelt Kjenner du til kommandoene Ping, Sfc /scannow og Chkdsk? I Ledetekst er det enkelt å utføre oppgaver som normalt er forbeholdt de største pc-ekspertene. Jeg går med planer om å skaffe meg en gps til primært sykkelbruk. Navigator Collapsible Backpack er en praktisk sammenleggbar dagstursekk. Interne låste side sporings strukturer…"
                    // },
                    // {
                    //     elementType: "div",
                    //     style: {
                    //         // width: "60%",
                    //         height: 200,
                    //         backgroundColor: "yellow",
                    //     },
                    //     class: "col-md-6",
                    //     children: [{ elementType: "gg" }]
                    // }
                ],
                metaData: value.metaData,
            }
        }
        return data;
    }
};



export default ElementCard;


