import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import Footer from '../components/Footer';
import BKTravel from '../display/BKTravel';
import NavBar from '../navBar';



class BookTravel extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        const ParentProps = this.props.ParentProps
        return (
            <>
                <BKTravel />
                <AdsSystem ParentProps={ParentProps} parent="BookTravel"/>
                <Footer parent="BookTravel" styles={{marginTop:0}}/>
                <NavBar parent="BookTravel" />
            </>
        );
    }
}

export default BookTravel;
