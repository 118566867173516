import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import "./App.css";

export default function SripeDummyView({ props }) {
  const { selectedOption1, selectedOption2, selectedOption3, total, subTotal, checkOut } = props;
  return (
    <div className="SripeDummyView">
      <div className="SripeDummyView_">
        <div className="SripeDummyView_head">
          <div className="SripeDummyView_head_col">
            <div className="SripeDummyView_head_col_ _"></div>
          </div>
          <div className="SripeDummyView_head_col">
            <div className="SripeDummyView_head_col_"></div>
          </div>
          <div className="SripeDummyView_head_col">
            <div className="SripeDummyView_head_col_"></div>
          </div>
          <div className="SripeDummyView_head_col">
            <div className="SripeDummyView_head_col_"></div>
          </div>
        </div>
        <div className="SripeDummyView_head_sub"></div>
        <div className="SripeDummyView_body"></div>
        <div className="SripeDummyView_body_foot_intermediate">
          <div className="SripeDummyView_body_foot_intermediate_"></div>
          <div className="SripeDummyView_body_foot_intermediate_"></div>
        </div>
        <div className="SripeDummyView_head_sub" style={{ height: 5, width: "100%" }}></div>
        <div className="SripeDummyView_body_foot"></div>
      </div>
      <div className="SripeDummyView_absolute">
        <h1>Click the Finish Up button to proceed</h1>
        <p>
          By clicking the check-out button you are proceeding with the payment of <span style={{ fontWeight: "bold" }}>${total}</span>,
        </p>
        <p>
          Package Type: <span style={{ fontWeight: "bold" }}>{selectedOption1 ? selectedOption1.label : "Full Pacakge - Includes Flight from New York"}</span>,
        </p>
        <p>Occupancy Type:  <span style={{ fontWeight: "bold" }}>{selectedOption2 ? selectedOption2.label : "Single Occupancy"}</span></p>
      </div>
    </div>
  );
}