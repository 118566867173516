import React, { Component } from 'react';
import '../../App.css';
import FadingGradient from '../components/FadingGradient';
import Heading from '../components/Heading';
import ImageComp from '../components/ImageComp';
import LatestNews from '../components/LatestNews';
import { Link } from 'react-router-dom';



class BKTravel extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            headings: [
                {
                    title: "Book your travel with us today!",
                    titleStyle: { marginTop: 20, textAlign: "center" },
                    text: [{ t: "Our advisors are jetsetters with firsthand knowledge and experience to elevate your travel to the next level. We specialize in African tourism, but we are more than capable of booking any geographical travel tour that you or your group desires. We design signature travel with you or your group in mind. ", type: "text", url: "" }],
                    linkText: "",
                    path: "",
                    style: "boldDialogue",
                    externalUrlName: "Book Travel",
                    externalUrlIcon: 'fa fa-arrow-right',
                    externalUrl: "https://latangelarogers.inteletravel.com/booktravel.cfm",
                    noHR: true,
                    // CSS: {paddingBottom: 30}
                },
            ]
        }
    }

    render() {
        const { headings } = this.state
        const FirstHeading = headings.slice(0, 2);
        const secondHeading = headings.slice(2, 3);
        return (
            <>
                <ImageComp imgUrl={require('../../assets/img/Kingdom_Travel_Tours_facebook_cover.jpg')} />
                {/* <FadingGradient color1="white" color2="rgb(207,207,207)"/> */}
                <div className='BTT_container_absoluteBG'></div>
                <div className='BTT_container'>
                    <div className='BTT_container_absoluteBG2'></div>
                    <div className='BTT_container_sub'>
                        {/* <Heading value={FirstHeading[0]} /> */}
                        <div class="heading">
                            <h1 className='BCS_H'>{FirstHeading[0].title}</h1>
                            <div className='section' style={{ marginLeft: 0 }}>
                                <p>{FirstHeading[0].text[0].t}</p>
                            </div>
                        </div>
                        <Tours />
                        <Line marginBottom={0} width={"70%"} />
                        <OtherTours />
                    </div>
                    <div className='BTT_container_footer'>
                        <div className='BTT_container_footer_sub'>
                            <div className='BCFS_Advisor'>
                                <div className='BCFS_image'></div>
                                <div className='BCFS_info'>
                                    <h1>LaTangela Rogers</h1>
                                    <h6>Independent Travel Advisor</h6>
                                </div>
                            </div>
                            <div className='BCFS_contact'>
                                <h5>About LaTangela</h5>
                                <p>
                                    LaTangela’s journey through Africa is what pushed her to becoming a Certified Travel Agent (CTA). 
                                    She has been coaching more than 10 years. Her coaching expertise serves as guide for her to plan 
                                    extraordinary travel experiences.
                                </p>
                                <a href='mailto:kingdomliving.traveltours@gmail.com'>kingdomliving.traveltours@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {FirstHeading.map((value,index,array) => {
                    return(
                        <Heading value={value}/>
                    )
                })} */}
                {/* <FadingGradient color1="rgb(207,207,207)" color2="white"/> */}
                {/* <ImageComp imgUrl={require('../../assets/img/Kingdom_Living_Travel__Tours_Flyer_Design.png')}/> */}
                {/* <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)"/> */}

                {/* <div class={'App__'}></div> */}
            </>
        );
    }
}

export default BKTravel;



class Tours extends React.Component {
    constructor() {
        super();
        this.state = {
            slides: [
                {
                    index: 1,
                    title: "Customized",
                    titleSpan: "Travel Tours",
                    subTitle: "Book your travel with us today!",
                    info: "Our advisors are jetsetters with firsthand knowledge and experience to elevate your travel to the next level.",
                    link: "/BookTravel",
                    linkText: "Read More",
                    spanInvert: false,
                },
                {
                    index: 2,
                    title: "March 24 -",
                    titleSpan: "April 3, 2024",
                    subTitle: "Ghana 2024Trip",
                    info: "Accra, Kumasi, and Cape Coast. Come and experience the finer side of life- as we welcome you home to the Motherland.",
                    link: "/Ghana_2024Trip",
                    linkText: "Book Now",
                    spanInvert: true,
                },
                {
                    index: 3,
                    title: "Investment Tours:",
                    titleSpan: "Cape Town",
                    subTitle: "February 03- 09, 2024",
                    info: "Explore the fascinating world of mining in Cape Town, South Africa, with our exclusive Mining Tour Package.",
                    link: "/Cape_Town_Investment_Tour",
                    linkText: "Book Now",
                    spanInvert: false,
                },
                {
                    index: 4,
                    title: "Investment Tours:",
                    titleSpan: "Girlz Just wanna have fun ZANZIBAR",
                    subTitle: "3 May 2024 - 12 May 2024",
                    info: "Experiences a relaxing seaside vacation on the picturesque island paradise of Zanzibar, an underwater tour and more ",
                    link: "/Cape_Town_Investment_Tour",
                    linkText: "Book Now",
                    spanInvert: false,
                },
                // {
                //     index: 4,
                //     title: "Due Diligence Support:",
                //     info: "Assistance with conducting due diligence on investment opportunities, including regulatory compliance and risk assessment.",
                //     link: "",
                // },
                // {
                //     index: 5,
                //     title: "Networking and Partnership Facilitation:",
                //     info: "Facilitation of strategic partnerships between investors and tourism operators, hoteliers, and other stakeholders.",
                //     link: "",
                // },
                // {
                //     index: 6,
                //     title: "Sustainability and Impact:",
                //     info: "Emphasis on sustainable tourism practices and investment in projects that promote environmental conservation and community development.",
                //     link: "",
                // },
            ]
        }
    }

    render() {
        const { slides } = this.state
        return (
            <div className='Tours_'>
                <div className='ToursRow row d-sm-flex justify-content-sm-center'>
                    <div className='col-md-6 ToursCol'>
                        <div className='ToursCol_child'>
                            <div className='TCSlide_Ghana TCSlide'>
                                <div className='TCS1 TCS'>
                                    <Link to="/Ghana_2024Trip">Book Now</Link>
                                </div>
                                <div className='TCS2 TCS'>
                                    <h2>{slides[1].title}<span> {slides[1].titleSpan}</span></h2>
                                    <h1>{slides[1].subTitle}</h1>
                                    <h3>{slides[1].info}</h3>
                                </div>
                                <div className='TCS3 TCS'>
                                    <Link to="/Ghana_2024Trip">Book Now</Link>
                                </div>
                                <div className='TCS_Dark'></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ToursCol'>
                        <div className='ToursCol_child'>
                            <div className='TCSlide_Cape TCSlide'>
                                <div className='TCS1 TCS'>
                                    <Link to="/Cape_Town_Investment_Tour">Book Now</Link>
                                </div>
                                <div className='TCS2 TCS'>
                                    <h2>{slides[2].subTitle}</h2>
                                    <h1>{slides[2].titleSpan} Trip</h1>
                                    <h3>{slides[2].info}</h3>
                                </div>
                                <div className='TCS3 TCS'>
                                    <Link to="/Cape_Town_Investment_Tour">Book Now</Link>
                                </div>
                                <div className='TCS_Dark'></div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 ToursCol'>
                        <div className='ToursCol_child'>
                            <div className='TCSlide_Tanz TCSlide'>
                                <div className='TCS1 TCS'>
                                    <Link to="/GIRLZ_JUST_WANNA_HAVE_FUN_ZANZIBAR_2024">Book Now</Link>
                                </div>
                                <div className='TCS2 TCS'>
                                    <h2>{slides[3].subTitle}</h2>
                                    <h1 style={{transform: "scale(0.7)", textAlign: "center"}}>{slides[3].titleSpan}</h1>
                                    <h3>{slides[3].info}</h3>
                                </div>
                                <div className='TCS3 TCS'>
                                    <Link to="/GIRLZ_JUST_WANNA_HAVE_FUN_ZANZIBAR_2024">Book Now</Link>
                                </div>
                                <div className='TCS_Dark'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Line extends React.Component {
    render() {
        const { width, height, color, marginTop, marginBottom } = this.props
        // const style = {}
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: marginTop ? marginTop : 10, marginBottom: marginBottom ? marginBottom : 10 }}>
                <div style={{ width: width ? width : "50%", height: height ? height : 1, backgroundColor: color ? color : "brown", borderRadius: 2 }}></div>
            </div>
        )
    }
}


class OtherTours extends React.Component {
    constructor() {
        super();
        this.state = {
            deals: [
                { id: 1 },
                { id: 2 },
                { id: 3 },
                { id: 4 }
            ]
        }
    }

    renderDeals = (val) => {
        return (
            <div className='OtherTours_deals'>
                <div className={'OtherTours_deals_ OtherTours_deals_' + val.id}>
                    {/* <h1>{val.id}</h1> */}
                </div>
            </div>
        )
    }

    render() {
        const { deals } = this.state
        return (
            <div className='OtherTours'>
                <h1>Hot Deals</h1>
                <Line color={"rgba(255,255,255,0.1)"} width={"45%"} />
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-4 row-cols-xxxl-4 d-sm-flex">
                    {deals.map((val) => {
                        return this.renderDeals(val)
                    })}
                </div>
                <Line color={"rgba(255,255,255,0.3)"} width={"10%"} />
            </div>
        )
    }
}
