import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SearchField from "react-search-field";
import SearchBar from 'search-bar-react'


export default class FadingGradient extends React.Component {



    render() {
        const { color1, color2, height} = this.props;
        const height_ = height ? height : null
        const linearGradient = 'linear-gradient(to bottom, ' + color1 + ',' + color2 + ')'
        return (
            <section class="FadingGradient" style={{backgroundImage: linearGradient, height: height_}}>
            </section>
        )
    }
}


