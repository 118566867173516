import React, { Component } from 'react';
import '../App.css';
// import {
//     BrowserRouter as Router,
//     Route,
//     Switch,
//     Redirect
// } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './Screens/HomePage';
import AboutMe from './Screens/AboutMe';
import ExecutiveTourism from './Screens/ExecutiveTourism';
import ThePathwayToGhana from './Screens/ThePathwayToGhana';
import BookTravel from './Screens/BookTravel';
import AdvisorBenefits from './Screens/AdvisorBenefits';
import Blog from './Screens/Blog';
import Blogs from './Screens/Blogs';
import StartupPage from './Screens/StartupPage';
import Contact from './Screens/Contact';
import Blogs_ from './Screens/Blogs_';
import OrderForm from './Screens/OrderForm';
import PackagesG from './Screens/Packages';
import PackagesC from './Screens/PackageC';
import PackageZ from './Screens/PackageGZ';

const text1 = `From a young age, I was creative and technical. I often built toy cars from carton box whenever we wanted 
to visit the orphanage. With transparent plastic from “perfume-box” I made the windscreen and the door-glasses. 
For the headlamps, I attached small bulbs to a small HW-battery 
with cables using a technique I learnt in school, and put them in the toy car. This meant that the 
light would always be on, and visible through the windscreen and door-glasses, I was fine with that. 
Although it took hours to finish, I was always satisfied with my 
work. And with the continuous encouragement from my parents I kept improving and later I took a liking to information 
technology. In my few years as a computer-scientist I have been able to pour my heart out into creative designs and 
implementations, and I still feel the urge to dive deeper and explore. `

const text = `I believe that IT(information technology) is the future. And I know that I am going to play a very big 
role in our technological advancement. In my country we have students like myself who have less opportunities to 
express our skills and talent, this has caused some to embark on negative approaches. Negative approaches like hacking, 
internet fraud and some more, but I intend to dilute all this by providing opportunities for them to use their tallents skills 
in a more positive approach. With the necessary skills acquired, I intend to gather tech scientist like myself, forming 
a large group of skilled scientist, and our goal would be to improve the technological society by inventing new systems, 
building on bright ideas and embarking on numerous technological adventures like building AI (Artificial Intelligence) that 
can implemented in banking systems, transportation systems, digital markets, cyber security, and so on. `

const text3 = `While I study, I intend to also conduct some Cultural/Biblical seminars. Sharing the Nigerian culture and how 
our forefathers were introduced to christ. How it caused conflicts and spiritual battles back in their time. Being in a 
home where both parents are Priests, it is assumed that automatically I’m supposed to be a believer, but that wasn’t the case 
until I was 15 of age. With the few years I’ve spent as believer I understand the conflicts of mental processes on decisions, 
especially with the cultural beliefs and resentments of the christian believes with the argument that it is a foreign religion. 
I intend to share all these in form of a story which would be divided into sermons where at the end of each session, group 
members can discuss their opinions on certain events in the story, and the title to the story is “God by the gods”. `


class App_ extends Component {
    constructor() {
        super();
        this.state = {
            adsClosedByUser: false,
            currentAdAnimProgress: 0,
            newsletterClosedByUser: true,
            currentAdAnimProgress: 0,
            showWriteEmail: false,
            subscribed: false,
        }
    }

    closeAds = () => {
        this.setState({ adsClosedByUser: true });
    }
    NewsLetter_close = () => {
        this.setState({ newsletterClosedByUser: true });
    }
    NewsLetter_open = () => {
        this.setState({ newsletterClosedByUser: false });
    }
    emailToggle = () => {
        const { showWriteEmail } = this.state
        this.setState({ showWriteEmail: !showWriteEmail });
    }
    update_currentAdAnimProgress = () => {
        const { currentAdAnimProgress } = this.state;
        const newProgress = currentAdAnimProgress >= 7 ? 0 : currentAdAnimProgress + 1;
        this.setState({ currentAdAnimProgress: newProgress })
    }

    render() {
        const fn = {
            closeAds: this.closeAds, up_CAAP: this.update_currentAdAnimProgress,
            NewsLetter_close: this.NewsLetter_close, NewsLetter_open: this.NewsLetter_open,
            emailToggle: this.emailToggle
        }
        const ParentProps = { fn: fn, state: this.state, userData: this.props.userData }
        return (
            <>
                <Router>
                    {/* <Switch>
                        <Route exact path="/" >
                            <HomePage />
                        </Route>
                        <Redirect from='*' to='/finishSetingAccount' />
                    </Switch> */}

                    <Routes>
                        <Route exact path="/" element={<HomePage ParentProps={ParentProps} />} />
                        <Route exact path="/Home" element={<HomePage ParentProps={ParentProps} />} />
                        <Route exact path="/AboutMe" element={<AboutMe ParentProps={ParentProps} />} />
                        <Route exact path="/ExecutiveTourism" element={<ExecutiveTourism ParentProps={ParentProps} />} />
                        <Route exact path="/ThePathwayToGhana" element={<ThePathwayToGhana ParentProps={ParentProps} />} />
                        <Route exact path="/BookTravel" element={<BookTravel ParentProps={ParentProps} />} />
                        <Route exact path="/AdvisorBenefits" element={<AdvisorBenefits ParentProps={ParentProps} />} />
                        <Route exact path="/Blogs/:id" element={<Blogs ParentProps={ParentProps} />} />
                        <Route exact path="/Blogs" element={<Blogs ParentProps={ParentProps} noFilter={true} />} />
                        <Route exact path="/blog/:id" element={<Blog ParentProps={ParentProps} />} />
                        <Route exact path="/Blogs_/:id" element={<Blogs_ ParentProps={ParentProps} />} />
                        <Route exact path="/Contact" element={<Contact ParentProps={ParentProps} />} />
                        <Route exact path="/Ghana_2024Trip" element={<PackagesG ParentProps={ParentProps} />} />
                        <Route exact path="/CheckOut_Page" element={<OrderForm ParentProps={ParentProps} />} />
                        <Route exact path="/Cape_Town_Investment_Tour" element={<PackagesC ParentProps={ParentProps} />} />
                        <Route exact path="/GIRLZ_JUST_WANNA_HAVE_FUN_ZANZIBAR_2024" element={<PackageZ ParentProps={ParentProps} />} />
                        <Route path="*" element={<HomePage ParentProps={ParentProps} />} />
                        {/* <Route exact path="/login" element={<Login />} />
                        <Route exact path="/recovery-password" element={<RecoveryPassword />} />
                        <Route path="*" element={<NotFound />} /> */}
                    </Routes>
                </Router>

            </>
            // <>
            //     <h1>{text.split(' ').length-1} words</h1>
            //     <p style={{color: "black"}}>{text}</p>
            // </>
        )
    }
}

export default App_;
