import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import Footer from '../components/Footer';
import MainScreen from '../display/mainScreen';
import NavBar from '../navBar';



class HomePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        const ParentProps = this.props.ParentProps
        return (
            <>
                <MainScreen />
                {/* <AdsSystem ParentProps={ParentProps} parent="home"/> */}
                <Footer parent="home" />
                <NavBar parent="home" />
            </>
        );
    }
}

export default HomePage;
