import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SearchField from "react-search-field";
import SearchBar from 'search-bar-react'
import { OFFLINE_DB } from "../../presets";
import ElementCard from "./ElementCard";
import Heading from "./Heading";


export default class READ extends React.Component {

    constructor() {
        super();
        this.state = {
            bubble: 0,
            data: [],
            SearchBar_focused: false,
            filter: "",
            headings: [
                {
                    title: "",
                    text: [
                        { t: "", type: "image", url: require('../../assets/img/paige_cody_ITTqjS3UpoY_unsplash.jpg'), style: { width: "100%" } },
                        { t: "Become an Advisor", type: "heading", url: "", style: { textAlign: "left", color: "white", fontSize: 40, paddingTop: 20, paddingBottom: 7, fontFamily: "Verdana", letterSpacing: "normal", paddingLeft: 0 } },
                        { t: "Our travel Advisors earn a commission from partner brands on each trip they sell, but the profession is so much more rewarding than that. When you become an Advisor with InteleTravel, you’ll find joy in building unique experiences for your friends, family, and followers while growing your own passion for travel, education, and personal development—not to mention your business. ", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, paddingLeft: 0, width: "97%", } },
                        { t: "Read more", type: "link", linkType: "internal", url: "/AdvisorBenefits", style: { textAlign: "left", color: "white", fontSize: 13, marginTop: 40, paddingBottom: 7, fontWeight: "bold" } },
                    ],
                    linkText: "",
                    path: "",
                    style: "ColumnRead2",
                    columnType: 'col-md-6',
                    columnStyle: { overflow: "hidden", paddingLeft: 0, paddingTop: 0, marginTop: 0 },
                    columnContainerStyle: { overflow: "hidden", paddingLeft: 0, paddingTop: 0 },
                    columnArray: [{ start: 0, finish: 1 }, { start: 1, finish: 4 }],
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundColor: "rgb(219,53,57)", backgroundImage: "linear-gradient(to right, rgb(0, 15, 130),rgb(73, 0, 220))", paddingBottom: 20, paddingTop: 0, overflow: "hidden", paddingLeft: 0 },
                    noHR: true,
                },
                {
                    title: "Book your travel with us today!",
                    text: [
                        { t: "Our advisors are jetsetters with firsthand knowledge and experience to elevate your travel to the next level. We specialize in African tourism ...", type: "text", url: "", style: { color: "white", paddingLeft: 0, paddingRight: window.innerWidth >= 760 ? 60 : 0, width: "100%", marginLeft: 0, } },
                        { t: "READ MORE", type: "link", linkType: "internal", url: "/BookTravel", style: { textAlign: "left", color: "white", fontSize: 13, marginTop: 40, paddingBottom: 7, fontWeight: "bold", marginLeft: 0, marginRight: window.innerWidth >= 760 ? 20 : 0, } },
                    ],
                    linkText: "",
                    path: "",
                    style: "img_Card",
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: {
                        backgroundImage: "url(" + require('../../assets/img/Kingdom_Living_Travel__Tours_Flyer_Design_cop_.jpg') + ")",
                        height: window.innerHeight * 0.6, overflow: "hidden",
                        justifyContent: "center", alignItems: "center", display: "flex",
                        backgroundSize: "cover", backgroundPosition: "center", marginTop: 30, marginBottom: 30,
                    },
                    titleStyle: { color: "white", fontSize: 60, textAlign: "center", textTransform: "capitalize", paddingRight: 4, paddingLeft: 4 },
                    noHR: true
                },
                {
                    title: "READ OTHER TOPICS",
                    text: [],
                    linkText: "",
                    path: "",
                    style: "READ",
                    externalUrlName: "",
                    externalUrl: "",
                },
            ],
            filteredResultEmpty: false
        }
    }

    SearchBar_focused = () => {
        this.setState({ SearchBar_focused: true });
    }
    SearchBar_blured = () => {
        this.setState({ SearchBar_focused: false })
    }

    componentDidMount() {
        const filter = this.props.filter ? this.props.filter : ""
        this.setState({ filter });
        // alert(JSON.stringify(OFFLINE_DB[0].metaData.Tags.name))
        if (this.props.filter) {
            const filterdJSON = OFFLINE_DB.map((value, index, array) => {
                const names = value.metaData.Tags.map(el => el.name);
                if (names.includes(filter)) {
                    return value;
                } else {
                    return "null"
                }
            })
            const responseJSON = filterdJSON.filter(el => el !== "null");
            // alert(JSON.stringify(responseJSON))
            this.setState({ data: responseJSON, filteredResultEmpty: responseJSON.length >= 1 ? false : true })
        } else {
            this.setState({ data: OFFLINE_DB })
        }
    }


    render() {
        const { filter, data, filteredResultEmpty, headings } = this.state;
        const filtering = this.props.filter ? true : false
        const h_1 = headings.slice(0, 2);
        const h_2 = headings.slice(2, 3);
        // const data = OFFLINE_DB
        return (
            <>
                <div className="section container READ_Search">
                    <SearchBar
                        onChange={(text) => console.log(text)}
                        onFocus={() => this.SearchBar_focused()}
                        size='large'
                        width='60%'
                        // autoFocus
                        placeholder={"Type and Search"}
                        onClear={() => this.SearchBar_blured()}
                        value=''
                    />
                </div>
                {filtering ? <p style={{ marginTop: 20 }}>Showing results for <span style={{ fontWeight: "bold" }}>{filter}</span></p> : null}
                <div className="readSection">
                    {data.map((value, index, array) => {
                        return (
                            <ElementCard boostrapWidth={false} value={value} index={index} parentData={value} />
                        )
                    })}
                </div>
                {filteredResultEmpty ?
                    <>
                        <div class="filteredResultEmpty">
                            <p style={{ fontSize: 30, paddingLeft: 0, paddingRight: 0, }}><i class="fa fa-frown-o"></i> No results found for <span style={{ fontWeight: "bold" }}>{filter}</span></p>
                            <p style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 20 }}>Read Also:</p>
                            {h_1.map((value, index, array) => {
                                return (
                                    <Heading value={value} />
                                )
                            })}
                        </div>
                        {h_2.map((value, index, array) => {
                            return (
                                <Heading value={value} />
                            )
                        })}
                    </>
                    :
                    null
                }
            </>
        )
    }
}


