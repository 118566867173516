import React from "react";
import { Link, Navigate } from "react-router-dom";
import '../assets/css/styles.css'

class Elements extends React.Component {
    constructor() {
        super();
        this.state = {
            startNavigate: false,
            NavigateTo: "",
        }
    }

    renderSettings_edit = (id) => {
        // alert("heuuuu")
        // const settings_edit = document.getElementById(id);
        // settings_edit.style.display = "flex"
    }
    closeSettings_edit = (id) => {
        // const settings_edit = document.getElementById(id);
        // settings_edit.style.display = "none"
    }
    navigate = (NavigateTo) => {
        this.setState({ startNavigate: true, NavigateTo, })
    }
    do_nothing = (id) => {
        // const settings_edit = document.getElementById(id);
        // settings_edit.style.display = "none"
    }
    socialLink = (link) => {
        window.open(link, '_blank');
    }

    render() {
        const { elements, boostrapWidth, index, parentData } = this.props
        const { NavigateTo, startNavigate } = this.state;
        const col_md_12 = boostrapWidth ? "" : " hunnidprcnt"
        const hideDetailsButton = elements.hideDetailsButton ? elements.hideDetailsButton : false
        const hhh = []
        return (
            <>
                {startNavigate ? <Navigate to={NavigateTo} /> : null}
                {elements.elementType === "container" ?
                    <div class={"elements container " + elements.class + col_md_12} style={elements.style}>
                        {elements.children ?
                            elements.children.map((item, index_, array) => {
                                return (<Elements elements={item} index={index + "_" + index_} boostrapWidth={boostrapWidth} parentData={parentData} />)
                            })
                            :
                            null}
                        {hideDetailsButton ? null : <p class="details" onClick={() => { this.navigate('/blog/' + parentData.Title.replace(' ', '_')) }}>Details <i class="fa fa-caret-right"></i></p>}
                    </div>
                    :
                    elements.elementType === "metaDataDiv" ?
                        <div class={"metaDataDiv " + elements.class + col_md_12} style={elements.style}>
                            {parentData.metaData.Tags.map((value, i, a) => {
                                const comma = a.length >= 2 ? i === a.length - 1 ? '' : ',' : ''
                                return (
                                    <Link to={'/Home'}>{value.name}{comma} </Link>
                                )
                            })}
                            <i class="fa fa-dot-circle-o"></i>
                            <Link to={'/Home'}>by {parentData.metaData.by}</Link>
                            <i class="fa fa-dot-circle-o"></i>
                            <Link to={'/Home'}>{parentData.metaData.date}</Link>
                            <i class="fa fa-dot-circle-o"></i>
                            <Link to={'/Home'}>Leave a comment</Link>
                        </div>
                        :
                        elements.elementType === "shareMediaDiv" ?
                            <div class={"shareMediaDiv " + elements.class + col_md_12} style={elements.style}>
                                <div class="Facebook" onClick={() => { this.socialLink('https://www.facebook.com/sharer/sharer.php?u=' + 'https://dating-african.com/blog/' + parentData.Title.replace(' ', '_') +  '/&t=' + parentData.Title.replace(' ', '+')) }}>
                                    <img src={require('../assets/img/Icons/icons8-glyph-neue-icons8-glyph-neue-Facebook.png')} />
                                    <p>Facebook</p>
                                </div>
                                <div class="Twitter" onClick={() => { this.socialLink('https://dating-african.com/') }}>
                                    <img src={require('../assets/img/Icons/icons8-ios_filled-icons8-ios_filled-Twitter Squared.png')} />
                                    <p>Twitter</p>
                                </div>
                                <div class="Google" onClick={() => { this.socialLink('https://plus.google.com/share?url='+ 'https://dating-african.com/blog/' + parentData.Title.replace(' ', '_') + '/') }}>
                                    <img src={require('../assets/img/Icons/icons8-iOS Glyph-Google Plus.png')} />
                                    <p>Google+</p>
                                </div>
                                <div class="Pinterest" onClick={() => { this.socialLink('https://dating-african.com/') }}>
                                    <img src={require('../assets/img/Icons/icons8-iOS Glyph-Pinterest.png')} />
                                    <p>Pinterest</p>
                                </div>
                                <div class="LinkedIn" onClick={() => { this.socialLink('https://www.linkedin.com/shareArticle?mini=true&ro=true&trk=EasySocialShareButtons&title='+parentData.Title.replace(' ', '+')+'&url=' +'https://dating-african.com/blog/' + parentData.Title.replace(' ', '_') +'/') }}>
                                    <img src={require('../assets/img/Icons/icons8-iOS Glyph-LinkedIn.png')} />
                                    <p>LinkedIn</p>
                                </div>
                            </div>
                            :
                            elements.elementType === "div" ?
                                <div class={"div " + elements.class + col_md_12} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>
                                    {elements.children ?
                                        elements.children.map((item, index_, array) => {
                                            return (<Elements elements={item} index={index + "_" + index_} boostrapWidth={boostrapWidth} parentData={parentData} />)
                                        })
                                        :
                                        null}
                                </div>
                                :
                                elements.elementType === "p" ?
                                    <p class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>{elements.value}</p>
                                    :
                                    elements.elementType === "h1" ?
                                        <h1 class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>{elements.value}</h1>
                                        :
                                        elements.elementType === "h2" ?
                                            <h2 class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>{elements.value}</h2>
                                            :
                                            elements.elementType === "h3" ?
                                                <h3 class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>{elements.value}</h3>
                                                :
                                                elements.elementType === "h4" ?
                                                    <h4 class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>{elements.value}</h4>
                                                    :
                                                    elements.elementType === "h5" ?
                                                        <h5 class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>{elements.value}</h5>
                                                        :
                                                        elements.elementType === "h6" ?
                                                            <h6 class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}>{elements.value}</h6>
                                                            :
                                                            elements.elementType === "img" ?
                                                                <img src={elements.value} class={elements.class} style={elements.style} onClick={() => { elements.link ? this.navigate('/blog/' + parentData.Title.replace(' ', '_')) : this.do_nothing() }}></img>
                                                                :
                                                                elements.elementType === "Details_btn" ?
                                                                    <p class="details" onClick={() => { this.navigate('/blog/' + parentData.Title.replace(' ', '_')) }}>Details <i class="fa fa-caret-right"></i></p>
                                                                    :
                                                                    <p class="elements small">Unknown Element</p>
                }
            </>
        )
    }
}

export default Elements