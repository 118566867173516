import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import Footer from '../components/Footer';
import AboutScreen from '../display/aboutScreen';
import NavBar from '../navBar';



class AboutMe extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        const ParentProps = this.props.ParentProps
        return (
            <>
                <AboutScreen />
                <AdsSystem ParentProps={ParentProps} parent="aboutme"/>
                <Footer parent="aboutme" />
                <NavBar parent="aboutme" />
            </>
        );
    }
}

export default AboutMe;
