import React, { Component, } from 'react';
import NavBar from '../navBar';
import Footer from '../components/Footer';
import CheckOut from '../display/CheckOut';
import { useLocation } from 'react-router-dom';



const OrderForm = props => {
	const location = useLocation()
	return <OrderForm_ location={location} {...props} /> // your component
  }

class OrderForm_ extends Component {
	constructor() {
		super();
		this.state = {
			page: "",
			pageLoading: true,
			checkOutCode: "",
		}
		this.WV_VRef = null;
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		// let id = this.props.match.params;
		let id = this.props.location.state
		if (id) {
			this.setState({checkOutCode: id.code})
		} else {
			// this.setTo404();
		}
	}

	render() {
		const {checkOutCode} = this.state
		return (
			<div>
				{/* <PageTitle title="Packages"/> */}
				{checkOutCode && <CheckOut checkOutCode={checkOutCode}/>}
				<Footer parent="OrderForm" styles={{ marginTop: 0 }} />
				<NavBar parent="OrderForm" />
			</div>
		)
	}
}

export default OrderForm;