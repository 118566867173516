import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import FadingGradient from '../components/FadingGradient';
import Footer from '../components/Footer';
import READ from '../components/READ';
import AdvBenft from '../display/AdvBenft';
import NavBar from '../navBar';
import { useParams, } from "react-router";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}



class Blogs extends Component {
    constructor() {
        super();
        this.state = {
            filter: "none",
            show: false,
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        let { id } = this.props.params;
        if (id) {
            const rV = id.replace('_', ' ');
            this.setState({ filter: rV, show: true })
        } else {
            this.setState({ show: true })
        }
    }
    render() {
        const { ParentProps, noFilter } = this.props
        const { filter, show } = this.state
        return (
            <>
                <FadingGradient color1="rgb(219,53,57)" color2="rgb(207,207,207)" />
                <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)" />
                <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)" height={40} />
                <section class="heading" style={{ paddingBottom: 50 }}>
                    {
                        show ?
                            <div class={"section container lsec READ"}>
                                {noFilter ? <READ /> : <READ filter={filter} />}
                            </div>
                            :
                            null
                    }
                </section>
                <Footer parent="blog" styles={{ marginTop: 0 }} parentSub={filter} />
                <NavBar parent="blog" parentSub={filter} />
            </>
        );
    }
}

export default withParams(Blogs);
