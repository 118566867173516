import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import Footer from '../components/Footer';
import AboutScreen from '../display/aboutScreen';
import ContactScreen from '../display/ContactScreen';
import NavBar from '../navBar';



class Contact extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        const ParentProps = this.props.ParentProps
        return (
            <>
                <ContactScreen />
                <AdsSystem ParentProps={ParentProps} parent="contact"/>
                <Footer parent="contact" styles={{marginTop:0}}/>
                <NavBar parent="contact" />
            </>
        );
    }
}

export default Contact;
