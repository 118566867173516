'use strict';

import React, { useEffect, useState } from 'react';
import { Link, useHref, useNavigate } from "react-router-dom";
import Elements from '../../Settings/elements';
// import Elements from './elements';


const MailListSub = (props) => {
    const { newsletterClass, newsletterCloseClass, Close_newsLetter, status, message, onValidated, Cancel_newsLetter } = props;

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const subscribe = () => {
        // email &&
        //     firstName &&
        //     lastName &&
        //     email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
            MERGE1: firstName,
            MERGE2: lastName,
        });
    }

    useEffect(() => {
        if (status === "success") success();
    }, [status]);

    const success = () => {
        setTimeout(() => {
            Close_newsLetter({
                EMAIL: email,
                MERGE1: firstName,
                MERGE2: lastName,
            })
        }, 2000);
    }




    return (
        <>
            <div class={"showWriteEmail " + newsletterClass} style={{ height: window.innerHeight, width: window.innerWidth }}>
                <div class={"showWriteEmail_box " + newsletterCloseClass}>
                    <div class="showWriteEmail_box_">
                        <p class="title">
                            {status === "success"
                                ? "Success!"
                                : "Subscribe to our Newsletters!!! Get monthly newsletters and Travel updates."
                            }
                        </p>
                        {status === "sending" ?
                            <p class="showWriteEmail_sending">
                                sending...
                            </p> : null}
                        {status === "error" ?
                            <p class="showWriteEmail_error">
                                {message}
                            </p> : null}
                        {status === "success" ?
                            <p class="showWriteEmail_success">
                                {message}
                            </p> : null}
                        {
                            status === "success" ?
                                null
                                :
                                <>
                                    <p>First name</p>
                                    <input class="log_in_box_input " type={"text"} onChange={(e) => { setFirstName(e.target.value) }} value={firstName} placeholder="Jane" required></input>
                                    <p>Last name</p>
                                    <input class="log_in_box_input " type={"text"} onChange={(e) => { setLastName(e.target.value) }} value={lastName} placeholder="Doe"></input>
                                    <p>Email Address</p>
                                    <input class="log_in_box_input " type={"email"} onChange={(e) => { setEmail(e.target.value) }} value={email} placeholder="your@email.com"></input>
                                    <div style={{ display: "flex" }}>
                                        <p class="subscribe" onClick={subscribe}>subscribe</p>
                                    </div>
                                </>
                        }
                        {/* <input class="subscribe" type={"submit"} placeholder="subscribe"></input> */}
                        <p class="cancel" onClick={Cancel_newsLetter}><i class='fa fa-close'></i></p>
                    </div>
                </div>
            </div>

        </>
    )
}


// class AdsSystem extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             settingsType: "",
//             elements: [],
//         }
//     }

//     navigate = () => {
//         let navigate = useNavigate();
//         alert("haa");

//         navigate("/AboutMe");
//         // fn.closeAds() 
//     }



//     render() {
//         const { fn, state } = this.props.ParentProps
//         return (
//             <>
//                 {state.adsClosedByUser ?
//                     null
//                     :
//                     <div className={'AdsSystem'} onClick={() => {this.navigate() }}>

//                     </div>
//                 }
//             </>
//         )
//     }
// };



export default MailListSub;


