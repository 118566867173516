import React, { Component } from 'react';
import '../../App.css';
import FadingGradient from '../components/FadingGradient';
import Heading from '../components/Heading';
import ImageComp from '../components/ImageComp';
import LatestNews from '../components/LatestNews';



class ExcutvTorsmScreen extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            headings: []
        }
    }

    render() {
        const {headings} = this.state
        const FirstHeading = headings.slice(0,2);
        const secondHeading = headings.slice(2,3);
        return (
            <>
                {/* <ImageComp imgUrl={require('../../assets/img/about.png')}/> */}
                <FadingGradient color1="rgb(219,53,57)" color2="rgb(207,207,207)"/>
                <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)"/>
                {headings.map((value,index,array) => {
                    return(
                        <Heading value={value}/>
                    )
                })}
                {/* <div class={'App__'}></div> */}
            </>
        );
    }

    componentDidMount() {
        const { email, first_name, last_name} = this.props.ParentProps.userData.offlinePreset
        const Fname = first_name ? first_name : ""; const Lname = last_name ? " " + last_name : "";
        const name = Fname + Lname
        const email_ = email ? email : ""
        const headings = [
            {
                title: "Executive Tourism",
                titleStyle: {marginTop: 15},
                text: [
                    {t:"We have designed a 5 Star executive tour that will provide visual insight into what it is like to do business in Ghana. Our tours were created to remove the stigma that it is difficult to launch a business in Ghana. Ghana has created a 7-pillar initiative that is easing the restrictions making doing business a breeze. We have adopted their initiative and have partnered up with Ghana to build their infrastructure.", type: "text", url: ""},
                    {t:"", type: "image", url: require('../../assets/img/dating-african-favicon.png')},
                    {t:"Consider joining one of our executive tours!", type: "text", url: ""}
            ],
                linkText: "",
                path: "",
                style: "boldDialogue",
                externalUrlName: "",
                externalUrl: "",
                noHR: true,
            },
            // {
            //     title: "",
            //     text: [
            //         {t:"", type: "image", url: require('../../assets/img/Pool-1920w.jpg'), style:{width: "100%"}},
            //         {t: "What we offer:", type: "heading", url: "", style:{textAlign: "left", color: "white", fontSize: 40, paddingTop: 10, paddingBottom: 7}},
            //         {t: "– Luxury style lodging and dinning", type: "text", url: "", style:{textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7}},
            //         {t: "– Tours of available real estate properties and land", type: "text", url: "", style:{textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7}},
            //         {t: "– 1-day Executive Seminar with local officials to provide detail insight into how businesses can invest securely into Ghana.", type: "text", url: "", style:{textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7}},
            //     ],
            //     linkText: "",
            //     path: "",
            //     style: "ColumnRead",
            //     columnType: 'col-md-6',
            //     columnArray: [{start: 0, finish:1}, {start: 1, finish:5}],
            //     externalUrlName: "",
            //     externalUrl: "",
            //     CSS: {backgroundColor: "rgb(219,53,57)", paddingBottom: 20,  paddingTop: 20},
            //     noHR: true,
            // },
            {
                title: "Contact us for pricing details!",
                titleStyle: { textAlign: "center", width: "100%" },
                text: [
                    {t: "Name", value: name, type: "input", url: "", style:{width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25}},
                    {t: "Email", value: email_, type: "input", url: "", style:{width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25}},
                    {t: "Subject", type: "input", url: "", style:{width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25}},
                    {t: "Comment or Message", type: "textInput", url: "", style:{width: "100%", height: 200, backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25}},
                ],
                linkText: "",
                path: "",
                style: "dynamicForm",
                externalUrlName: "",
                externalUrl: "",
            }
        ];
        this.setState({headings})
    }
}

export default ExcutvTorsmScreen;
