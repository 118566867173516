import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SearchField from "react-search-field";
import SearchBar from 'search-bar-react'
import READ from "./READ";


export default class Heading extends React.Component {

    constructor() {
        super();
        this.state = {
            bubble: 0,
            SearchBar_focusText: "Start Typing",
            SearchBar_blurText: "Tap to activate Search bar",
            SearchBar_focused: false,
            dynamicFormArray: [],
            index0: false,
            index1: false,
            index2: false,
            index3: false,
            index4: false,
            index5: false,
            index6: false,
            index7: false,
            index8: false,
            index9: false,
            index10: false,
            showModal: false,
            modalOpacity: 0,
            videoHasBeenUnmuted: false,
        }
    }

    SearchBar_focused = () => {
        this.setState({ SearchBar_focused: true });
    }
    SearchBar_blured = () => {
        this.setState({ SearchBar_focused: false })
    }
    updatedynamicFormArray = () => {
        const { } = this.props.value
    }
    updatemultipleDropDown = (i) => {
        if (i === 0) {
            const { index0 } = this.state;
            this.setState({ index0: !index0, })
        } else if (i === 1) {
            const { index1 } = this.state;
            this.setState({ index1: !index1 })
        } else if (i === 2) {
            const { index2 } = this.state;
            this.setState({ index2: !index2 })
        } else if (i === 3) {
            const { index3 } = this.state;
            this.setState({ index3: !index3 })
        }
    }
    checkIfShouldDrop = (i) => {
        if (i === 0) {
            return this.state.index0
        } else if (i === 1) {
            return this.state.index1
        } else if (i === 2) {
            return this.state.index2
        } else if (i === 3) {
            return this.state.index3
        } else return false;
    }

    componentDidMount() {
        const { style } = this.props.value;
        if (style === "dynamicForm") {
            this.updatedynamicFormArray()
        }
        // this.animObjects()
        this.observer_fn()
    }

    observer_fn = () => {
        const options = {
            rootMargin: "0px",
            threshold: [0.25, 0.75]
        };

        const handlePlay = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (this.state.videoHasBeenUnmuted === false) {
                        this.showModal_()
                    }
                    this.VideoRef.play();
                    // this.VideoRef.muted = false;
                    // alert("it is in view")
                } else {
                    this.VideoRef.pause();
                    // this.VideoRef.muted = true;
                    // alert("it is no more in view")
                }
            });
        };

        this.observer = new IntersectionObserver(handlePlay, options);

        //   observer.observe(videoRef.current);
        if (this.VideoRef) {
            this.observer.observe(this.VideoRef)
        }

    }

    showModal_ = () => {
        this.setState({
            showModal: true,
        })
        setTimeout(() => {
            this.setState({
                modalOpacity: 1,
                videoHasBeenUnmuted: true,
            })
        }, 300);
    }
    hideModal_ = () => {
        this.setState({
            modalOpacity: 0,
        })
        setTimeout(() => {
            this.setState({ showModal: false, })
        }, 600);
    }
    unMuteVideo = () => {
        this.VideoRef.muted = false;
        setTimeout(() => {
            this.VideoRef.play()
            this.hideModal_()
        }, 500);
    }

    componentWillUnmount = () => {
        if (this.observer) {
            this.observer.disconnect()
        }
    }


    render() {
        const WINheight = window.innerHeight;
        const WINheight_3 = window.innerHeight / 3;
        const WINheight_3_2 = WINheight_3 / 2;
        const height = WINheight - WINheight_3 - WINheight_3_2
        const width = window.innerWidth;
        const { SearchBar_blurText, SearchBar_focusText, SearchBar_focused, modalOpacity, showModal } = this.state;
        const { index0, index1, index2, index3, index4 } = this.state;
        const placeholder = SearchBar_focused ? SearchBar_focusText : SearchBar_blurText
        const { title, text, linkText, path, style, externalUrl, externalUrlName, externalUrlIcon, columnArray, columnType, columnType_isArray, CSS, noHR, titleStyle, HR2, hrStyle, columnStyle, columnContainerStyle, noTitle } = this.props.value
        const CSS_ = CSS ? CSS : null;
        const titleStyle_ = titleStyle ? titleStyle : null;
        const hrStyle_ = hrStyle ? hrStyle : null
        return (
            <section class="heading" style={CSS_}>
                {noHR === true ? null : <hr style={hrStyle} />}
                {noTitle ? null : <h1 style={titleStyle_}>{title}</h1>}
                {HR2 === true ? <hr style={hrStyle} /> : null}
                <div class={"section container lsec " + style}>
                    {
                        style === "READ" ?
                            <READ />
                            :
                            style === "ColumnRead" ?
                                <div class="container" style={columnContainerStyle}>
                                    {columnArray.map((value, index, array) => {
                                        const data = text.slice(value.start, value.finish)
                                        return (
                                            <div class={columnType_isArray ? columnType[index] : columnType} style={columnStyle}>
                                                <HeadingText text={data} style={style} />
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                style === "ColumnRead2" ?
                                    <div class="container" style={columnContainerStyle}>
                                        {columnArray.map((value, index, array) => {
                                            const data = text.slice(value.start, value.finish)
                                            return (
                                                <div class={columnType_isArray ? columnType[index] : columnType} style={columnStyle}>
                                                    <HeadingText text={data} style={style} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                style === "CoolVideoPlayer" ?
                                    <div class={style + "_div"}>
                                        <video
                                            class={style}
                                            // src={"https://dating-african.com/VideosArch/Dating_African.mp4"}
                                            // controls
                                            autoPlay={true}
                                            muted={true}
                                            // onLoad={() => { alert("loaded") }}
                                            // onCanPlay={() => { this.VideoRef.pause() }}
                                            // onEnded={() => { this.goHome() }}
                                            ref={VideoRef => this.VideoRef = VideoRef}
                                        // onProgress={() => {this.handleProgress()}}
                                        style={{ height: "100%", width: "100%", objectFit: "contain" }}
                                        >
                                            <source src={require("../../assets/img/Dating_African.mp4")} />
                                        </video>
                                        <div class={"startUp_info " + style} style={{ position: "relative", top: -CSS_.height }} onClick={() => { this.VideoRef.muted = false }}>
                                            <div class="glass" style={{ opacity: 0, }}></div>
                                            <img class={style} src={require('../../assets/img/logo_startUp.png')} style={{ top: 0, objectFit: "contain" }} />
                                        </div>
                                        {showModal ? <div class="modal_container" style={{ opacity: modalOpacity }} onClick={() => { this.unMuteVideo() }}>
                                            <div class="modal_">
                                                <p>Tap to Unmute</p>
                                                <p><i class="fa fa-volume-up"></i></p>
                                            </div>
                                        </div> : null}
                                    </div>
                                    :
                                    style === "dynamicForm" ?
                                        <div class="container">
                                            <div class="col-md-6">
                                                {text.map((value, index, array) => {
                                                    const compStyle = value.style ? value.style : null
                                                    if (value.type === "textInput") {
                                                        return (
                                                            <textarea class={style + "_"} style={compStyle} placeholder={value.t}></textarea>
                                                        )
                                                    } else {
                                                        return (
                                                            <input class={style + "_"} style={compStyle} placeholder={value.t} value={value.value?value.value:""}/>
                                                        )
                                                    }
                                                })}
                                                <p style={{ cursor: "pointer" }}>SUBMIT</p>
                                            </div>
                                            <div class="col-md-6"></div>
                                        </div>
                                        :
                                        style === "multipleDropDown" ?
                                            <div class="container multipleDropDown">
                                                {text.map((value, index, array) => {
                                                    const compStyle = value.style ? value.style : null
                                                    return (
                                                        <>
                                                            <div class="multipleDropDown_head" onClick={() => { this.updatemultipleDropDown(index) }}>
                                                                {this.checkIfShouldDrop(index) ? <i class="fa fa-minus-square-o"></i> : <i class={externalUrlIcon}></i>}
                                                                <p>{value.t}</p>
                                                            </div>
                                                            <div class="dropDown_ " style={{ height: this.checkIfShouldDrop(index) ? value.height : 0 }}>
                                                                <HeadingText text={value.data} style={style} />
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                            :
                                            <HeadingText text={text} style={style} />
                        // <p>{JSON.stringify(text.length)}</p>
                    }
                    {
                        linkText ?
                            <a>
                                <span><Link to={path} class={style}>
                                    {style === "READ" ? <i class="fa fa-angle-down"></i> : null}{linkText}
                                </Link></span>
                            </a>
                            :
                            null
                    }
                    {
                        externalUrlName ?
                            <a class="externalUrl" target={"_blank"} href={externalUrl}>{externalUrlName} {externalUrlIcon ? <i class="fa fa-arrow-right"></i> : null}</a>
                            :
                            null
                    }
                </div>
            </section>
        )
    }
}



class HeadingText extends React.Component {
    returnComponent = (inf) => {
        const { data, style } = inf;
        const compStyle = data.style ? data.style : null
        const compClass = data.class ? data.class : ""
        if (data.type === "image") {
            return (<img src={data.url} style={compStyle} />)
        } else if (data.type === "list") {
            return (
                <ul class={style + "_"} style={compStyle}>
                    <HeadingText text={data.data} style={style} />
                </ul>
            )
        } else if (data.type === "listChild") {
            return (
                <li class={style + "_"} style={compStyle}>{data.t}</li>
            )
        } else if (data.type === "icon") {
            return (<i class={data.t} style={compStyle}></i>)
        } else if (data.type === "div") {
            return (
                <div class={style + "_" + compClass} style={compStyle}>
                    <HeadingText text={data.children} style={style} />
                </div>
            )
        } else if (data.type === "link") {
            return (
                <div class={style + "_"} style={compStyle}>
                    {
                        data.linkType === "internal" ?
                            <Link to={data.url} class={style + "_"} style={compStyle}>{data.t}</Link>
                            :
                            <a href={data.url} class={style + "_"} style={compStyle}>{data.t}</a>
                    }
                </div>
            )
        } else {
            return (<p class={style + "_"} style={compStyle}>{data.t}</p>)
        }
    }
    render() {
        const { text, style } = this.props;
        return (
            <>
                {
                    text.length >= 2 ?
                        text.map((value, index, array) => {
                            return (
                                this.returnComponent({ data: value, style })
                            )
                        })
                        :
                        text.length === 0 ?
                            <p>nothing</p>
                            :
                            this.returnComponent({ data: text[0], style })
                }
            </>
        )
    }
}