import React, { useRef } from "react";
import {
    SnapList,
    SnapItem,
    useVisibleElements,
    useScroll,
    useDragToScroll,
    isTouchDevice,
} from 'react-snaplist-carousel';


export default class LatestNews extends React.Component {

    constructor() {
        super();
        this.state = {
            bubble: 0,
            pageJustOpened: true,
            showInfo: false,
            News: [
                { title: "Ugandas empowering all female Diva Taxi service takes on pandemic" },
                { title: "Kenya and France sign three bilateral deals to boost economic ties" },
                { title: "Nigerian team investigating police brutality begins work" },
            ]
        }
    }

    componentDidMount() {
        // this.animObjects()
    }

    nextSlide = () => {
        const {bubble, News} = this.state;
        const total = News.length;
        const highestIndex = total - 1
        if (bubble === highestIndex) {
            this.setState({
                bubble: 0
            })
            return 0;
        } else {
            const newBubble = bubble + 1;
            this.setState({
                bubble: newBubble
            })
            return newBubble;
        }
    }
    prevSlide = () => {
        const {bubble, News} = this.state;
        const total = News.length;
        const highestIndex = total - 1
        if (bubble === 0) {
            this.setState({
                bubble: highestIndex
            })
            return highestIndex;
        } else {
            const newBubble = bubble - 1;
            this.setState({
                bubble: newBubble
            })
            return newBubble;
        }
    }

    render() {
        const WINheight = window.innerHeight;
        const WINheight_3 = window.innerHeight / 3;
        const WINheight_3_2 = WINheight_3 / 2;
        const height = WINheight - WINheight_3 - WINheight_3_2
        const width = window.innerWidth;
        const { News } = this.state
        return (
            <section class="latestnews" style={{ height }}>
                <h1>LATEST NEWS</h1>
                <div class="section container lsec" style={{  height: WINheight_3 }}>
                    <Bubbles_holder height={WINheight_3} news={News} nextSlide={this.nextSlide} prevSlide={this.prevSlide} />
                    {/* <div class="Bubbles_holder">
                        {News.map((value, index, array) => {
                            return (
                                <Box index={index} value={value} />
                            )
                        })}
                    </div> */}
                </div>
            </section>
        )
    }
}


const Bubbles_holder = ({ height, news, nextSlide, prevSlide }) => {
    const snapList = useRef(null);

    const visible = useVisibleElements(
        { debounce: 10, ref: snapList },
        ([element]) => element,
    );
    const goToSnapItem = useScroll({ ref: snapList });
    const { isDragging } = useDragToScroll({ ref: snapList });

    return (
        <>
            <SnapList ref={snapList}>
                {news.map((value, index, array) => {
                    return (
                        <SnapItem margin={{ left: '15px', right: '15px' }} snapAlign="center">
                            <Box onClick={() => goToSnapItem(index)} visible={visible === index} height={height}>
                                <p class="SnapItem_Box_p">{value.title}</p>
                            </Box>
                        </SnapItem>
                    )
                })}
            </SnapList>
            <p class="SnapListNav1 SnapListNav" onClick={() => goToSnapItem(prevSlide())} >{"<"}</p>
            <p class="SnapListNav2 SnapListNav" onClick={() => goToSnapItem(nextSlide())} >{">"}</p>
        </>
    );
};

class Box extends React.Component {
    render() {
        const { value, onClick, children, visible, height } = this.props
        const index = this.props.index + 1
        return (
            <div
                style={{
                    width: '50vw',
                    height: visible ? height : height - 40,
                    background: visible ? 'rgb(127, 32, 3)' : '#cccccc',
                    cursor: visible ? 'default' : 'pointer',
                    transition: "0.6s",
                    marginTop: visible ? 0 : 20, alignItems: "center",
                    display: "flex", justifyContent: "center",
                }}
                onClick={onClick}
            >
                {children}
            </div>
        )
    }
}