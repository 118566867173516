import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SearchField from "react-search-field";
import SearchBar from 'search-bar-react'


export default class Footer extends React.Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            blog: [
                { title: "Africa", path: "/Home" },
                { title: "Social Justice", path: "/Home" },
                { title: "Social Change", path: "/Home" },
                { title: "Adventure", path: "/Home" },
                { title: "Travel", path: "/Home" },
                { title: "Opportunities", path: "/Home" },
                { title: "Culture", path: "/Home" },
                { title: "Theological History", path: "/Home" },
            ],
            services: [
                { title: "Executive Tourism", path: "/ExecutiveTourism" },
                { title: "The Pathway To Ghana", path: "/ThePathwayToGhana" }
            ],
            showMobileNav: false,
            dropdown1: false,
            dropdown2: false,
        }
    }



    render() {
        const { blog, services } = this.state
        const { parent } = this.props;
        const active1 = parent === "home" ? 'active' : '';
        const active2 = parent === "aboutme" ? 'active' : '';
        const active3 = parent === "service_prod" ? 'active' : '';
        const active4 = parent === "blog" ? 'active' : '';
        const active5 = parent === "BookTravel" ? 'active' : '';
        const active6 = parent === "contact" ? 'active' : '';
        const style = this.props.styles ? this.props.styles : null
        return (
            <div class="footer" style={style}>
                <div>
                    <p>© 2020 Dating African | All Right Reserved.</p>
                </div>
                <div class={'logo_'}>
                    <div class={"NavBar_buttons_a"}><Link to={'/Home'} class={active1}>HOME</Link></div>
                    <div class={"NavBar_buttons_a"}><Link to={'/AboutMe'} class={active2}>ABOUT ME</Link></div>
                    <div class={"NavBar_buttons_a"}>
                        <Link to={'/ExecutiveTourism'} class={active3}>SERVICES/PRODUCTS</Link>
                        {/* <div class={"dropDown"}>
                                <div class='dropDown_tabIndicator'></div>
                                <div class='dropDown_dialogue'>
                                    {services.map((value, index, array) => {
                                        return (
                                            <p><span><Link to={'/Home'}>{value.title}</Link></span></p>
                                        )
                                    })}
                                    <p style={{ opacity: 0.5 }}><Link to={'/Home'}>More</Link></p>
                                </div>
                            </div> */}
                    </div>
                    <div class={"NavBar_buttons_a"}>
                        <Link to={'/Blogs'} class={active4}>BLOG</Link>
                        {/* <div class={"dropDown2"}>
                                <div class='dropDown_tabIndicator'></div>
                                <div class='dropDown_dialogue'>
                                    {blog.map((value, index, array) => {
                                        return (
                                            <p><span><Link to={'/Home'}>{value.title}</Link></span></p>
                                        )
                                    })}
                                    <p style={{ opacity: 0.5 }}><Link to={'/Home'}>More</Link></p>
                                </div>
                            </div> */}
                    </div>
                    <div class={"NavBar_buttons_a"}><Link to={'/BookTravel'} class={active5}>BOOK TRAVEL</Link></div>
                    <div class={"NavBar_buttons_a"}><Link to={'/Contact'} class={active6}>CONTACT</Link></div>

                </div>
            </div>
        )
    }
}


