
'use strict';

import React, { useEffect, useState } from 'react';
import { Link, useHref, useNavigate } from "react-router-dom";
import Elements from '../../Settings/elements';
import MailListSub from './MailListSub';
import MailchimpSubscribe from "react-mailchimp-subscribe";
// import Elements from './elements';


const AdsSystem = ({ ParentProps, parent }) => {
    const { fn, state, userData } = ParentProps;
    const [animClass, setAnimClass] = useState("AdsSystem_inActive");
    const [animClass_, setAnimClass_] = useState("");
    const [animClass_close, setAnimClass_close] = useState("AdsSystem_inActive_close");
    const [animClass__close, setAnimClass__close] = useState("");
    const [newsletterClass, setNewsLetterClass] = useState("")
    const [newsletterCloseClass, setNewsLetterCloseClass] = useState("");
    const [feild, setFeild] = useState("");
    // const [curentProgress, setCurentProgress] = useState(0);
    const curentProgress = state.currentAdAnimProgress
    const [curentInAbv, setCurentInAbv] = useState(true);

    const url = curentProgress === 0 ? require('../../assets/img/InteleTravel_Icon_Flight.png') :
        curentProgress === 1 ? require('../../assets/img/InteleTravel_Icon_Car.png') :
            curentProgress === 2 ? require('../../assets/img/InteleTravel_Icon_Cruise.png') :
                curentProgress === 3 ? require('../../assets/img/InteleTravel_Icon_Hotel.png') :
                    curentProgress === 4 ? require('../../assets/img/InteleTravel_Icon_Insurance.png') :
                        curentProgress === 5 ? require('../../assets/img/InteleTravel_Icon_Packages.png') :
                            curentProgress === 6 ? require('../../assets/img/InteleTravel_Icon_Tickets.png') :
                                curentProgress === 7 ? require('../../assets/img/InteleTravel_Icon_Tours.png') :
                                    require('../../assets/img/InteleTravel_Icon_Flight.png');
    const urlText = curentProgress === 0 ? "Earn 5% - 10%" :
        curentProgress === 1 ? "Earn 5% - 10%" :
            curentProgress === 2 ? "Earn 10% - 18%" :
                curentProgress === 3 ? "Earn 8% - 15%" :
                    curentProgress === 4 ? "Earn 25% - 28%" :
                        curentProgress === 5 ? "Earn 10% - 12%" :
                            curentProgress === 6 ? "Earn 10% - 12%" :
                                curentProgress === 7 ? "Earn 12% - 16%" :
                                    "Earn 5% - 10%"

    let navigate = useNavigate();
    const goToAds = () => {
        navigate("/AdvisorBenefits");
        fn.closeAds()
    };
    const closeAds = () => {
        fn.closeAds()
    };

    const Close_newsLetter = (data) => {
        userData.subscribe_toMialChimp(data)
        setNewsLetterClass("");
        setTimeout(() => {
            fn.NewsLetter_close();
            setNewsLetterCloseClass("")
        }, 600);
        setTimeout(() => {
            setAnimClass("AdsSystem_Active");
            setAnimClass_close("AdsSystem_Active_close");
            neStyle()
        }, 3000);
    }
    const Cancel_newsLetter = () => {
        setNewsLetterClass("");
        setTimeout(() => {
            fn.NewsLetter_close();
            setNewsLetterCloseClass("")
        }, 600);
        setTimeout(() => {
            setAnimClass("AdsSystem_Active");
            setAnimClass_close("AdsSystem_Active_close");
            neStyle()
        }, 3000);
    }

    const animateIN = () => {
        if (parent === "home") {
            if (userData.offlinePreset.subscribed === true) {
                setTimeout(() => {
                    setAnimClass("AdsSystem_Active");
                    setAnimClass_close("AdsSystem_Active_close");
                    neStyle()
                }, 2000);
            } else {
                setTimeout(() => {
                    fn.NewsLetter_open()
                    setTimeout(() => {
                        setNewsLetterClass("showWriteEmail_anim");
                        setNewsLetterCloseClass("showWriteEmail_box_anim")
                    }, 300);
                }, 2000);
            }
        } else {
            setTimeout(() => {
                setAnimClass("AdsSystem_Active");
                setAnimClass_close("AdsSystem_Active_close");
                neStyle()
            }, 2000);
        }
    }
    const neStyle = () => {
        if (state.adsClosedByUser === false) {
            setTimeout(() => {
                setAnimClass_(" AdsSystem_Active__");
                setAnimClass__close(" AdsSystem_Active___close");
                setCurentInAbv(false);
                setTimeout(() => {
                    setAnimClass_("");
                    setAnimClass__close("");
                    fn.up_CAAP();
                    setCurentInAbv(true);
                    neStyle()
                }, 5000);
            }, 5000);
        }
    }

    useEffect(() => {
        animateIN()
    }, []);

    const AbrvInfo = () => {
        return (
            <div class="AbrvInfo">
                <img src={url} />
                <p>{urlText}</p>
            </div>
        )
    }
    const fullInfo = () => {
        return (
            <div class="fullInfo">
                <img src={require('../../assets/img/paige_cody_ITTqjS3UpoY_unsplash.jpg')} />
                <div>
                    <p class="title">Become an Advisor</p>
                    <p>Our travel Advisors earn a commission from partner brands on each trip they sell, but the profession is so much more rewarding than that.</p>
                </div>
            </div>
        )
    }
    const REACT_APP_MAILCHIMP = {
        U: "4d4c72664ef0675b144325a8e",
        ID: "cf12539491"
    }

    const postUrl = `https://outheboxcoaching.us1.list-manage.com/subscribe/post?u=${REACT_APP_MAILCHIMP.U}&id=${REACT_APP_MAILCHIMP.ID}`;


    return (
        <>
        {/* <h1 style={{position: "absolute", top: "40%", zIndex: 1000, color: "white"}}>subscribe:  {JSON.stringify(state.newsletterClosedByUser)}</h1> */}
            {state.adsClosedByUser ?
                null
                :
                <>
                    <div className={'AdsSystem ' + animClass + animClass_} onClick={goToAds}>
                        {
                            curentInAbv === false ?
                                fullInfo()
                                :
                                AbrvInfo()
                        }
                    </div>
                    <div className={'AdsSystem_close ' + animClass_close + animClass__close} onClick={closeAds}>
                        <i class="fa fa-close"></i>
                    </div>
                </>
            }

            {parent === "home" ?
                state.newsletterClosedByUser === false ?
                    <MailchimpSubscribe
                        url={postUrl}
                        render={({ subscribe, status, message }) => (
                            <MailListSub
                                newsletterClass={newsletterClass}
                                newsletterCloseClass={newsletterCloseClass}
                                Close_newsLetter={Close_newsLetter}
                                Cancel_newsLetter={Cancel_newsLetter}
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )}
                    />
                    :
                    null
                :
                null
            }

        </>
    )
}

const AdsSystem_ = () => {
    return(
        <div></div>
    )
}


// class AdsSystem extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             settingsType: "",
//             elements: [],
//         }
//     }

//     navigate = () => {
//         let navigate = useNavigate();
//         alert("haa");

//         navigate("/AboutMe");
//         // fn.closeAds() 
//     }



//     render() {
//         const { fn, state } = this.props.ParentProps
//         return (
//             <>
//                 {state.adsClosedByUser ?
//                     null
//                     :
//                     <div className={'AdsSystem'} onClick={() => {this.navigate() }}>

//                     </div>
//                 }
//             </>
//         )
//     }
// };



export default AdsSystem_;


