import React, { Component } from 'react';
import '../../App.css';
import FadingGradient from '../components/FadingGradient';
import Heading from '../components/Heading';
import ImageComp from '../components/ImageComp';
import LatestNews from '../components/LatestNews';



class AdvBenft extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            headings: [
                {
                    title: "",
                    text: [
                        { t: "", type: "image", url: require('../../assets/img/paige_cody_zGmVI1VljO4_unsplash.jpg'), style: { width: "100%" } },
                        { t: "Advisor Benefits", type: "heading", url: "", style: { textAlign: "left", color: "white", fontSize: 60, paddingTop: 20, paddingBottom: 7, fontFamily: "Impact" } },
                        { t: "Our travel Advisors earn a commission from partner brands on each trip they sell, but the profession is so much more rewarding than that. When you become an Advisor with InteleTravel, you’ll find joy in building unique experiences for your friends, family, and followers while growing your own passion for travel, education, and personal development—not to mention your business. ", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7 } },
                        { t: "Part-time passion.", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 40, paddingBottom: 7, fontWeight: "bold" } },
                        { t: "Anytime you want.", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontWeight: "bold" } },
                        { t: "Full-time career.", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontWeight: "bold" } },
                        { t: "fa fa-long-arrow-down animated bounce animate_repeat_infinit", type: "icon", url: "", style: { color: "white", fontSize: 103, paddingTop: 40, paddingLeft: 70, opacity: 0.7, } },
                    ],
                    linkText: "",
                    path: "",
                    style: "ColumnRead",
                    columnType: 'col-md-6',
                    columnArray: [{ start: 0, finish: 1 }, { start: 1, finish: 7 }],
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundColor: "rgb(219,53,57)", backgroundImage: "linear-gradient(to right, green,rgb(219,53,57))", paddingBottom: 20, paddingTop: 20 },
                    noHR: true,
                },
                {
                    title: "Benefits of becoming a travel advisor",
                    titleStyle: { marginTop: 60, fontFamily: "Impact", fontSize: 40, paddingLeft: 20, paddingRight: 20, textAlign: "center" },
                    text: [
                        { t: "Our travel Advisors have found that booking travel isn’t just about setting up a side hustle. It’s a way to develop new skills and apply the ones you already have. It’s a way to discover new places to travel. It’s a way to connect with family and friends as well as others that share their passion.", type: "text", url: "" },
                        { t: "The freedom and flexibility you need in your life", type: "text", url: "", style: { fontSize: 25, paddingLeft: 20, paddingRight: 20, paddingTop: 20, textAlign: "center", fontWeight: "bold" } },
                    ],
                    linkText: "",
                    path: "",
                    style: "boldDialogue",
                    externalUrlName: "",
                    externalUrl: "",
                    noHR: true,
                },
                // {
                //     title: "The freedom and flexibility you need in your life",
                //     titleStyle: { fontSize: 40, paddingLeft: 20, paddingRight: 20, textAlign: "center" },
                //     text: [{ t: ".", type: "text", url: "", style: { fontSize: 1 } }],
                //     linkText: "",
                //     path: "",
                //     style: "boldDialogue",
                //     externalUrlName: "",
                //     externalUrl: "",
                //     noHR: true,
                // },
                {
                    title: "",
                    text: [
                        {
                            children: [
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/icons8_cancel_100.png'), style: { width: 50, height: 50, } },
                                        { t: "No quotas to meet", type: "text", url: "", style: { textAlign: "center", paddingTop: 5, paddingBottom: 7, letterSpacing: "normal", fontWeight: "bold", paddingLeft: 0, paddingRight: 0 } },
                                        { t: "This can be a full-time career opportunity, or a side interest that pays.", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 2, paddingBottom: 7, letterSpacing: "normal", paddingLeft: 0, paddingRight: 0  } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", padding: 5, margin: 0, flexDirection: "column", }
                                },
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/icons8_beach_umbrella_100.png'), style: { width: 50, height: 50, } },
                                        { t: "You can book travel for your clients as often or as little as you like!", type: "heading", url: "", style: { textAlign: "center", paddingTop: 5, paddingBottom: 7, letterSpacing: "normal", fontWeight: "bold", paddingLeft: 0, paddingRight: 0  } },
                                        { t: "Some advisors work every day, while others book on weekends or during their free time.", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 2, paddingBottom: 7, letterSpacing: "normal", paddingLeft: 0, paddingRight: 0  } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", padding: 5, margin: 0, flexDirection: "column", }
                                }
                            ],
                            type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "flex-start", padding: 0, margin: 0,  }
                        },

                        {
                            children: [
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/icons8_cancel_100_2.png'), style: { width: 50, height: 50, } },
                                        { t: "No minimum requirements to join", type: "text", url: "", style: { textAlign: "center", paddingTop: 5, paddingBottom: 7, letterSpacing: "normal", fontWeight: "bold", paddingLeft: 0, paddingRight: 0  } },
                                        { t: "This is not a gym membership. No long-term contracts. Stop whenever you want for any reason. ", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 2, paddingBottom: 7, letterSpacing: "normal", paddingLeft: 0, paddingRight: 0  } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", padding: 5, margin: 0, flexDirection: "column" }
                                },
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/icons8_checkmark_100.png'), style: { width: 50, height: 50, } },
                                        { t: "Get started right away", type: "heading", url: "", style: { textAlign: "center", paddingTop: 5, paddingBottom: 7, letterSpacing: "normal", fontWeight: "bold", paddingLeft: 0, paddingRight: 0  } },
                                        { t: "Interact with other advisors, share experiences, and learn more about the travel industry as soon as you join.", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 2, paddingBottom: 7, letterSpacing: "normal", paddingLeft: 0, paddingRight: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", padding: 5, margin: 0, flexDirection: "column" }
                                }
                            ],
                            type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "flex-start", padding: 0, margin: 0, }
                        },
                    ],
                    linkText: "",
                    path: "",
                    style: "ColumnRead",
                    columnType: 'col-md-6',
                    columnStyle: {padding: 0},
                    columnArray: [{ start: 0, finish: 1 }, { start: 1, finish: 4 }],
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundColor: "rgba(0,0,0,0)", paddingBottom: 30, paddingTop: 0, marginTop: 0 },
                    noHR: true,
                },
                // {
                //     title: "",
                //     text: [
                //         { t: "", type: "image", url: require('../../assets/img/icons8_beach_umbrella_100.png'), style: { width: 70, height: 70, marginLeft: 30 } },
                //         { t: "You can book travel for your clients as often or as little as you like!", type: "heading", url: "", style: { textAlign: "left", fontSize: 20, paddingTop: 20, paddingBottom: 7, } },
                //         { t: "Some advisors work every day, while others book on weekends or during their free time.", type: "text", url: "", style: { textAlign: "left", fontSize: 13, paddingTop: 10, paddingBottom: 7, } },

                //         { t: "", type: "image", url: require('../../assets/img/icons8_checkmark_100.png'), style: { width: 70, height: 70, marginLeft: 30 } },
                //         { t: "Get started right away", type: "heading", url: "", style: { textAlign: "left", fontSize: 20, paddingTop: 20, paddingBottom: 7, } },
                //         { t: "Interact with other advisors, share experiences, and learn more about the travel industry as soon as you join.", type: "text", url: "", style: { textAlign: "left", fontSize: 13, paddingTop: 10, paddingBottom: 7, } },
                //     ],
                //     linkText: "",
                //     path: "",
                //     style: "ColumnRead",
                //     columnType: 'col-md-6',
                //     columnArray: [{ start: 0, finish: 3 }, { start: 3, finish: 6 }],
                //     externalUrlName: "",
                //     externalUrl: "",
                //     CSS: { backgroundColor: "rgba(0,0,0,0)", paddingBottom: 20, paddingTop: 20, },
                //     noHR: true,
                // },
                {
                    title: "You earn a commission on every sale",
                    titleStyle: { marginTop: 60, fontFamily: "Impact", fontSize: 40, paddingLeft: 20, paddingRight: 20, textAlign: "center", },
                    text: [
                        { t: "Booking with DATING AFRICAN does not cost your travelers anything extra.", type: "text", url: "", style: { fontWeight: "bold" } },
                        { t: "No matter where a trip is booked, the commission is built into the public price, whether the travel is booked through an Advisor or directly with the supplier. Typically, commissions from suppliers range from 10% to 28% of the travel purchase. Once you become a travel Advisor, InteleTravel pays 70% to 80% of those commissions to you.", type: "text", url: "", style: { fontWeight: "normal" } },
                    ],
                    linkText: "",
                    path: "",
                    style: "",
                    externalUrlName: "",
                    externalUrl: "",
                    noHR: true,
                    CSS: { backgroundColor: "rgba(215,200,204,0.9)", }
                },
                {
                    title: "",
                    text: [
                        {
                            children: [
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Flight.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "International Flights", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "5% - 10%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Cruise.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "Cruises", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "10% - 18%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                            ],
                            type: "div", url: "", style: { display: "flex", justifyContent: "space-around", alignItems: "center", padding: 0, margin: 0 }
                        },
                        {
                            children: [
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Hotel.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "Hotels & Resorts", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "8% - 15%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Car.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "Car Rentals", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "5% - 10%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                            ],
                            type: "div", url: "", style: { display: "flex", justifyContent: "space-around", alignItems: "center", padding: 0, margin: 0 }
                        },
                    ],
                    linkText: "",
                    path: "",
                    style: "ColumnRead",
                    columnType: 'col-md-6',
                    columnArray: [{ start: 0, finish: 1 }, { start: 1, finish: 2 }],
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundColor: "rgba(215,200,204,0.9)", paddingBottom: 0, paddingTop: 0, },
                    noHR: true,
                },
                {
                    title: "",
                    text: [
                        {
                            children: [
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Insurance.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "Insurance", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "25% - 28%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Tours.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "Tours", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "12% - 16%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                            ],
                            type: "div", url: "", style: { display: "flex", justifyContent: "space-around", alignItems: "center", padding: 0, margin: 0 }
                        },


                        {
                            children: [
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Packages.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "Activities", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "10% - 12%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                                {
                                    children: [
                                        { t: "", type: "image", url: require('../../assets/img/InteleTravel_Icon_Tickets.png'), style: { width: 70, height: 70, objectFit: "cover" } },
                                        { t: "Event Tickets", type: "heading", url: "", style: { fontWeight: "bold", paddingTop: 20, padding: 0, margin: 0 } },
                                        { t: "Average Commission", type: "text", url: "", style: { textAlign: "center", fontSize: 13, paddingTop: 10, padding: 0, margin: 0 } },
                                        { t: "10% - 12%", type: "text", url: "", style: { fontWeight: "bold", fontSize: 13, paddingTop: 0, paddingBottom: 0, padding: 0, margin: 0 } },
                                    ],
                                    type: "div", url: "", style: { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: 0, margin: 5, }
                                },
                            ],
                            type: "div", url: "", style: { display: "flex", justifyContent: "space-around", alignItems: "center", padding: 0, margin: 0 }
                        },
                    ],
                    linkText: "",
                    path: "",
                    style: "ColumnRead",
                    columnType: 'col-md-6',
                    columnArray: [{ start: 0, finish: 1 }, { start: 1, finish: 2 }],
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundColor: "rgba(215,200,204,0.9)", paddingBottom: 50, paddingTop: 0, },
                    noHR: true,
                },
                {
                    title: "",
                    text: [
                        { t: "", type: "image", url: require('../../assets/img/bruce-mars-8YG31Xn4dSw-unsplash.jpg'), style: { width: "100%" } },
                        { t: "Start earning today", type: "heading", url: "", style: { textAlign: "left", color: "white", fontSize: 30, paddingTop: 20, paddingBottom: 7, fontFamily: "Impact" } },
                        { t: "There’s a $179.99 one-time enrollment fee to become a certified travel Advisor. Then it’s only $39.95 a month for unlimited support and service. There are no hidden fees. That's less than $1.35 a day to own your own business!", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7 } },
                        { t: "Plus, our InteleTrust Guarantee ensures you’ll be profitable or your money back. And it’s easy to cancel your enrollment if you ever choose to do so. (See our guarantees for important terms and conditions).", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7 } },
                        { t: "Become an Advisor", type: "link", linkType: "internal", url: "/Home", style: {  color: "white", fontSize: 13, padding: 7, fontWeight: "bold", marginLeft: 20, marginTop: 20 } },
                    ],
                    linkText: "",
                    path: "",
                    style: "ColumnRead",
                    columnType: 'col-md-6',
                    columnContainerStyle: { backgroundColor: "rgba(219,53,57,0.1)", padding: 20, },
                    columnArray: [{ start: 0, finish: 1 }, { start: 1, finish: 7 }],
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundColor: "rgba(215,200,204,0.9)", paddingBottom: 20, paddingTop: 20 },
                    noHR: true,
                },
            ]
        }
    }

    render() {
        const { headings } = this.state
        const FirstHeading = headings.slice(0, 3);
        const secondHeading = headings.slice(3, 9);
        return (
            <>
                {/* <ImageComp imgUrl={require('../../assets/img/Kingdom_Travel_Tours_facebook_cover.jpg')}/> */}
                {/* <FadingGradient color1="white" color2="rgb(207,207,207)"/> */}
                {FirstHeading.map((value, index, array) => {
                    return (
                        <Heading value={value} />
                    )
                })}
                <div style={{ width: "100%", background: "url( " + require('../../assets/img/usgs-PuLsDCBbyBM-unsplash.jpg') + ")", backgroundSize: "cover", backgroundPosition: "center", }}>
                    <FadingGradient color1="rgba(219,53,57,1)" color2="rgba(215,200,204,0.9)" />
                    {secondHeading.map((value, index, array) => {
                        return (
                            <Heading value={value} />
                        )
                    })}
                </div>
                {/* <FadingGradient color1="rgb(207,207,207)" color2="white"/> */}
                {/* <ImageComp imgUrl={require('../../assets/img/Kingdom_Living_Travel__Tours_Flyer_Design.jpg')}/> */}
                {/* <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)"/> */}

                {/* background: "url( " + require('../../assets/img/usgs-PuLsDCBbyBM-unsplash.jpg') +")", backgroundSize: "cover", backgroundPosition: "center", */}

                {/* <div class={'App__'}></div> */}
            </>
        );
    }
}

export default AdvBenft;
