import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import Footer from '../components/Footer';
import ExcutvTorsmScreen from '../display/ExcutvTorsmScreen';
import NavBar from '../navBar';



class ExecutiveTourism extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        const ParentProps = this.props.ParentProps
        return (
            <>
                <ExcutvTorsmScreen ParentProps={ParentProps} />
                <AdsSystem ParentProps={ParentProps} parent="service_prod"/>
                <Footer parent="service_prod" parentSub="ExecutiveTourism" />
                <NavBar parent="service_prod" parentSub="ExecutiveTourism" />
            </>
        );
    }
}

export default ExecutiveTourism;
