import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../App.css';
import LogIn_System from '../components/LogIn_System';



class NavBar extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            blog: [
                { title: "Africa", path: "/Blogs_/Africa" },
                { title: "Social Injustice", path: "/Blogs_/Social_Injustice" },
                { title: "Social Change", path: "/Blogs_/Social_Change" },
                { title: "Adventure", path: "/Blogs_/Adventure" },
                { title: "Travel", path: "/Blogs_/Travel" },
                { title: "Opportunities", path: "/Blogs_/Opportunities" },
                { title: "Culture", path: "/Blogs_/Culture" },
                { title: "Theological History", path: "/Blogs_/Theological_History" },
            ],
            services: [
                { title: "Executive Tourism", path: "/ExecutiveTourism" },
                { title: "The Pathway To Ghana", path: "/ThePathwayToGhana" }
            ],
            showMobileNav: false,
            dropdown1: false,
            dropdown2: false,
            showLogIn: false,
            showLogIn_: false,
            showLogIn__: false,
            // loginOpacity: 0
        }
    }

    socialLink = (link) => {
        window.open(link, '_blank');
    }

    showMobileNav = () => {
        const { showMobileNav } = this.state;
        this.setState({
            showMobileNav: !showMobileNav
        })
    }
    dropdown1 = () => {
        const { dropdown1 } = this.state;
        this.setState({
            dropdown1: !dropdown1
        })
    }
    dropdown2 = () => {
        const { dropdown2 } = this.state;
        this.setState({
            dropdown2: !dropdown2
        })
    }

    showLogIn = () => {
        this.setState({
            showLogIn: true,
            showMobileNav: false,
        }, () => {
            setTimeout(() => {
                this.setState({
                    showLogIn_: true,
                })
            }, 100);
            setTimeout(() => {
                this.setState({
                    showLogIn__: true
                })
            }, 600);
        })
    }
    closeLogIn = () => {
        this.setState({
            showLogIn__: false
        })
        setTimeout(() => {
            this.setState({
                showLogIn_: false
            })
        }, 200);
        setTimeout(() => {
            this.setState({
                showLogIn: false
            })
        }, 800);
    }

    render() {
        const { blog, services, showMobileNav, dropdown1, dropdown2, showLogIn, showLogIn_, showLogIn__ } = this.state
        const { parent, parentSub } = this.props;
        const active1 = parent === "home" ? 'active' : '';
        const active2 = parent === "aboutme" ? 'active' : '';
        const active3 = parent === "service_prod" ? 'active' : '';
        const active4 = parent === "blog" ? 'active' : '';
        const active5 = parent === "BookTravel" ? 'active' : '';
        const active6 = parent === "contact" ? 'active' : '';
        const largeScreen = window.innerWidth > 991 ? true : false;
        const rotate1 = dropdown1 ? "rotate" : "";
        const rotate2 = dropdown2 ? "rotate" : "";
        return (
            <div style={{zIndex:10, position: "relative"}}>
                <Link to={'/Home'} class={'NavBar_logo'}>
                    <div class={'logo'}>
                        <img src={require('../../assets/img/logo_white2.png')}></img>
                        {/* <div>
                            <h1>KINGDOM LIVING</h1>
                            <p>dating-african.com</p>
                        </div> */}
                    </div>
                </Link>
                {/* <Link to={'/Home'} class={'NavBar_logo NavBar_logo2'}>
                    <div class={'logo'}>
                        <img src={require('../../assets/img/kongLoGo.png')}></img>
                    </div>
                </Link> */}
                
                
                
                {largeScreen ? <div class={'NavBar_buttons'}>
                    <div class={'logo'}>
                        <div class={"NavBar_buttons_a"}><Link to={'/Home'} class={active1}>HOME</Link></div>
                        <div class={"NavBar_buttons_a"}><Link to={'/AboutMe'} class={active2}>ABOUT ME</Link></div>
                        {/* <div class={"NavBar_buttons_a"}>
                            <Link to={'/ExecutiveTourism'} class={active3}>SERVICES/PRODUCTS</Link>
                            <div class={"dropDown"}>
                                <div class='dropDown_tabIndicator'></div>
                                <div class='dropDown_dialogue'>
                                    {services.map((value, index, array) => {
                                        return (
                                            <p class={parentSub === value.path.slice(1) ? "active" : ""}><span><Link to={value.path} >{value.title}</Link></span></p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div> */}
                        <div class={"NavBar_buttons_a"}>
                            <Link to={'/Blogs'} class={active4}>BLOG</Link>
                            <div class={"dropDown2"}>
                                <div class='dropDown_tabIndicator'></div>
                                <div class='dropDown_dialogue'>
                                    {blog.map((value, index, array) => {
                                        return (
                                            <p class={parentSub === value.title ? "dropDown_dialogue2_active" : ""}><span><Link to={value.path} class={"active dropDown_dialogue_a"}>{value.title}</Link></span></p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div class={"NavBar_buttons_a"}><Link to={'/BookTravel'} class={active5}>BOOK TRAVEL</Link></div>
                        <div class={"NavBar_buttons_a"}><Link to={'/Contact'} class={active6}>CONTACT</Link></div>
                        <i onClick={this.showLogIn} class="fa fa-user"></i>
                    </div>
                </div> : null}
                
                
                
                {largeScreen ? null : <div class={'NavBar_buttons'} style={{ width: "10%" }} onClick={() => { this.showMobileNav() }}>
                    <div class={'logo'}>
                        <div class={"NavBar_buttons_a"}><a> <i class='fa fa-list-ul'></i></a></div>
                    </div>
                </div>}
                
                
                {largeScreen ? <div class={'social_NavBar'}>
                    <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-glyph-neue-icons8-glyph-neue-Facebook.png')} />
                    <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-ios_filled-icons8-ios_filled-Twitter Squared.png')} />
                    <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-glyph-neue-icons8-glyph-neue-Instagram.png')} />
                    <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-ios_filled-icons8-ios_filled-YouTube.png')} />
                    <div class="social_line"></div>
                    <div class="social_pDiv"><p class="social_p">connect with us</p></div>
                </div> : null}
                {largeScreen ? null :
                    <div class={'NavBar_buttons_mobile '} style={{ width: showMobileNav ? "100%" : 0 }}>
                        <div class={'logo'}>
                            <div class={"NavBar_buttons_a_close"} onClick={() => { this.showMobileNav() }}><a> <i class='fa fa-close'></i></a></div>
                            <div class={"NavBar_buttons_a_"}><Link to={'/Home'} class={active1}>HOME</Link></div>
                            <div class={"NavBar_buttons_a_"}><Link to={'/AboutMe'} class={active2}>ABOUT ME</Link></div>
                            {/* <div class={"NavBar_buttons_a_"}>
                                <div class="children">
                                    <Link to={'/ExecutiveTourism'} class={active3}>SERVICES/PRODUCTS</Link>
                                    <i class={'fa fa-plus ' + rotate1} onClick={() => { this.dropdown1() }}></i>
                                </div>
                                <div class={"dropDown"} style={{ height: dropdown1 ? 60 : 0, padding: dropdown1 ? 10 : 0 }}>
                                    <div class='dropDown_dialogue'>
                                        {services.map((value, index, array) => {
                                            return (
                                                <p class={parentSub === value.path.slice(1) ? "active" : ""}><span><Link to={value.path}>{value.title}</Link></span></p>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div> */}
                            <div class={"NavBar_buttons_a_"}>
                                <div class="children">
                                    <Link to={'/Blogs'} class={active4}>BLOG</Link>
                                    <i class={'fa fa-plus ' + rotate2} onClick={() => { this.dropdown2() }}></i>
                                </div>
                                <div class={"dropDown"} style={{ height: dropdown2 ? 180 : 0, padding: dropdown2 ? 10 : 0 }}>
                                    <div class='dropDown_dialogue'>
                                        {blog.map((value, index, array) => {
                                            return (
                                                <p class={parentSub === value.path.slice(1) ? "active" : ""}><span><Link to={value.path}>{value.title}</Link></span></p>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div class={"NavBar_buttons_a_"}><Link to={'/BookTravel'} class={active5}>BOOK TRAVEL</Link></div>
                            <div class={"NavBar_buttons_a_"}><Link to={'/Contact'} class={active6}>CONTACT</Link></div>
                            <i onClick={this.showLogIn} class="fa fa-user"></i>

                            <div class={'social_NavBar_'}>
                                <div class="social_pDiv_"><p class="social_p">connect with us</p></div>
                                <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-glyph-neue-icons8-glyph-neue-Facebook.png')} />
                                <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-ios_filled-icons8-ios_filled-Twitter Squared.png')} />
                                <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-glyph-neue-icons8-glyph-neue-Instagram.png')} />
                                <img onClick={() => { this.socialLink('https://dating-african.com/') }} class="social_NavBarIcons" src={require('../../assets/img/Icons/icons8-ios_filled-icons8-ios_filled-YouTube.png')} />
                                <div class="social_line"></div>
                            </div>
                        </div>
                    </div>
                }

                {showLogIn === false ? null :
                    <div class="LogIn_Container " style={{ opacity: showLogIn_ ? 1 : 0 }}>
                        <div class={"NavBar_buttons_a_close"} onClick={() => { this.showMobileNav() }}><i class='fa fa-close'></i></div>
                        <div onClick={this.closeLogIn} class="LogIn_Container_glass"></div>
                        <LogIn_System showLogIn__={showLogIn__} showLogIn_={showLogIn_} />
                    </div>
                }

            </div>
        );
    }
}

export default NavBar;
