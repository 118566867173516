'use strict';

import React from 'react';
import './App.css';
import "./assets/bootstrap/css/bootstrap.min.css"
import "./assets/fonts/font-awesome.min.css"
import "./assets/fonts/material-icons.css"
import "./assets/css/animate.css"
import "./assets/css/overall.css"
import "./assets/css/styles.css"
// import Home from './Site1/home'
import windowSize from 'react-window-size';
import Settings from './Settings'
import App_ from './App/App';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
const preset_ = { settingsFullScreen: false, subscribed: false, email: "", first_name: "", last_name: "" };


class App extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      lang: "en",
      user: "",
      userData: "",
      showDialog: false,
      showSettings: false,
      offlinePreset: cookies.get('offlinePreset') || preset_,
    }
  }


  componentDidMount() {
    this.getUserData();
    // this.getOfflinePreset()
    // localStorage.setItem("user", "user");
    const { cookies } = this.props;
    // cookies.remove('offlinePreset')
  }
  getOfflinePreset() {
    const offlinePreset = localStorage.getItem("offlinePreset");
    // if (offlinePreset === "" || offlinePreset === null) {
    //   const offlnPreset = { settingsFullScreen: false, subscribed: false, email: "", first_name: "", last_name: "" };
    //   this.setState({
    //     offlinePreset: offlnPreset
    //   })
    //   localStorage.setItem("offlinePreset", JSON.stringify(offlnPreset));
    // } else {
    //   this.setState({
    //     offlinePreset: JSON.parse(offlinePreset)
    //   })
    // }
  }

  subscribe_toMialChimp = (data) => {
    const { EMAIL, MERGE1, MERGE2, } = data;
    const { cookies } = this.props;
    const { offlinePreset } = this.state;
    const OFLP = offlinePreset
    const newOfflinePreset = {
      settingsFullScreen: OFLP.settingsFullScreen, subscribed: true,
      email:EMAIL, first_name: MERGE1, last_name: MERGE2
    }
    this.setState({ offlinePreset: newOfflinePreset });
    cookies.set("offlinePreset", newOfflinePreset);
  }

  change_settingsFullScreen = () => {
    const { cookies } = this.props;
    const { offlinePreset } = this.state;
    const OFLP = offlinePreset

    const settingsFullScreen = OFLP.settingsFullScreen;
    if (settingsFullScreen === true) {
      OFLP.settingsFullScreen = false;
      this.setState({ offlinePreset:  OFLP });
      cookies.set("offlinePreset", OFLP);
    } else {
      OFLP.settingsFullScreen = true;
      this.setState({ offlinePreset:  OFLP });
      cookies.set("offlinePreset", OFLP);
    }
  }



  setFn = () => {
    this.setState({
      lang: "fn"
    })
  }
  setEn = () => {
    this.setState({
      lang: "en"
    })
  }

  updateUserData = (data) => {
    const { user } = data
    localStorage.setItem("user", user);
    this.getUserData();
  }
  getUserData = () => {
    const user = localStorage.getItem("user");
    if (user === "" || user === null) {
      this.setState({
        user: ""
      })
    } else {
      this.setState({
        user
      })
    }
  }
  logOut = () => {
    localStorage.setItem("user", "");
    this.getUserData();
    this.hideDialog()
  }

  showDialog = () => {
    this.setState({
      showDialog: true
    })
  }
  hideDialog = () => {
    this.setState({
      showDialog: false
    })
  }
  showSettings = () => {
    this.setState({
      showSettings: true
    })
  }
  hideSettings = () => {
    this.setState({
      showSettings: false
    })
  }

  render() {
    const { lang, user, userData, showDialog, showSettings, offlinePreset } = this.state;
    const halfWheight = window.innerHeight / 2;
    // const offlinePreset = JSON.parse(this.state.offlinePreset)

    const newSDH = offlinePreset.settingsFullScreen === true ? window.innerHeight - 20 : window.innerHeight > halfWheight ? window.innerHeight * 70 / 100 : window.innerHeight - 20;
    const newSDW = offlinePreset.settingsFullScreen === true ? window.innerWidth - 20 : window.innerHeight > halfWheight ? window.innerWidth * 30 / 100 : window.innerWidth - 20;

    // const newSetH = window.innerWidth > 991 ? newSDH : window.innerHeight - 20;
    // const newSetW = window.innerWidth > 991 ? newSDW : window.innerWidth - 20;
    return (
      // <div style={{ padding: '16px', height: 600, width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>

      //     <QRCode value=
      //     // "https://www.imesbenin.com/landingPage/%7B[actn]:[VE],[da]:[jerrysplendour2gmail.com]%7D" 
      //     // "https://www.imesbenin.com/landingPage/{[actn]:[QR],[da]:[LicenseAYOITO COMLAN LEOPOLD]}"
      //     // "http://localhost:3000/landingPage/%7B[actn]:[QR],[da]:[LicenseAYOITO%20COMLAN%20LEOPOLD]%7D"
      //     "https://www.imesbenin.com/landingPage/{[actn]:[QR],[da]:[LicenseAYOITO%20COMLAN%20LEOPOLD]}"
      //     // "http://localhost:3000/landingPage/{[actn]:[QR],[da]:[LicenseAYOITO%20COMLAN%20LEOPOLD]}"
      //     bgColor='rgba(0,0,0,0)'
      //     />
      //   </div>
      <>


        <App_
          lang={lang} user={user}
          userData={{
            userData, updateUserData: this.updateUserData, logOut: this.logOut,
            subscribe_toMialChimp: this.subscribe_toMialChimp, offlinePreset
          }}
        />
        {/* <div
          style={{
            position: "fixed", top: 15, left: 15,
            borderRadius: 10, opacity: 0.8,
            height: 25, paddingLeft: 5, paddingRight: 5,
            overflow: 'hidden',
            boxShadow: "0px 0px 16px 15px rgba(0, 0, 0, 0.5)",
            backgroundColor: "whitesmoke", display: "flex",
            alignItems: "center"
          }}
        >

          <p>{user}</p>

          <div class="lang en en_" onClick={() => { this.setEn() }}></div>
          <div class="lang translator"></div>
          <div class="lang fr fr_" onClick={() => { this.setFn() }}></div>

        </div> */}

        <Settings
          // lang={lang} user={user} userData={userData} showDialog={showDialog} showSettings={showSettings}
          propss={{ lang, user, userData, showDialog, showSettings, newSDH, newSDW, offlinePreset }}
          fns={{
            fn_showDialog: this.showDialog,
            fn_showSettings: this.showSettings, hideSettings: this.hideSettings,
            logOut: this.logOut, hideDialog: this.hideDialog,
            change_settingsFullScreen: this.change_settingsFullScreen,
          }}
        />
      </>
    );
  }
}

export default withCookies(windowSize(App));
// export default App;




