import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import Select, { StylesConfig } from 'react-select';
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
    PaymentElement
} from "@stripe/react-stripe-js";
import ServerStripe from '../../Settings/Stripe/ServerStripe';
import SripeDummyView from '../../Settings/Stripe/SripeDummyView';
// import { loadStripe } from "@stripe/stripe-js";


// const stripe = loadStripe('pk_test_51LpN5uLX4fP9nDzXNfXaMI9lwRPkyxRkginHB1bVMpBFTu5KdHSQeGDpMXdvhDGE4vCRQE0sDKDha4TAOocRX6mZ00R96U4m6X');




const returnOptions1 = (code) => {
    const GToptions = [
        { value: 'full', label: 'Full Pacakge - Includes Flight from New York ' },
        { value: 'land_only', label: 'Land Only - No flight from New York ' },
    ];
    const CToptions = [
        { value: 'full', label: 'Full Pacakge - Includes Flight From Atlanta ' },
        { value: 'land_only', label: 'Land Only - No Flight From Atlanta' },
    ];
    const ZAoptions = "null"
    return code === "TPCT10315" ? CToptions : code === "GWHFZA8765" ? ZAoptions : GToptions;
}

const returnOptions2 = (code) => {
    const GToptions = [
        { value: 'Single', label: 'Single occupancy' },
        { value: 'Double1', label: 'Double, I have a roommate' },
        { value: 'Double2', label: 'Double, match me with a roommate' },
    ];
    const CToptions = [
        { value: 'Single', label: 'Single occupancy' },
    ];
    const ZAoptions = [
        { value: 'Single', label: 'Single occupancy' },
        { value: 'Double', label: 'Double occupancy' },
    ];
    return code === "TPCT10315" ? CToptions : code === "GWHFZA8765" ? ZAoptions : GToptions;
}

const returnOptions3 = (code) => {
    const GToptions = [
        { value: 1, label: '1' },
        { value: 8, label: '8' },
        { value: 16, label: '16' },
    ];
    const CToptions = [
        { value: 1, label: '1' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
    ];
    const ZAoptions = [
        { value: 1, label: '1' },
        { value: 7, label: '7' },
    ];
    return code === "TPCT10315" ? CToptions : code === "GWHFZA8765" ? ZAoptions : GToptions;
}

const returnPrices = (code) => {
    const GTPrices = {
        // Full package
        FullPackage: {
            SingleOccupancy: {
                _01_Payment: 5739.00,
                _08_Payment: 717.38,
                _16_Payment: 358.68,
            },
            DoubleOccupancy: {
                _01_Payment: 5103.00,
                _08_Payment: 687.88,
                _16_Payment: 318.94,
            }
        },
        // Land Only package
        LandOnlyPackage: {
            SingleOccupancy: {
                _01_Payment: 4039.00,
                _08_Payment: 504.88,
                _16_Payment: 252.44,
            },
            DoubleOccupancy: {
                _01_Payment: 3750.00,
                _08_Payment: 468.75,
                _16_Payment: 234.38,
            }
        },
    }
    const CTPrices = {
        // Full package
        FullPackage: {
            SingleOccupancy: {
                _01_Payment: 6239.00,
                _05_Payment: 1247.80,
                _06_Payment: 1039.84,
            },
        },
        // Land Only package
        LandOnlyPackage: {
            SingleOccupancy: {
                _01_Payment: 4239.00,
                _05_Payment: 847.80,
                _06_Payment: 706.50,
            },
        },
    }
    const ZAPrices = {
        // Full package
        FullPackage: {
            SingleOccupancy: {
                _01_Payment: 3869.20,
                _07_Payment: 481.31,
            },
            DoubleOccupancy: {
                _01_Payment: 2895.20,
                _07_Payment: 342.17,
            }
        },
    }
    return code === "TPCT10315" ? CTPrices : code === "GWHFZA8765" ? ZAPrices : GTPrices;
}

const returnTitle = (code) => {
    const titles = {
        Ghana: "Ghana 2024 Trip  (Payment plans available every 30 days or 15 days)",
        CapeTown: "Cape Town Investment Tour  (Payment plans available every 30 days or 15 days)",
        Zanzibar: "Zanzibar Tanzania - ;: All-inclusive stay at the luxurious Melia Zanzibar in Kiwengwa"
    }
    return code === "TPCT10315" ? titles.CapeTown : code === "GWHFZA8765" ? titles.Zanzibar : titles.Ghana;
}
const returnSubTitle = (code) => {
    const subTitles = {
        Ghana: `March 24 - April 3, 2024 | 9 nights in Ghana | All tours and activities listed on
        the website | All payments are non-refundable and non-transferrable.`,
        CapeTown: `February 03 - February 09, 2024 | 6 nights in Cape Town | All tours and activities 
        listed on the website | All payments are non- refundable and non-transferrable.`,
        Zanzibar: `May 03, 2024 to May 12, 2024 | 9 nights in Zanzibar Tanzania | All tours and activities 
        listed on the website | All payments are non- refundable and non-transferrable.`,
    }
    return code === "TPCT10315" ? subTitles.CapeTown : code === "GWHFZA8765" ? subTitles.Zanzibar : subTitles.Ghana;
}
const returnSumary = (code) => {
    const sumary = {
        Ghana: `Come and experience the finer side of life- as we welcome you home to the Motherland. This majestic
        tour will fill up with the culture and heritage of Ghanaians. The rich history will astound you as we journey
        through the past, present, and the future of Ghana.`,
        CapeTown: `Come and experience the finer side of life- as we welcome you home to the Motherland.`,
        Zanzibar: `Melia Zanzibar in Kiwengwa offers a paradise-like setting, surrounded by tropical gardens and 
        overlooking the turquoise waters of the Indian Ocean. Prepare to be pampered and enjoy the best of Zanzibar's 
        beauty and culture.`,
    }
    return code === "TPCT10315" ? sumary.CapeTown : code === "GWHFZA8765" ? sumary.Zanzibar : sumary.Ghana;
}

const returnHeaderGradient = (code) => {
    const style = {
        CapeTown: { backgroundColor: "grey", backgroundImage: "linear-gradient(to right, rgb(255, 204, 0), #076469ac, rgb(96, 32, 0), #076469ac)", position: "relative" },
        Ghana: {},
        Zanzibar: { backgroundColor: "grey", backgroundImage: "linear-gradient(to right, rgb(255, 204, 0), #076469ac, rgb(96, 32, 0), #076469ac)", position: "relative" },
    }
    return code === "TPCT10315" ? style.CapeTown : code === "GWHFZA8765" ? style.Zanzibar : style.Ghana;
}

const returnHeaderGradient_cover = (code) => {
    const cover = {
        CapeTown: () => { return <></> },
        Ghana: () => { return <></> },
        Zanzibar: () => { return <div className='absolute_YTD'></div> },
    }
    return code === "TPCT10315" ? cover.CapeTown() : code === "GWHFZA8765" ? cover.Zanzibar() : cover.Ghana();
}


const selectStyles = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: "rgba(0,0,0,0)",
        borderRadius: 10,
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.137)",
        marginBottom: 20,
        fontSize: 12,
        top: 20

    }),
    placeholder: (styles) => ({
        ...styles,
        fontSize: 12
    }),
    input: (styles) => ({
        ...styles,
        fontSize: 12
    }),
    option: (styles) => ({
        ...styles,
        fontSize: 12
    }),
}
const selectStyles_small = {
    control: (styles) => ({
        ...styles,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: "rgba(0,0,0,0)",
        borderRadius: 10,
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.137)",
        marginBottom: 20,
        fontSize: 12,
        top: 9,
        marginRight: 10,

    }),
    placeholder: (styles) => ({
        ...styles,
        fontSize: 12
    }),
    input: (styles) => ({
        ...styles,
        fontSize: 12
    }),
    option: (styles) => ({
        ...styles,
        fontSize: 12
    }),
}

class CheckOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption1: null,
            selectedOption2: null,
            selectedOption3: null,
            total: 0,
            subTotal: 0,
            checkOut: false,
            checkOutCode: props.checkOutCode
        }
    }
    componentDidMount() {
        const { selectedOption1, selectedOption2, selectedOption3, checkOutCode } = this.state;
        this.AmountLogic({ selectedOption1, selectedOption2, selectedOption3 });
        // alert("checkout code: " + checkOutCode)
    }

    AmountLogic = ({ selectedOption1, selectedOption2, selectedOption3 }) => {
        const TypeOfPackage = selectedOption1 ? selectedOption1.value : "full";
        const TypeOfOccupancy = selectedOption2 ? selectedOption2.value : "Single";
        const NumberOfPayments = selectedOption3 ? selectedOption3.value : 1;
        const subTotal = this.getSubTotal({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments });
        const total = this.getTotal({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments });
        this.setState({ subTotal, total, checkOut: false });
    }
    getSubTotal = ({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments }) => {
        const { checkOutCode } = this.state
        var SubTotal;
        SubTotal = checkOutCode === "TPCT10315" ? this.byCTCode({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments }) :
            checkOutCode === "GWHFZA8765" ? this.byZACode({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments }) :
                this.byGTCode({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments });
        return SubTotal;
    };
    byGTCode = ({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments }) => {
        const { checkOutCode } = this.state;
        var SubTotal;
        if (TypeOfPackage === "land_only") {
            if (TypeOfOccupancy === "Single") {
                SubTotal = NumberOfPayments === 8 ? returnPrices(checkOutCode).LandOnlyPackage.SingleOccupancy._08_Payment :
                    NumberOfPayments === 16 ? returnPrices(checkOutCode).LandOnlyPackage.SingleOccupancy._16_Payment :
                        returnPrices(checkOutCode).LandOnlyPackage.SingleOccupancy._01_Payment;
            } else {
                SubTotal = NumberOfPayments === 8 ? returnPrices(checkOutCode).LandOnlyPackage.DoubleOccupancy._08_Payment :
                    NumberOfPayments === 16 ? returnPrices(checkOutCode).LandOnlyPackage.DoubleOccupancy._16_Payment :
                        returnPrices(checkOutCode).LandOnlyPackage.DoubleOccupancy._01_Payment;
            }
        } else {
            if (TypeOfOccupancy === "Single") {
                SubTotal = NumberOfPayments === 8 ? returnPrices(checkOutCode).FullPackage.SingleOccupancy._08_Payment :
                    NumberOfPayments === 16 ? returnPrices(checkOutCode).FullPackage.SingleOccupancy._16_Payment :
                        returnPrices(checkOutCode).FullPackage.SingleOccupancy._01_Payment;
            } else {
                SubTotal = NumberOfPayments === 8 ? returnPrices(checkOutCode).FullPackage.DoubleOccupancy._08_Payment :
                    NumberOfPayments === 16 ? returnPrices(checkOutCode).FullPackage.DoubleOccupancy._16_Payment :
                        returnPrices(checkOutCode).FullPackage.DoubleOccupancy._01_Payment;
            }
        }
        return SubTotal.toFixed(2);
    }
    byCTCode = ({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments }) => {
        const { checkOutCode } = this.state;
        var SubTotal;
        if (TypeOfPackage === "land_only") {
            SubTotal = NumberOfPayments === 5 ? returnPrices(checkOutCode).LandOnlyPackage.SingleOccupancy._05_Payment :
                NumberOfPayments === 6 ? returnPrices(checkOutCode).LandOnlyPackage.SingleOccupancy._06_Payment :
                    returnPrices(checkOutCode).LandOnlyPackage.SingleOccupancy._01_Payment;
        } else {
            SubTotal = NumberOfPayments === 5 ? returnPrices(checkOutCode).FullPackage.SingleOccupancy._05_Payment :
                NumberOfPayments === 6 ? returnPrices(checkOutCode).FullPackage.SingleOccupancy._06_Payment :
                    returnPrices(checkOutCode).FullPackage.SingleOccupancy._01_Payment;
        }
        return SubTotal.toFixed(2);
    }
    byZACode = ({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments }) => {
        const { checkOutCode } = this.state;
        var SubTotal;
        if (TypeOfOccupancy === "Single") {
            SubTotal = NumberOfPayments === 7 ? returnPrices(checkOutCode).FullPackage.SingleOccupancy._07_Payment :
                returnPrices(checkOutCode).FullPackage.SingleOccupancy._01_Payment;
        } else {
            SubTotal = NumberOfPayments === 7 ? returnPrices(checkOutCode).FullPackage.DoubleOccupancy._07_Payment :
                returnPrices(checkOutCode).FullPackage.DoubleOccupancy._01_Payment;
        }
        return SubTotal.toFixed(2);
    }
    getTotal = ({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments }) => {
        const subTotal = this.getSubTotal({ TypeOfPackage, TypeOfOccupancy, NumberOfPayments });
        const NOP = NumberOfPayments;
        const Total = NOP * subTotal;
        return Total.toFixed(2);
    };


    handleChange1 = (selectedOption1) => {
        const { selectedOption2, selectedOption3 } = this.state;
        this.setState({ selectedOption1 });
        this.AmountLogic({ selectedOption1, selectedOption2, selectedOption3 });
    };
    handleChange2 = (selectedOption2) => {
        const { selectedOption1, selectedOption3 } = this.state;
        this.setState({ selectedOption2 });
        this.AmountLogic({ selectedOption1, selectedOption2, selectedOption3 });
    };
    handleChange3 = (selectedOption3) => {
        const { selectedOption1, selectedOption2 } = this.state;
        this.setState({ selectedOption3 });
        this.AmountLogic({ selectedOption1, selectedOption2, selectedOption3 });
    };

    FinishUp = () => {
        this.setState({ checkOut: true })
    }


    render() {
        const windowsInnerWidth = window.innerWidth
        const windowsInnerHeight = window.innerHeight
        const { selectedOption1, selectedOption2, selectedOption3, total, subTotal, checkOut, checkOutCode } = this.state;
        return (
            // <Elements stripe={stripe}>
            <div style={{ width: "100%", overflowX: "hidden", boxShadow: "" }}>
                <div class="rtn" style={returnHeaderGradient(checkOutCode)}>
                    {returnHeaderGradient_cover(checkOutCode)}
                    <h1 style={{ position: "relative" }}>Book your spot</h1>
                </div>

                <div class="OrderSummary">
                    <div class="row O_S_container">
                        <div class="col-md-6 O_S_col O_S_col_" style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
                            <h5>Order summary</h5>
                            <div style={{ width: "85%", height: 1, backgroundColor: "rgba(2, 68, 2, 0.1)", borderRadius: 20, marginBottom: 20, marginTop: 0 }}></div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start" }}>
                                <div style={{ width: "50%" }}>
                                    <h6>{returnTitle(checkOutCode)}</h6>
                                    <p style={{ fontSize: 11 }}>
                                        {returnSubTitle(checkOutCode)}
                                    </p>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <p style={{ fontSize: 11 }}>{selectedOption3 ? selectedOption3.label : 1} x ${subTotal}</p>
                                    <p style={{ fontSize: "smaller", marginLeft: 10, fontWeight: "bold" }}>${total}</p>
                                </div>
                            </div>

                            {returnOptions1(checkOutCode) !== "null" && <Select
                                value={selectedOption1}
                                onChange={this.handleChange1}
                                options={returnOptions1(checkOutCode)}
                                isSearchable={false}
                                placeholder='Type of Package - Defaults to "Full Package"'
                                styles={selectStyles}
                            />}

                            <Select
                                value={selectedOption2}
                                onChange={this.handleChange2}
                                options={returnOptions2(checkOutCode)}
                                isSearchable={false}
                                placeholder='Type of Occupancy - Defaults to "Single Occupancy"'
                                styles={selectStyles}
                            />

                            <div style={{ width: "30%", height: 2, backgroundColor: "rgba(2, 68, 2, 0.4)", borderRadius: 20, marginBottom: 7, marginTop: 20 }}></div>
                            <div
                                style={{
                                    width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
                                    fontSize: 13, color: "grey", marginTop: 5
                                }}
                            >
                                <p>Sub-Total</p>
                                <p>${subTotal}</p>
                            </div>
                            <div style={{ width: "100%", height: 2, backgroundColor: "rgba(2, 68, 2, 0.2)", borderRadius: 20, marginBottom: 0, marginTop: 10 }}></div>
                            <div
                                style={{
                                    width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
                                    fontSize: 13, color: "grey", marginTop: 5
                                }}
                            >
                                <div>
                                    <h6 style={{ margin: 0, marginTop: 4 }}>Number of Payments</h6>
                                    <p style={{ fontSize: "x-small" }}>make multiple payments</p>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Select
                                        value={selectedOption3}
                                        onChange={this.handleChange3}
                                        options={returnOptions3(checkOutCode)}
                                        isSearchable={false}
                                        placeholder={selectedOption3 ? selectedOption3.label : 1}
                                        styles={selectStyles_small}
                                    />
                                    <p> x ${subTotal}</p>
                                </div>
                            </div>
                            <div style={{ width: "100%", height: 2, backgroundColor: "rgba(2, 68, 2,  0.2)", borderRadius: 20, marginBottom: 7, marginTop: 10 }}></div>
                            <div
                                style={{
                                    width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center",
                                    fontSize: 16, marginTop: 5, fontWeight: "bold"
                                }}
                            >
                                <p>Total</p>
                                <p>${total}</p>
                            </div>
                            <div style={{ width: "100%", height: 2, backgroundColor: "rgba(2, 68, 2, 0.4)", borderRadius: 20, marginBottom: 0, marginTop: 10 }}></div>
                            <p style={{ fontSize: 13, paddingBottom: 0, marginBottom: 1, fontWeight: "bold", marginTop: "1vw" }}>
                                Package Summary
                            </p>
                            <p style={{ fontSize: 12, paddingBottom: 0, marginBottom: 0 }}>
                                <span style={{ fontWeight: "bold" }}>Join us on our majestic black history experience tour<br /></span>
                                {returnSumary(checkOutCode)}
                            </p>

                            <div style={{ marginTop: 20 }}>
                                <p>current status: <span style={{ fontWeight: "bold", color: checkOut ? "green" : "blue" }}>{checkOut === true ? "Finishing Up ..." : "Making selection ..."}</span></p>
                                <h4 onClick={() => { this.FinishUp() }} className={checkOut ? '' : 'scaleOnHover clickIn'} style={{ padding: 10, color: "white", width: 300, backgroundColor: checkOut ? "black" : "rgb(58, 35, 7)", marginTop: 5, fontSize: "x-large", fontFamily: "sans-serif", cursor: "pointer", borderRadius: 10, }}>{checkOut ? "Checking Out" : "Finish Up"}</h4>

                                <Link to={'/Home'} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: 50 }}>
                                    <img style={{ height: 100, width: 70, objectFit: "cover", padding: 0, margin: 0, marginRight: 5 }} src={require('../../assets/img/logo_black.png')}></img>
                                    <div>
                                        <h1 style={{ fontFamily: "Allura-Regular", lineHeight: 0.05, marginTop: 30, fontSize: "x-large" }}>KINGDOM LIVING</h1>
                                        <p style={{ fontFamily: "Allura-Regular" }}>dating-african.com</p>
                                    </div>
                                </Link>
                                <p style={{ padding: 0, margin: 0 }}>
                                    {/* "TPCT10315" ? cover.CapeTown() : code === "GWHFZA8765" ? cover.Zanzibar() : cover.Ghana() */}
                                    {checkOutCode !== "G2023T10318" &&
                                        <>
                                            <Link to={'/Ghana_2024Trip'}>Ghana 2023Trip</Link>
                                            <span> | </span>
                                        </>
                                    }
                                    {checkOutCode !== "TPCT10315" &&
                                        <>
                                            <Link to={'/Cape_Town_Investment_Tour'}>Cape Town Investment Tour</Link>
                                            <span> | </span>
                                        </>
                                    }
                                    {checkOutCode !== "GWHFZA8765" &&
                                        <>
                                            <Link to={'/GIRLZ_JUST_WANNA_HAVE_FUN_ZANZIBAR_2024'}>Girlz Just Wanna Have Fun - Zanzibar 2024</Link>
                                            <span> | </span>
                                        </>
                                    }
                                    <Link to={'/AdvisorBenefits'}>Advisor Benefits</Link>
                                </p>
                            </div>

                        </div>
                        <div class="col-md-6 O_S_col_" style={{ display: "flex", flexDirection: "column" }}>
                            <h5>Check Out</h5>
                            <div style={{ width: "85%", height: 2, backgroundColor: "rgba(2, 68, 2, 0.1)", borderRadius: 20, marginBottom: 20, marginTop: 0, }}></div>
                            <div class="row">
                                <InputField
                                    conatainerCLass="col-md-6"
                                    placeholder="First Name"
                                    value=""
                                    required
                                />
                                <InputField
                                    conatainerCLass="col-md-6"
                                    placeholder="Last Name"
                                    value=""
                                    required
                                />
                            </div>

                            <div class="row">
                                <InputField
                                    conatainerCLass="col-md-6"
                                    placeholder="Address Line 1"
                                    value=""
                                    required
                                />
                                <InputField
                                    conatainerCLass="col-md-6"
                                    placeholder="Address Line 2"
                                    value=""
                                    required
                                />
                            </div>

                            <div class="row">
                                <InputField
                                    conatainerCLass="col-md-7"
                                    placeholder="City"
                                    value=""
                                    required
                                />
                                <InputField
                                    conatainerCLass="col-md-5"
                                    placeholder="State"
                                    value=""
                                    required
                                />
                            </div>
                            <div class="row">
                                <InputField
                                    conatainerCLass="col-md-12"
                                    placeholder="Zip Code"
                                    value=""
                                    required
                                />
                                <InputField
                                    conatainerCLass="col-md-12"
                                    placeholder="Phone Number"
                                    value=""
                                    required
                                />
                                <InputField
                                    conatainerCLass="col-md-12"
                                    placeholder="Email Address"
                                    value=""
                                    required
                                />
                            </div>

                            {/* <CheckoutForm /> */}
                            {
                                checkOut ?
                                    <ServerStripe props={{ selectedOption1, selectedOption2, selectedOption3, total, subTotal, checkOut }} />
                                    :
                                    <SripeDummyView props={{ selectedOption1, selectedOption2, selectedOption3, total, subTotal, checkOut }} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            // </Elements>
        )
    }
}



function CheckoutForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    // const stripe = useStripe();
    // const elements = useElements();
    const getClientSecret = () => { }
    // const payMoney = async (e) => {
    //     e.preventDefault();
    //     if (!stripe || !elements) {
    //         return;
    //     }
    //     setPaymentLoading(true);
    //     const clientSecret = getClientSecret();
    //     const paymentResult = await stripe.confirmCardPayment(clientSecret, {
    //         payment_method: {
    //             card: elements.getElement(CardElement),
    //             billing_details: {
    //                 name: "Faruq Yusuff",
    //             },
    //         },
    //     });
    //     setPaymentLoading(false);
    //     if (paymentResult.error) {
    //         alert(paymentResult.error.message);
    //     } else {
    //         if (paymentResult.paymentIntent.status === "succeeded") {
    //             alert("Success!");
    //         }
    //     }
    // };

    return (
        <div style={{ padding: "3rem", }} >
            <div style={{ maxWidth: "500px", margin: "0 auto", }}
            >
                <form style={{ display: "block", width: "100%", }}
                // onSubmit={payMoney}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {/* <CardElement
                            className="card"
                            options={{
                                style: {
                                    base: {
                                        backgroundColor: "white"
                                    }
                                },
                            }}
                        /> */}
                        <button
                            className="pay-button"
                            disabled={isPaymentLoading}
                        >
                            {isPaymentLoading ? "Loading..." : "Place Order"}
                        </button>
                        <p style={{ fontSize: 12, textAlign: "center", paddingBottom: 0, marginBottom: 0 }}>
                            Buy cliking the "Place Order" button you agree to Dating African® terms & conditions, and you
                            understand all payments to  Dating African® are non-refundable and non-transferrable.
                        </p>
                        <p style={{ fontSize: 12, textAlign: "center", fontWeight: "bold" }}>
                            You further acknowledge that I have been advised to purchase travel insurance to protect my investment.
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
}

const InputField = (props) => {
    const { conatainerCLass, subConatainerCLass, inputCLass, onChange, value, placeholder, required } = props
    const dClass = conatainerCLass ? conatainerCLass + " O_S_InputField_container" : " O_S_InputField_container"
    const sdClass = subConatainerCLass ? subConatainerCLass + " O_S_InputField_subCcontainer" : " O_S_InputField_subCcontainer"
    const iClass = inputCLass ? inputCLass + " O_S_InputField" : " O_S_InputField"
    return (
        <div class={dClass}>
            <div class={sdClass}>
                <p>{required ? "*" : ""} {placeholder ? placeholder : ""}</p>
                <input class={iClass} type="text" placeholder="" onChange={(e) => { onChange(e.target.value) }} value={value ? value : ""} />
            </div>
        </div>
    )
}


export default CheckOut;







// const colourStyles = {
//     control: (styles) => ({ ...styles, backgroundColor: 'white' }),
//     option: (styles, { data, isDisabled, isFocused, isSelected }) => {
//       const color = chroma(data.color);
//       return {
//         ...styles,
//         backgroundColor: isDisabled
//           ? undefined
//           : isSelected
//           ? data.color
//           : isFocused
//           ? color.alpha(0.1).css()
//           : undefined,
//         color: isDisabled
//           ? '#ccc'
//           : isSelected
//           ? chroma.contrast(color, 'white') > 2
//             ? 'white'
//             : 'black'
//           : data.color,
//         cursor: isDisabled ? 'not-allowed' : 'default',

//         ':active': {
//           ...styles[':active'],
//           backgroundColor: !isDisabled
//             ? isSelected
//               ? data.color
//               : color.alpha(0.3).css()
//             : undefined,
//         },
//       };
//     },
//     input: (styles) => ({ ...styles, ...dot() }),
//     placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
//     singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
//   };