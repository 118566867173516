import React, { Component } from 'react';
import '../../App.css';
import HeaderAnimComp, { CoolTravel_OurServices, HomeHiglight, Home_intro } from '../components/HeaderAnimComp';
import Heading from '../components/Heading';
import LatestNews from '../components/LatestNews';

const { innerHeight, innerWidth } = window



class MainScreen extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            headings: [
                {
                    title: "",
                    noTitle: true,
                    text: [
                        {
                            t: "Our advisors", type: "div", url: "",
                            style: { width: "100%", backgroundColor: "rgba(255,255,255,0.1)", background: "url(" + require('../../assets/img/tourits.jpeg') + ") center", backgroundSize: "cover" },
                            children: [
                                {
                                    type: "div", style: { backgroundColor: "rgba(255,255,255,0.1)", backgroundImage: 'linear-gradient(to bottom, rgb(207,207,207) , rgba(255,255,255,0.3)' },
                                    children: [
                                        { t: "Explore The World of Kingdom Living", type: "text", url: "", style: { color: "black", fontSize: 25, fontWeight: "bold", paddingTop: 50 } },
                                        { t: "Hot Hotel Deals, In-Depth Destination Guides, Travel Stories, Trip Planning Tips, Updated News Around The Continent And More.", type: "text", url: "", style: { color: "black", fontWeight: "200", paddingTop: 10, paddingBottom: 40 } },
                                    ]
                                },
                                {
                                    type: "div", class: "row container", style: { backgroundColor: "rgba(255,255,255,0.4)", width: "100%", paddingLeft: 0, paddingRight: 0 },
                                    children: [
                                        // {type: "div", style: { height: "100%", backgroundColor: "rgba(255,255,255,0.2)", position: "absolute", width: "100%" }, children: []},
                                        {
                                            type: "div", class: " col-md-6", style: { height: "inherit", paddingLeft: 0, paddingRight: 0 },
                                            children: [
                                                { t: "Dating African- Kingdom Living Travel & Tours", type: "text", url: "", style: { color: "black", fontWeight: "bolder", paddingTop: 0, textAlign: "left" } },
                                                { t: "Welcome to our travel website, Kingdom Living Travel & Tours By Dating-African, LLC!", type: "text", url: "", style: { color: "black", fontWeight: "normal", paddingTop: 15, letterSpacing: "normal", textAlign: "left" } },
                                                {
                                                    t: `The title of our website probably drew you to look further into who we are and if you can get a date;
                                                however, it is beyond dating a human being. Dating African is for individuals that have an interest in
                                                Africa but cannot seem to get moving to travel beyond their borders to see what has piqued his/her
                                                interest. This site is even for those of you that have visited the continent multiple times
                                                and have flirted with the idea of relocating, but you have not taken the plunge. You are Dating African!!!`, type: "text", url: "", style: { color: "black", fontWeight: "normal", paddingTop: 10, paddingBottom: 25, letterSpacing: "normal", textAlign: "left" }
                                                },
                                            ]
                                        },
                                        {
                                            type: "div", class: " col-md-6", style: { height: "inherit", paddingLeft: 0, paddingRight: 0 },
                                            children: [
                                                {
                                                    t: `Africa is a unique continent! It is the world’s second largest and second-most populous continent, after
                                                Asia in both cases. There are about 1250-3000 native languages. How intriguing is that? There is no
                                                geographical location like it! Many fall in love with the sounds, the vibrant colors, the hospitable people,
                                                and the vivacious cultures. As you can tell…..Our specialization is AFRICA!!!`, type: "text", url: "", style: { color: "black", fontWeight: "normal", paddingTop: 10, paddingBottom: 5, letterSpacing: "normal", textAlign: "left" }
                                                },
                                                {
                                                    t: `You will find our inspirational travel blogs empowering and uplifting. Come and experience Kingdom Living!
                                                We are ready to help you plan an unforgettable travel experience whether it be business, faith based, or pleasure!`, type: "text", url: "", style: { color: "black", fontWeight: "normal", paddingTop: 5, paddingBottom: 35, letterSpacing: "normal", textAlign: "left" }
                                                },
                                            ]
                                        },
                                    ]
                                },
                            ]
                        },
                        // { t: "", type: "text", url: "", style: { color: "white" } }
                    ],
                    linkText: "",
                    path: "/Blogs",
                    style: "",
                    externalUrlName: "",
                    externalUrl: "",
                },
                {
                    title: "THE PATHWAY TO GHANA",
                    titleStyle: { marginTop: 0, textAlign: "center", width: "100%", paddingLeft: "6%" },
                    text: [{ t: "Are you interested in relocating to Ghana, but you are unsure of how to do it? We are here to assist you with your relocation process. With the nation’s cultural heritage and economic prowess, you have every reason to be. Our guide to Ghana has details on the admin issues you face before relocation.", type: "text", url: "" }],
                    linkText: "READ MORE",
                    path: "/ThePathwayToGhana",
                    style: "boldDialogue2",
                    externalUrlName: "",
                    externalUrl: "",
                },
                {
                    title: "THE PATHWAY TO GHANA",
                    noTitle: true,
                    noHR: true,
                    text: [],
                    linkText: "",
                    path: "",
                    style: "CoolVideoPlayer",
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { height: innerWidth >= 991 ? innerHeight : innerWidth >= 791 ? innerHeight * 0.7 : innerHeight * 0.4, marginTop: 20, width: innerWidth, }
                },
                {
                    title: "Book your travel with us today!",
                    text: [{ t: "Our advisors are jetsetters with firsthand knowledge and experience to elevate your travel to the next level. We specialize in African tourism ...", type: "text", url: "", style: { color: "white" } }],
                    linkText: "READ MORE",
                    path: "/BookTravel",
                    style: "img_Card",
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundImage: "url(" + require('../../assets/img/Kingdom_Living_Travel__Tours_Flyer_Design_cop_.jpg') + ")", height: window.innerHeight, overflow: "hidden", justifyContent: "center", alignItems: "center", display: "flex", backgroundSize: "cover", backgroundPosition: "center", marginTop: 30, marginBottom: 0 },
                    titleStyle: { color: "white", fontSize: 60, textAlign: "center", textTransform: "capitalize", marginLeft: 4, marginRight: 4 },
                    noHR: true
                },
                {
                    title: "READ BY TOPIC",
                    text: [],
                    linkText: "Load More",
                    path: "/Blogs",
                    style: "READ",
                    externalUrlName: "",
                    externalUrl: "",
                }
            ]
        }
    }

    render() {
        const { headings } = this.state
        return (
            <>
                <HeaderAnimComp />
                <HomeHiglight />
                <Home_intro />
                <About />
                {/* <Heading value={headings[0]} /> */}
                {/* <Heading value={headings[1]} /> */}
                <CoolTravel_OurServices />
                {/* <OurServices /> */}
                <Heading0 />
                <Heading value={headings[3]} />
                <div style={{ backgroundImage: 'linear-gradient(to bottom, rgba(3, 39, 21, 0.729) , rgba(255,255,255,0)' }}>
                    <Heading value={headings[4]} />
                </div>
                {/* <LatestNews /> */}
                {/* {headings.map((value, index, array) => {
                    return (
                        <Heading value={value} />
                    )
                })} */}
                {/* <div class={'App__'}></div> */}
            </>
        );
    }
}

export default MainScreen;



class Heading0 extends Component {
    render() {
        const style = {
            Heading: { width: "100vw", display: "flex", flexDirection: "column", alignItems: "center", marginTop: 30 },
            container: { position: "relative", width: "80vw", backgroundColor: "rgba(0,0,0,0.1)", background: "url(" + require('../../assets/img/kingdomlivingwebsitecontent/airportSample-.jpg') + ") center", backgroundSize: "cover" },
            head: { backgroundColor: "rgba(0,0,0,0)", backgroundImage: 'linear-gradient(to bottom, rgb(255,255,255) , rgba(0,0,0,0)', padding: 20, paddingBottom: 0, paddingTop: 0 },
            headTitle: { color: "white", fontSize: 25, fontWeight: "bold", paddingTop: 50, textAlign: "center", position: "relative", letterSpacing: 1.2 },
            subTitle: { color: "white", fontWeight: "200", paddingTop: 10, paddingBottom: 40, textAlign: "center", position: "relative", letterSpacing: 1.2 },
            body: { paddingLeft: 30, paddingRight: 30 },
            col: { height: "inherit", paddingLeft: 10, paddingRight: 10, paddingBottom: 30 },
            col1Title: { color: "white", fontWeight: "bolder", paddingTop: 0, textAlign: "left", letterSpacing: 1.2 },
            colText: { color: "white", fontWeight: "normal", paddingTop: 15, letterSpacing: "normal", textAlign: "left", letterSpacing: 1.2 },
            line: { width: "60vw", height: 2, backgroundColor: "rgba(0,0,0,0.2)", marginTop: 10 }
        }
        return (
            <div style={style.Heading}>
                <div style={style.container}>
                    <div style={{width:"100%", height: "100%", backgroundColor: "rgba(0,0,0,0.4)", position: "absolute", top: 0, left: 0,}}></div>
                    <div style={style.head}>
                        <p style={style.headTitle}>Explore The World of Kingdom Living</p>
                        <p style={style.subTitle}>Hot Hotel Deals, In-Depth Destination Guides, Travel Stories, Trip Planning Tips, Updated News Around The Continent And More.</p>
                    </div>
                    <div className="row" style={style.body}>
                        <div className='col-md-6' style={style.col}>
                            <p style={style.col1Title}>Dating African- Kingdom Living Travel & Tours</p>
                            <p style={style.colText}>Welcome to our travel website, Kingdom Living Travel & Tours By Dating-African, LLC!</p>
                            <p style={style.colText}>
                                The title of our website probably drew you to look further into who we are and if you can get a date;
                                however, it is beyond dating a human being. Dating African is for individuals that have an interest in
                                Africa but cannot seem to get moving to travel beyond their borders to see what has piqued his/her
                                interest. This site is even for those of you that have visited the continent multiple times
                                and have flirted with the idea of relocating, but you have not taken the plunge. You are Dating African!!!
                            </p>
                        </div>
                        <div className='col-md-6' style={style.col}>
                            <p style={style.colText}>
                                Africa is a unique continent! It is the world’s second largest and second-most populous continent, after
                                Asia in both cases. There are about 1250-3000 native languages. How intriguing is that? There is no
                                geographical location like it! Many fall in love with the sounds, the vibrant colors, the hospitable people,
                                and the vivacious cultures. As you can tell…..Our specialization is AFRICA!!!
                            </p>
                            <p style={style.colText}>
                                You will find our inspirational travel blogs empowering and uplifting. Come and experience Kingdom Living!
                                We are ready to help you plan an unforgettable travel experience whether it be business, faith based, or pleasure!
                            </p>
                        </div>
                    </div>
                </div>
                <div style={style.line}></div>
            </div>
        )
    }
}

class About extends Component {
    render() {
        return (
            <div className='HAbout'>
                <div className='row'>
                    <div className='col-md-6 HAI'>
                        <div className='HAI1'>
                            <div className='HAI_card'>
                                <h1>Our Mission</h1>
                                <p>
                                    Unleashing the Potential: Connecting Tourism and Investing in Africa for Sustainable Growth and
                                    Prosperity. Our mission is to bring together visionary investors and vibrant tourism ventures
                                    across Africa, driving economic development, creating job opportunities, and preserving cultural
                                    heritage. Join us as we unlock the transformative power of tourism and investing in Africa.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 HAI'>
                        <div className='HAI2'>
                            <div className='HAI_card'>
                                <h1>Our Vision</h1>
                                <p>
                                    Empowering Growth through Tourism and Investing: Our vision is to position Africa as a premier
                                    destination for tourism and investment, where travelers can discover diverse cultures,
                                    breathtaking landscapes, and thriving business opportunities. By fostering sustainable
                                    development and forging strategic partnerships, we aim to create a prosperous future for
                                    Africa's tourism and investment sectors.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class OurServices extends Component {
    constructor() {
        super();
        this.state = {
            services: [
                {
                    index: 1,
                    title: "Customized Travel Tours:",
                    service: [
                        "Tailor-made itineraries that cater to the unique preferences and interests of travelers.",
                        "Expert guidance in selecting destinations, arranging accommodations, transportation, and activities.",
                        "Access to local guides who provide cultural insights and ensure a memorable travel experience."
                    ]
                },
                {
                    index: 2,
                    title: "Investment Tours:",
                    service: [
                        "Exclusive tours designed for investors seeking firsthand knowledge of Africa's investment landscape.",
                        "Visits to key business hubs, industry-specific events, and meetings with local entrepreneurs and government officials.",
                        "Insightful presentations and analysis of investment opportunities across various sectors."
                    ]
                },
                {
                    index: 3,
                    title: "Market Research and Analysis:",
                    service: [
                        "In-depth research reports on tourism and investment trends in Africa.",
                        "Market analysis to identify potential investment opportunities and emerging tourism markets.",
                        "Expert insights and guidance on investment strategies and market entry."
                    ]
                },
                {
                    index: 4,
                    title: "Due Diligence Support:",
                    service: [
                        "Assistance with conducting due diligence on investment opportunities, including regulatory compliance and risk assessment.",
                        "Access to local legal, financial, and advisory services to ensure informed investment decisions.",
                        "Support in navigating local regulations, permits, and licensing requirements."
                    ]
                },
                {
                    index: 5,
                    title: "Networking and Partnership Facilitation:",
                    service: [
                        "Networking events and forums connecting investors with local businesses, industry experts, and potential partners.",
                        "Facilitation of strategic partnerships between investors and tourism operators, hoteliers, and other stakeholders.",
                        "Platform for knowledge exchange, collaboration, and fostering business relationships."
                    ]
                },
                {
                    index: 6,
                    title: "Sustainability and Impact:",
                    service: [
                        "Emphasis on sustainable tourism practices and investment in projects that promote environmental conservation and community development.",
                        "Guidance on socially responsible investment options and impact measurement.",
                        "Support for projects that contribute to the UN Sustainable Development Goals."
                    ]
                },
            ]
        }
    }

    Service = (val, index) => {
        return (
            <div className={'HOS_serviceCard scaleOnHover HOS_serviceCard' + index}>
                <div className='bulletIndicator'>
                    {/* <div className='bulletIcon'></div> */}
                    {/* <div className='bulletDash'></div> */}
                </div>
                <div className='bulletCard'>
                    <div className={'bulletCardImg bulletCardImg' + index}></div>
                    <h1>{val.title}</h1>
                    <ul>
                        {val.service.map((val, i) => {
                            return (
                                <li>{val}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    render() {
        const { services } = this.state;
        return (
            <div className='HOServices'>
                <div className='row'>
                    <h1 className='HOServices_h1'>Our Services</h1>
                    <div className='col-md-6 HOS'>
                        {services.slice(0, 3).map((val) => {
                            return (
                                this.Service(val, val.index)
                            )
                        })}
                    </div>
                    <div className='col-md-6 HOS'>
                        {services.slice(3, 6).map((val, index) => {
                            return (
                                this.Service(val, val.index)
                            )
                        })}
                    </div>
                </div>
                <div className='HOServices_imgBG'></div>
                <div className='HOServices_imgBG_'>
                    <div className='HOServices_imgBG_gradient'></div>
                    <div className='HOServices_imgBG_gradient2'></div>
                </div>
            </div>
        )
    }
}
