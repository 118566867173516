import React, { Component } from 'react';
import '../../App.css';
import FadingGradient from '../components/FadingGradient';
import Heading from '../components/Heading';
import ImageComp from '../components/ImageComp';
import LatestNews from '../components/LatestNews';



class ContactScreen extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            headings: [
                {
                    title: "Contact Us",
                    titleStyle: { marginTop: 0, textAlign: "left", width: "100%", paddingLeft: "6%" },
                    text: [
                        { t: `Kingdom Living Travel & Tours By Dating African, LLC`, type: "text", url: "", style: { textAlign: "left", width: "100%", } },
                        { t: ``, type: "list", url: "", style: { textAlign: "left", width: "100%", fontFamily: "Verdana" }, data: [
                            { t: `Phone: (619) 865-3281`, type: "listChild", url: "", style: { textAlign: "left", width: "100%", fontFamily: "Verdana" } },
                            { t: `Email: kingdomliving.traveltours@gmail.com`, type: "listChild", url: "", style: { textAlign: "left", width: "100%", fontFamily: "Verdana" } },
                            { t: `Mailing Address: 30777 Rancho California RD #892041, Temecula, CA 92591-9998`, type: "listChild", url: "", style: { textAlign: "left", width: "100%", fontFamily: "Verdana" } },
                        ] },
                    ],
                    linkText: "",
                    path: "",
                    style: "boldDialogue2",
                    externalUrlName: "",
                    externalUrl: "",
                    noHR: true,
                },
                {
                    title: "Book your travel with us today!",
                    text: [{ t: "Our advisors are jetsetters with firsthand knowledge and experience to elevate your travel to the next level. We specialize in African tourism ...", type: "text", url: "", style: { color: "white" } }],
                    linkText: "READ MORE",
                    path: "/BookTravel",
                    style: "img_Card",
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundImage: "url(" + require('../../assets/img/Kingdom_Living_Travel__Tours_Flyer_Design_cop_.jpg') + ")", height: window.innerHeight, overflow: "hidden", justifyContent: "center", alignItems: "center", display: "flex", backgroundSize: "cover", backgroundPosition: "center", marginTop: 30, marginBottom: 0 },
                    titleStyle: { color: "white", fontSize: 60, textAlign: "center", textTransform: "capitalize", marginLeft: 4, marginRight: 4 },
                    noHR: true
                },
            ]
        }
    }

    render() {
        const { headings } = this.state
        return (
            <>
                <FadingGradient color1="rgb(219,53,57)" color2="rgb(207,207,207)" />
                <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)" height={80}/>
                {headings.map((value, index, array) => {
                    return (
                        <Heading value={value} />
                    )
                })}
            </>
        );
    }
}

export default ContactScreen;
