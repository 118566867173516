
'use strict';

import React from 'react';
import FileUploadProgress from 'react-fileupload-progress';
import axios from 'axios';
import Elements from './elements';


class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsType: "",
            elements: [
                {
                    elementType: "container",
                    style: {
                        width: "100%",
                        height: 600,
                        backgroundColor: "blue",
                    },
                    class: "",
                    children: [
                        {
                            elementType: "container",
                            style: {
                                // width: "60%",
                                height: 200,
                                backgroundColor: "red",
                            },
                            class: "col-md-6",
                            children: [
                                { 
                                    elementType: "P",
                                    style: {
                                        // width: "60%",
                                        height: 200,
                                        color: "white",
                                    },
                                    class: "",
                                    value: "this is an example" 
                                }
                            ]
                        },
                        {
                            elementType: "container",
                            style: {
                                // width: "60%",
                                height: 200,
                                backgroundColor: "yellow",
                            },
                            class: "col-md-6",
                            children: [{ elementType: "gg" }]
                        }
                    ]
                }
            ],
        }
    }



    openAdmin = () => {
        this.setState({
            settingsType: "admin"
        })
    }
    openUser = () => {
        this.setState({
            settingsType: "user"
        })
    }
    goBack = () => {
        this.setState({
            settingsType: ""
        })
    }

    renderAdmin = () => {
        const { value, error, feild_1, progressTex1, progressTex2 } = this.state
        return (
            <div>
                <h1>Admin Settings</h1>
                <input type="hidden" name='".ini_get("session.upload_progress.name"); ."' value="123" />
                {/* <input type="file" onChange={this.fileSelect} /> */}
                <label class="custom-file-upload">
                    <input type="file" onChange={this.fileSelect} />
                    Click to select file, or drag and drop file here
                </label>
                <div class="UP_progress" style={{ height: value === 0 ? 0 : 30 }}>
                    {/* <button onClick={this.cancelUpload}>cancle</button> */}
                    <div class="progress_bar" style={{ width: value + "%" }}></div>
                </div>
                <div class="UP_progress_">
                    {
                        value === 0 ?
                            null
                            :
                            <p>{value}%</p>
                    }
                    {
                        value === 0 ?
                            null
                            :
                            <p>{this.niceBytes(progressTex1)} of {this.niceBytes(progressTex2)}</p>
                    }
                    <p>{error}</p>
                </div>
                <h4 style={{ marginBottom: 0, paddingBottom: 0, marginTop: 30 }}>Gallery Password</h4>
                <p style={{ marginTop: 0, paddingTop: 5, color: "black" }}>Type Gallery Password before attempting to upload</p>
                <input style={{ width: "100%", marginLeft: 0, }} class="log_in_box_input real_login" type="password" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1}></input>
            </div>
        )
    }
    renderUser = () => {
        return (
            <div>
                <h1>User Settings</h1>
            </div>
        )
    }


    render() {
        const { settingsType, elements } = this.state;
        const {boostrapWidth} = this.props
        const box1 = settingsType === "" ? "slideInLeft" : "slideOutLeft";
        const box2 = settingsType === "" ? "slideOutRight" : "slideInRight";
        return (
            <>
                <div class={"settings_ animated " + box1}>
                    <h1 class="settingsHead">Editor</h1>
                    <p class="psmall settingsHead">The bellow is your spreadshit, hover over elements for edit options to apear</p>
                    {
                        elements.map((value, index, array) => {
                            return (
                                <Elements elements={value} index={index} boostrapWidth={boostrapWidth}/>
                            )
                        })
                    }
                </div>
            </>
        )
    }
};



export default Settings;


