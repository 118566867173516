import React from "react";
import { Link } from "react-router-dom";

// export default class HeaderAnimComp extends React.Component {
//     render() {
//         return (
//             <header class="HeaderAnimComp" style={{}}>
//                 <div className="devLine1"></div>
//                 <div className="circleButton2 scaleOnHover"></div>
//                 <div className="circleButton3 scaleOnHover"></div>
//                 <div className="circleButton4 scaleOnHover"></div>
//                 <div className="devLine2"></div>
//                 <div className="devLine3"></div>
//                 <div className="devLine4"></div>
//                 {/* <h1>{bubble}</h1> */}


//             </header>
//         )
//     }
// }












class HeaderAnimComp extends React.Component {

    constructor() {
        super();
        this.state = {
            bubble: 0,
            pageJustOpened: true,
            showInfo: false,
            slides_: [
                {
                    title: "Kakum National Park",
                    info: "Considered the nation's park, Kakum National Park is a destination that every tourist must see. It has an area of 145 square meters and is located on the coast of southern Ghana (specifically the Central Region of Ghana). The park also has a canopy walk suspended about 30 meters above the ground. The canopy walk can sometimes be scary if you are trying it for the first time, but it shouldn't be a problem if you have a sense of adventure. It provides a view above the trees and allows you to appreciate the surrounding nature."
                },
                {
                    title: "Kumasi Zoo",
                    info: "The Kumasi Zoo (Kumasi Zoological Garden) is a zoo located in the heart of Kumasi in the Ashanti Region of Ghana. It has about 40 different species of animals, with individual animals numbering over 135. A notable feature is the thousands of bats that rest on trees in the zoo."
                },
                {
                    title: "Mole National Park",
                    info: "Mole National Park is Ghana's largest wildlife refuge. Mole National Park protects an area of savannah and forest in northern Ghana. It’s home to elephants, leopards and rare birds such as the white-backed vulture. In the western part of the park, the Konkori Escarpment has panoramic views and overlooks waterholes where animals drink. There are waterfalls along the Kparia and Polzen rivers. To the south, Larabanga village has a centuries-old, Sudanese-style mosque."
                },
                {
                    title: "Visit Cape Coast Castle",
                    info: "Cape Coast Castle is the largest of the buildings which contains the legacy of the trans-Atlantic slave trade and is a UNESCO World Heritage Site. Like most ancient fortifications in Ghana, Cape Coast Castle played a significant role in the gold and slave trades. But also, two significant contributions were made here: the arrival of Christianity, and the establishment of the first formal education system through Castle Schools."
                },
                {
                    title: "Umbrella Rock",
                    info: "The Umbrella Rock is a rock found at the site of the Boti Falls in Yilo Krobo District, Ghana. As the name implies, the overhang on the top is large enough to cover 12 to 15 people at once."
                },
                {
                    title: "Lake Volta",
                    info: "Lake Volta, the largest artificial reservoir in the world based on surface area, is contained behind the Akosombo Dam which generates a substantial amount of Ghana's electricity. It is completely within the country of Ghana and has a surface area of 8,502 square kilometres. It extends from Akosombo in the south to the northern part of the country"
                },
                {
                    title: "The Zaina Lodge in Mole National Park",
                    info: "Within the confines of the Mole National Park, visitors have two options for accommodation: the Mole Motel or the Zaina Lodge. The names probably indicate where each falls on the “luxury” spectrum. But you probably wouldn’t believe just how beautiful the Zaina Lodge is, until you step onto its grounds."
                },
                {
                    title: "Labadi Beach",
                    info: "Labadi Beach or more properly known as La Pleasure Beach is the busiest beach on Ghana's coast.[citation needed] It is one of Accra's beaches and is maintained by the local hotels. Labadi Beach is in a town called La, popularly known as Labadi, near Teshie in the Greater Accra Region of Ghana. "
                },
            ],
            slides: [
                {
                    index: 1,
                    title: "Customized",
                    titleSpan: "Travel Tours",
                    subTitle: "Book your travel with us today!",
                    info: "Our advisors are jetsetters with firsthand knowledge and experience to elevate your travel to the next level.",
                    link: "/BookTravel",
                    linkText: "Read More",
                    spanInvert: false,
                },
                {
                    index: 2,
                    title: "March 24 -",
                    titleSpan: "April 3, 2024",
                    subTitle: "Ghana 2024Trip",
                    info: "Accra, Kumasi, and Cape Coast. Come and experience the finer side of life- as we welcome you home to the Motherland.",
                    link: "/Ghana_2024Trip",
                    linkText: "Book Now",
                    spanInvert: true,
                },
                {
                    index: 3,
                    title: "Investment Tours:",
                    titleSpan: "Cape Town",
                    subTitle: "February 03- 09, 2024",
                    info: "Explore the fascinating world of mining in Cape Town, South Africa, with our exclusive Mining Tour Package.",
                    link: "/Cape_Town_Investment_Tour",
                    linkText: "Book Now",
                    spanInvert: false,
                },
                {
                    index: 3,
                    title: "Girlz Just Wanna Have",
                    titleSpan: "Fun",
                    subTitle: "MELIA ZANZIBAR 2024",
                    info: "Melia Zanzibar in Kiwengwa offers a paradise-like setting, surrounded by tropical gardens and overlooking the turquoise waters of the Indian Ocean.",
                    link: "/GIRLZ_JUST_WANNA_HAVE_FUN_ZANZIBAR_2024",
                    linkText: "Book Now",
                    spanInvert: false,
                },
                // {
                //     index: 4,
                //     title: "Due Diligence Support:",
                //     info: "Assistance with conducting due diligence on investment opportunities, including regulatory compliance and risk assessment.",
                //     link: "",
                // },
                // {
                //     index: 5,
                //     title: "Networking and Partnership Facilitation:",
                //     info: "Facilitation of strategic partnerships between investors and tourism operators, hoteliers, and other stakeholders.",
                //     link: "",
                // },
                // {
                //     index: 6,
                //     title: "Sustainability and Impact:",
                //     info: "Emphasis on sustainable tourism practices and investment in projects that promote environmental conservation and community development.",
                //     link: "",
                // },
            ]
        }
    }

    componentDidMount() {
        this.animObjects()
        // this.animObjects_bias()
    }

    animObjects = () => {
        const { bubble, pageJustOpened, slides } = this.state
        const maxSlide = slides.length - 1
        const newbubble = bubble + 1
        const timeout = pageJustOpened ? 0 : 10000;
        if (maxSlide >= bubble) {
            setTimeout(() => {
                this.setState({ showInfo: false })
                setTimeout(() => {
                    this.setState({ bubble: newbubble, pageJustOpened: false })
                    setTimeout(() => {
                        this.setState({ showInfo: true })
                        this.animObjects();
                    }, 1600);
                }, 600);
            }, timeout);
        } else {
            setTimeout(() => {
                this.setState({ showInfo: false })
                setTimeout(() => {
                    this.setState({ bubble: 1 })
                    setTimeout(() => {
                        this.setState({ showInfo: true })
                        this.animObjects()
                    }, 1600);
                }, 600);
            }, timeout);
        }
    }

    animObjects_bias = () => {
        this.setState({ showInfo: false, bubble: 4, pageJustOpened: false, showInfo: true })
    }

    render() {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const { bubble, showInfo, slides } = this.state
        return (
            <header class="HeaderAnimComp" style={{ height, backgroundColor: "rgba(0,0,0,0.8)" }}>
                <div class="Bubbles_holder">
                    {slides.map((value, index, array) => {
                        return (
                            <Bubble index={index} value={value} height={height} width={width} bubble={bubble} showInfo={showInfo} />
                        )
                    })}
                    {/* <div class="Bubble3" style={{ width: bubble === 3 ? heightx3 : 0, height: bubble === 3 ? heightx3 : 0 }}></div>
                    <div class="Bubble4" style={{ width: bubble === 4 ? heightx3 : 0, height: bubble === 4 ? heightx3 : 0 }}></div>
                    <div class="Bubble5" style={{ width: bubble === 5 ? heightx3 : 0, height: bubble === 5 ? heightx3 : 0 }}></div> */}
                    {/* <div class="Bubble2"></div>
                    <div class="Bubble3"></div>
                    <div class="Bubble4"></div>
                    <div class="Bubble5"></div> */}
                </div>
            </header>
        )
    }
}
class Bubble extends React.Component {
    render() {
        const { bubble, height, width, showInfo, value } = this.props
        const index = this.props.index + 1
        return (
            <div class={"Bubble" + index + " Bubble"} style={{ height: bubble === index ? height : 0, }}>
                <div class="Bubble_img" style={{ width: width, height: height }}></div>
                <div class="Bubble_info" style={{ opacity: showInfo === true ? index : 0, width: width, height: height }}>
                    <div class="Bubble_info_dialogue">
                        <h2 class="Bubble_info_h2" style={{ color: value.spanInvert === true ? "#04ac1b" : null }}>
                            {value.title}
                            <span style={{ color: value.spanInvert === true ? "white" : "#04ac1b" }}> {value.titleSpan}</span>
                        </h2>
                        <h1 class="Bubble_info_h1">{value.subTitle}</h1>
                        <p class="Bubble_info_p">{value.info}</p>
                        <Link class="Bubble_info_a" to={value.link}>{value.linkText}</Link>
                    </div>
                </div>
            </div>
        )
    }
}



class Home_intro extends React.Component {
    render() {
        return (
            <header class="HeaderAnimComp" style={{}}>
                <div className="home_intro">
                    <div className="row home_intro_">
                        <h1>Bringing the World of Tourism and Investing Together: </h1>
                        <h2>Unlocking Opportunities for Growth</h2>
                        <div className="col-md-6">
                            <p>
                                Welcome to our platform, where we seamlessly unite the realms of tourism and investing.
                                We understand that tourism is not only about exploration and experiences but also presents
                                lucrative investment prospects. Our mission is to connect visionary investors with
                                promising tourism ventures, fueling sustainable growth and mutual prosperity.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Through our platform, investors gain access to a diverse range of investment opportunities in
                                the tourism sector, including hotels, resorts, travel technology, and eco-friendly
                                initiatives. We provide comprehensive services, market insights, and a collaborative
                                community, facilitating the fusion of tourism and investing for unparalleled success.
                                Join us today and unlock the potential of this dynamic industry.
                            </p>
                        </div>
                    </div>
                    <div className="Home_linearGradien"></div>
                </div>
            </header>
        )
    }
}



class HomeHiglight extends React.Component {
    render() {
        return (
            <header class="HeaderAnimComp" style={{}}>
                <div className="homeHiglight">
                    <div className="devLine1"></div>
                    <div className="circleButton2 scaleOnHover"></div>
                    <div className="circleButton3 scaleOnHover"></div>
                    <div className="circleButton4 scaleOnHover"></div>
                    <div className="devLine2"></div>
                    <div className="devLine3"></div>
                    <div className="devLine4"></div>
                    <div className="homeHiglight_child homeHiglight_child1 scaleOnHover">
                        <div></div>
                        <img className="HHC_icon" src={require('../../assets/img/Icons/Protect(4).png')} style={{ objectFit: "cover" }} />
                        <h4>Best Price Guarantee</h4>
                        {/* <p>text</p> */}
                    </div>
                    <div className="homeHiglight_child homeHiglight_child2 scaleOnHover">
                        <div></div>
                        <img className="HHC_icon" src={require('../../assets/img/Icons/Two-Hearts(4).png')} style={{ objectFit: "cover" }} />
                        <h4>Travelers and Tourists love us</h4>
                    </div>
                    <div className="homeHiglight_child homeHiglight_child3 scaleOnHover">
                        <div></div>
                        <img className="HHC_icon" src={require('../../assets/img/Icons/Spy(4).png')} style={{ objectFit: "cover" }} />
                        <h4>Best Travel Agents</h4>
                    </div>
                    <div className="homeHiglight_child homeHiglight_child4 scaleOnHover">
                        <div></div>
                        <img className="HHC_icon" src={require('../../assets/img/Icons/Headset(4).png')} style={{ objectFit: "cover" }} />
                        <h4>Satisfying Dedicated Support</h4>
                    </div>
                </div>
            </header>
        )
    }
}



class CoolTravelHeader extends React.Component {

    constructor() {
        super();
        this.state = {
            bubble: 0,
            pageJustOpened: true,
            showInfo: false,
            slides: [
                {
                    title: "Customized Travel Tours",
                    info: "Expert guidance in selecting destinations, arranging accommodations, transportation, and activities."
                },
                {
                    title: "Investment Tours",
                    info: "Visits to key business hubs, industry-specific events, and meetings with local entrepreneurs and government"
                },
                {
                    title: "Market Research and Analysis",
                    info: "Expert insights and guidance on investment strategies and market entry."
                },
                {
                    title: "Due Diligence Support",
                    info: "Support in navigating local regulations, permits, and licensing requirements."
                },
            ],
        }
    }

    componentDidMount() {
        this.animObjects()
        // this.animObjects_bias()
    }

    animObjects = () => {
        const { bubble, pageJustOpened } = this.state
        const newbubble = bubble + 1
        const timeout = pageJustOpened ? 0 : 10000;
        if (3 >= bubble) {
            setTimeout(() => {
                this.setState({ showInfo: false })
                setTimeout(() => {
                    this.setState({ bubble: newbubble, pageJustOpened: false })
                    setTimeout(() => {
                        this.setState({ showInfo: true })
                        this.animObjects();
                    }, 1600);
                }, 600);
            }, timeout);
        } else {
            setTimeout(() => {
                this.setState({ showInfo: false })
                setTimeout(() => {
                    this.setState({ bubble: 1 })
                    setTimeout(() => {
                        this.setState({ showInfo: true })
                        this.animObjects()
                    }, 1600);
                }, 600);
            }, timeout);
        }
    }

    animObjects_bias = () => {
        this.setState({ showInfo: false, bubble: 4, pageJustOpened: false, showInfo: true })
    }

    render() {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const { bubble, showInfo, slides } = this.state
        return (
            <header class="HeaderAnimComp" style={{}}>
                <div className="coolTravelHeader">
                    <div className="CTH_col CTH_col1">
                        <img className="CTH_col_img" src={require('../../assets/img/kingdomlivingwebsitecontent/airportSample2.jpg')} style={{ objectFit: "cover" }} />
                        <div className="darkOverlay"></div>
                        <img className="africa_" src={require('../../assets/img/kingdomlivingwebsitecontent/africa.png')} style={{ objectFit: "cover" }} />
                        <div className="circleButton scaleOnHover"></div>
                    </div>
                    <div className="CTH_col CTH_col2">
                        <div className="warp1">
                            {slides.map((value, index, array) => {
                                return (
                                    <Warp props={{ position: "Top", bubble, index }} />
                                )
                            })}
                        </div>
                        <div className="warp2">
                            {slides.map((value, index, array) => {
                                return (
                                    <Warp props={{ position: "Bottom", bubble, index }} />
                                )
                            })}
                        </div>
                        <img className="airplane" src={require('../../assets/img/kingdomlivingwebsitecontent/airplane.png')} style={{ objectFit: "cover" }} />
                        {slides.map((value, index, array) => {
                            return (
                                <WarpText props={{ position: "Bottom", bubble, index, value }} />
                            )
                        })}
                    </div>
                </div>
            </header>
        )
    }
}
class CoolTravel_OurServices extends React.Component {

    constructor() {
        super();
        this.state = {
            bubble: 0,
            pageJustOpened: true,
            showInfo: false,
            slides: [
                {
                    title: "Customized Travel Tours",
                    info: "Expert guidance in selecting destinations, arranging accommodations, transportation, and activities."
                },
                {
                    title: "Investment Tours",
                    info: "Visits to key business hubs, industry-specific events, and meetings with local entrepreneurs and government"
                },
                {
                    title: "Market Research and Analysis",
                    info: "Expert insights and guidance on investment strategies and market entry."
                },
                {
                    title: "Due Diligence Support",
                    info: "Support in navigating local regulations, permits, and licensing requirements."
                },
            ],
            services: [
                {
                    index: 1,
                    title: "Customized Travel Tours:",
                    service: [
                        "Tailor-made itineraries that cater to the unique preferences and interests of travelers.",
                        "Expert guidance in selecting destinations, arranging accommodations, transportation, and activities.",
                        "Access to local guides who provide cultural insights and ensure a memorable travel experience."
                    ]
                },
                {
                    index: 2,
                    title: "Investment Tours:",
                    service: [
                        "Exclusive tours designed for investors seeking firsthand knowledge of Africa's investment landscape.",
                        "Visits to key business hubs, industry-specific events, and meetings with local entrepreneurs and government officials.",
                        "Insightful presentations and analysis of investment opportunities across various sectors."
                    ]
                },
                {
                    index: 3,
                    title: "Market Research and Analysis:",
                    service: [
                        "In-depth research reports on tourism and investment trends in Africa.",
                        "Market analysis to identify potential investment opportunities and emerging tourism markets.",
                        "Expert insights and guidance on investment strategies and market entry."
                    ]
                },
                {
                    index: 4,
                    title: "Due Diligence Support:",
                    service: [
                        "Assistance with conducting due diligence on investment opportunities, including regulatory compliance and risk assessment.",
                        "Access to local legal, financial, and advisory services to ensure informed investment decisions.",
                        "Support in navigating local regulations, permits, and licensing requirements."
                    ]
                },
                {
                    index: 5,
                    title: "Networking and Partnership Facilitation:",
                    service: [
                        "Networking events and forums connecting investors with local businesses, industry experts, and potential partners.",
                        "Facilitation of strategic partnerships between investors and tourism operators, hoteliers, and other stakeholders.",
                        "Platform for knowledge exchange, collaboration, and fostering business relationships."
                    ]
                },
                {
                    index: 6,
                    title: "Sustainability and Impact:",
                    service: [
                        "Emphasis on sustainable tourism practices and investment in projects that promote environmental conservation and community development.",
                        "Guidance on socially responsible investment options and impact measurement.",
                        "Support for projects that contribute to the UN Sustainable Development Goals."
                    ]
                },
            ]
        }
    }

    componentDidMount() {
        this.animObjects()
        // this.animObjects_bias()
    }

    animObjects = () => {
        const { bubble, pageJustOpened } = this.state
        const newbubble = bubble + 1
        const timeout = pageJustOpened ? 0 : 10000;
        if (3 >= bubble) {
            setTimeout(() => {
                this.setState({ showInfo: false })
                setTimeout(() => {
                    this.setState({ bubble: newbubble, pageJustOpened: false })
                    setTimeout(() => {
                        this.setState({ showInfo: true })
                        this.animObjects();
                    }, 1600);
                }, 600);
            }, timeout);
        } else {
            setTimeout(() => {
                this.setState({ showInfo: false })
                setTimeout(() => {
                    this.setState({ bubble: 1 })
                    setTimeout(() => {
                        this.setState({ showInfo: true })
                        this.animObjects()
                    }, 1600);
                }, 600);
            }, timeout);
        }
    }

    animObjects_bias = () => {
        this.setState({ showInfo: false, bubble: 4, pageJustOpened: false, showInfo: true })
    }

    Service = (val, index) => {
        return (
            <div className={'HOS_serviceCard scaleOnHover HOS_serviceCard' + index}>
                <div className='bulletIndicator'>
                    {/* <div className='bulletIcon'></div> */}
                    {/* <div className='bulletDash'></div> */}
                </div>
                <div className='bulletCard'>
                    <div className={'bulletCardImg bulletCardImg' + index}></div>
                    <h1>{val.title}</h1>
                    <ul>
                        {val.service.map((val, i) => {
                            return (
                                <li>{val}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        )
    }

    render() {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const { bubble, showInfo, slides, services } = this.state
        return (
            <div className="CoolTravel_OurServices">
                <div className="coolTravelHeader">
                    <div className="CTH_col CTH_col1">
                        <img className="CTH_col_img" src={require('../../assets/img/kingdomlivingwebsitecontent/airportSample2.jpg')} style={{ objectFit: "cover" }} />
                        <div className="darkOverlay"></div>
                        <img className="africa_" src={require('../../assets/img/kingdomlivingwebsitecontent/africa.png')} style={{ objectFit: "cover" }} />
                        <div className="circleButton scaleOnHover"></div>
                    </div>
                    <div className="CTH_col CTH_col2">
                        <div className="warp1">
                            {slides.map((value, index, array) => {
                                return (
                                    <Warp props={{ position: "Top", bubble, index }} />
                                )
                            })}
                        </div>
                        <div className="warp2">
                            {slides.map((value, index, array) => {
                                return (
                                    <Warp props={{ position: "Bottom", bubble, index }} />
                                )
                            })}
                        </div>
                        <img className="airplane" src={require('../../assets/img/kingdomlivingwebsitecontent/airplane.png')} style={{ objectFit: "cover" }} />
                        {slides.map((value, index, array) => {
                            return (
                                <WarpText props={{ position: "Bottom", bubble, index, value }} />
                            )
                        })}
                    </div>
                    <div className="coolTravelHeader_linearGradien"></div>
                </div>
                <div className="CT_services">
                    <div className="CT_services_intro">
                        <h1 className='HOServices_h1'>Our Services</h1>
                    </div>
                    <div className="HOServices">
                        <div className='row'>
                            <div className='col-md-6'>
                                {services.slice(0, 3).map((val) => {
                                    return (
                                        this.Service(val, val.index)
                                    )
                                })}
                            </div>
                            <div className='col-md-6'>
                                {services.slice(3, 6).map((val, index) => {
                                    return (
                                        this.Service(val, val.index)
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class Warp extends React.Component {
    render() {
        const { position, bubble, } = this.props.props
        const index = this.props.props.index + 1
        return (
            <div class={"Warp" + position + " WarpImg" + index + " WarpImg"} style={{ opacity: bubble === index ? 0.1 : 0, }}>
            </div>
        )
    }
}
class WarpText extends React.Component {
    render() {
        const { position, bubble, value } = this.props.props
        const index = this.props.props.index + 1
        return (
            // <div class={"Warp" + position + " WarpImg" + index + " WarpImg"} style={{ opacity: bubble === index ? 0.1 : 0, }}>
            // </div>
            <div className="WarpImgText" style={{ opacity: bubble === index ? 1 : 0, }}>
                <p className="WarpImgTitle">{value.title}</p>
                <p className="WarpImgInfo">{value.info}</p>
            </div>
        )
    }
}





export { Home_intro, HomeHiglight, CoolTravel_OurServices };
export default HeaderAnimComp;