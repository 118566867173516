const presets = {
    darkMode: false,
}

export const OFFLINE_DB = [
    {
        preset: "default",
        subTitle: "Social Injustice",
        Title: "EndSars",
        coverImage: require('../assets/img/blog/6-1430x715.jpg'),
        Data: [
            {
                elementType: "text",
                value: "In June of this year an article was published entitled, “As the world marches for American victims, police brutality in Africa goes unnoticed”. That was the first time I realized that black americans were not the only people suffering with injustice. The black race as a whole has encountered injustice for over 2000 years. In 1994 my uncle was a victum of police brutality. He was shot and killed in front of my childhood home by the police. The police were never held accountable for his brutal killing. "
            },
            {
                elementType: "text",
                value: "In Nigeria, innocent young bodies are laying dead in the streets by senseless police brutality. Nerigian police officers justify their killings sighting self-defense after being provoked and attacked by the young men when they try to disperse them. Innocent on lookers protest stating these killings are not warrented because there is no provacation by the innocent that lie dead in the streets. "
            },
            {
                elementType: "text",
                value: "As protests mount to an all time precident high in Africa, the world joins in matching the same momentum that was used to speak out against the police brutality that killed the American George Floyd. George Floyd’s murder was captured on video and streamed live by onlookers. There was no reasonable explaniation for his death. Protests has since sparked normous changes to the law around America. What will the protests in Nigeria produce? Prior until two weeks ago, whenever anyone was killed by the police in Nigeria, fancy hashtag activism for justice trends for some days. We have entered a new era. Protesters from near and far have joined together to raise their voices in the streets of Nigeria and around the world. Thousands march and chant “Enough is Enough” against police brutality and violence. "
            },
            {
                elementType: "file-image",
                value: require('../assets/img/blog/Social_Injustice_Blog2.png')
            },
            {
                elementType: "text",
                value: "Nigerians are campaigning for police reform and an end to bad goverance in Africa’s most populous country. They are calling for everyone to speak up and to speak out. ENDSAR!!!! Enough is Enough "
            },
            // {
            //     elementType: "text",
            //     value: ""
            // },
        ],
        metaData: {
            date: new Date().toDateString(),
            by: "admin",
            Tags: [
                {name: "Social Injustice"},
                {name: "EndSars"},
            ]
        },
    },
    {
        preset: "manga",
        subTitle: "Theological History",
        Title: "Black is Royalty",
        coverImage: require('../assets/img/blog/Picture1.png'),
        Data: [
            {
                elementType: "text",
                value: "I knew that there was a reason why I despised going to seminary school. In 2012 I decided that I want to pursue a master’s degree in Theology. After taking several courses, I would eventually flunk out. Yes, I did, I failed epically. I failed because deep down in my spirit I knew what they were teach was not accurate. There as always been something down in my spirit about the Bible that I knew needed to be uncovered. There was a deeper revelation that I desired. I love to read the Bible. Faith, perseverance, love, pathways, thrill, disappointments, and restoration, possession, and healing all are housed in the pages of the Bible. Very visible in the pages of the Bible is black history. However, black history is untold in the Western part of the world. Why? For over 400 years white Christianity have been used to suppress and oppression the black race. If we are open to really going beyond the suppression and oppression in the Bible our eyes will be opened to the strength and power of black people. We find Africa as pillar of the world. Everything began with Africa. Eden discovered to be in Botswana. Our ancestors are traced all the way back to Ancient Egypt. The deeper we dig the more that we will find about our royal lineage.  "
            },
            {
                elementType: "file-image",
                value: require('../assets/img/blog/Picture2.png')
            },
            {
                elementType: "text",
                value: "A friend of mine told me about a book entitled, “The Africans Who Wrote the Bible” by Nana Banchie Darkwah. This book is an eye opener. He discovered that Akan was at the core of the Bible. Akan is defined as a member of a people inhabiting southern Ghana and adjacent parts of Cote d’lvoire (Ivory Coast). I found that to be very interesting. Akan found in West Africa according to the dictionary. But Dr. Darkwah takes things further by stating a lot of the names in the Bible are derived from Akan which is traced back to Ancient Egypt. We should study the names of Ancient Egyptian kings, people, and places. When we do, we find that Akan names in the Egyptian story reveal Ewe, Ibo, Yoruba, Ga-Adangme, and Hausa names, especially in the Ancient Egyptians Kings.  "
            },
            {
                elementType: "text",
                value: "The Akan discovery is one of many revelations. We are just beginning to dig deep. The Bible provides us with general context, but yet it leads us into the deeper pastures that reveals to each of us our historical past. Dating African is about finding out the mysteries of our past to bring awareness to those in the diaspora that may be unaware of our rich historical roots. We are Royalty!!! "
            },
            // {
            //     elementType: "text",
            //     value: ""
            // },
        ],
        metaData: {
            date: new Date().toDateString(),
            by: "admin",
            Tags: [
                {name: "Theological History"},
                {name: "Black is Royalty"},
            ]
        },
    },
    {
        preset: "default",
        subTitle: "Travel",
        Title: "Experience Lesotho",
        coverImage: require('../assets/img/blog/Experience_Lesotho.png'),
        Data: [
            {
                elementType: "text",
                value: "In 2010, I experience a great awaking. An awaking that I never expected. I traveled to Johannesburg, South Africa where I journeyed into the Kingdom of Lesotho for my very first medical mission. When I arrived, I was not prepared for the beauty that I had experience. I was captivated by the architecture, the vibrant colors, the green pastures, the food, and the people. Lesotho, is a high-altitude, landlocked kingdom encircled by South Africa, is crisscrossed by a network of rivers and mountain ranges including the 3,482m-high peak of Thabana Ntlenyana. "
            },
            {
                elementType: "text",
                value: "Lesotho has endured decades of turbulent politics, periodic economic crises, and grinding poverty since gaining its independence from Great Britain in 1966. In the 1990s, the country welcomed modernization programs which have brought new wealth to the country but at the cost of much environmental damage. Tourism and revenues are an important factor in many of African countries. I made it one of my life’s missions to promote the Africa and its brand. Much of television and social trafficking reports Africa as a downtrodden continent, but I beg to differ. The negative reporting is a distraction to detour many of diasporans from returning to their birthrights. My trip in 2010 changed me. Not for the bad but for the greater good. "
            },
            {
                elementType: "file-image",
                value: require('../assets/img/blog/Experience_Lesotho2.png')
            },
            {
                elementType: "text",
                value: "I began to study Africa and its history for 9 years until I would return again in 2019. I dreamt of the life I would have somewhere on the African Continent. I was captivated and there was nothing that I could ever do that would change my passion and love affair Africa.   "
            },
            // {
            //     elementType: "text",
            //     value: ""
            // },
        ],
        metaData: {
            date: new Date().toDateString(),
            by: "admin",
            Tags: [
                {name: "Travel"},
                {name: "Experience Lesotho"},
            ]
        },
    },
]

export default presets;