import React, { Component } from 'react';
import '../../App.css';
import FadingGradient from '../components/FadingGradient';
import Heading from '../components/Heading';
import ImageComp from '../components/ImageComp';
import LatestNews from '../components/LatestNews';



class ThPtwyTGnaScreen extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            headings: []
        }
    }

    render() {
        const { headings } = this.state
        const FirstHeading = headings.slice(0, 2);
        const secondHeading = headings.slice(2, 3);
        return (
            <>
                {/* <ImageComp imgUrl={require('../../assets/img/about.png')}/> */}
                <FadingGradient color1="rgb(219,53,57)" color2="rgb(207,207,207)" />
                <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)" />
                {headings.map((value, index, array) => {
                    return (
                        <Heading value={value} />
                    )
                })}
                {/* <div class={'App__'}></div> */}
            </>
        );
    }

    componentDidMount(){
        const { email, first_name, last_name} = this.props.ParentProps.userData.offlinePreset
        const Fname = first_name ? first_name : ""; const Lname = last_name ? " " + last_name : "";
        const name = Fname + Lname
        const email_ = email ? email : ""
        const headings = [
            {
                title: "Ghana: Administrative Issues",
                titleStyle: { fontSize: "2.9rem", paddingLeft: window.innerWidth > 991 ? "10%" : "5%", marginTop: 30, textAlign: "left", width: "100%" },
                text: [
                    {
                        t: "Entering the Country", type: "dropDown", url: "", height: window.innerWidth > 991 ? 500 : window.innerWidth > 675 ? 600 : window.innerWidth > 575 ? 800 : 800,
                        data: [
                            {
                                t: "Nationals of a number of African countries do not need to apply for a visa in their country of residence before they travel to Ghana. Instead, they can acquire an entry visa stamp on arrival at the international airport in Accra as well as a number of seaports. Eligible nations are Malawi, Zambia, Lesotho, Botswana, Zimbabwe, Tanzania, Uganda, and Swaziland. Countries whose nationals are exempt from acquiring a visa altogether are the ECOWAS states as well as Kenya, Malaysia, Singapore, Egypt, and Hong Kong.",
                                type: "text"
                            },
                            {
                                t: "Everyone else is required to apply for an entry visa at the Ghanaian representation in their home country. A full list of Ghanaian embassies, high commissions, and consulates abroad can be found at the website of the Ministry of Tourism (Use this address for the hyper link: http://motac.gov.gh/).",
                                type: "text"
                            },
                            {
                                t: "The requirements for entry visa include:",
                                type: "text"
                            },
                            {
                                t: "",
                                type: "list",
                                data: [
                                    { t: "your passport, valid for at least six months at the time of application", type: "listChild" },
                                    { t: "two completed and signed application forms", type: "listChild" },
                                    { t: "two passport-sized photos", type: "listChild" },
                                    { t: "financial means to be indicated", type: "listChild" },
                                    { t: "names and addresses of references. If you are going to be staying in a hotel when first visiting Ghana, they will help you with this issue.", type: "listChild" },
                                ],
                                style: { paddingLeft: "6%" }
                            },
                            {
                                t: "You have the choice between single and multiple entry visas, which are valid for three and twelve months after acquisition, respectively. However, the time of validity is not the actual permitted duration of your stay in Ghana. The immigration officials at your point of entry decide how long you may stay in the country, the usual duration being 60 days.",
                                type: "text"
                            },
                        ],
                    },
                    {
                        t: "Relocating to and Working in Ghana ", type: "dropDown", url: "", height: window.innerWidth > 991 ? 270 : window.innerWidth > 675 ? 370 : window.innerWidth > 575 ? 450 : 450,
                        data: [
                            {
                                t: "The steps to be undertaken in order to legally work and reside in Ghana are, unsurprisingly, far more time-consuming and elaborate. The recent change in work permit legislation did not help to alleviate this. While in the past, expats were able to enter the country while their work permit was still pending, they now have to wait until all documents are cleared.",
                                type: "text"
                            },
                            {
                                t: "We have shortly hinted at the immigration policy of the country in our article on working in Ghana. Foreigners are only allowed to take up employment in Ghana within an authorized immigrant quota which regulates the number of non-Ghanaians which may be employed by an enterprise. It is not unheard of that a company may only hire one single foreigner, making job opportunities for expats somewhat scarcer than in other African countries.",
                                type: "text"
                            },
                        ]
                    },
                    {
                        t: "Getting Hold of a Work Permit", type: "dropDown", url: "", height:  window.innerWidth > 991 ? 380 : window.innerWidth > 675 ? 480 : window.innerWidth > 575 ? 580 : 600,
                        data: [
                            {
                                t: "If you were able to successfully land a job, you have to apply for both a work permit and a residence permit, in this order. Applications for work permits need to be directed to the Ghana Immigration Service (GIS); your future employer should be able to supply you with the necessary forms.",
                                type: "text"
                            },
                            {
                                t: "Along with your completed and signed application, you need to supply:",
                                type: "text"
                            },
                            {
                                t: "",
                                type: "list",
                                data: [
                                    { t: "a completed work permit application form", type: "listChild" },
                                    { t: "your CV", type: "listChild" },
                                    { t: "any educational and professional certificates", type: "listChild" },
                                    { t: "your work contract", type: "listChild" },
                                    { t: "a police report from your country of residence", type: "listChild" },
                                    { t: "a medical report", type: "listChild" },
                                    { t: "copies of the relevant pages in your passport", type: "listChild" },
                                ],
                                style: { paddingLeft: "6%" }
                            },
                            {
                                t: "As the application for the work permit is to be handed in in person at the GIS headquarters in Ghana, you will have to submit all necessary documents to your employer who will then take the necessary steps. The usual processing time is four weeks.",
                                type: "text"
                            },
                        ],
                    },
                    {
                        t: " How to Get a Residence Permit", type: "dropDown", url: "", height: window.innerWidth > 991 ? 500 : window.innerWidth > 675 ? 600 : window.innerWidth > 575 ? 650 : 650,
                        data: [
                            {
                                t: "The residence permit also requires teamwork: both you and your employer need to supply different documents in order to successfully acquire the permit. You will realize that the GIS should already have many of those from your work permit application; however, you will still need to hand them in all over again.",
                                type: "text"
                            },
                            {
                                t: "Requirements for the residence permit on the employee’s side:",
                                type: "text"
                            },
                            {
                                t: "",
                                type: "list",
                                data: [
                                    { t: "your CV", type: "listChild" },
                                    { t: "your medical report", type: "listChild" },
                                    { t: "your police report", type: "listChild" },
                                    { t: "your passport with a validity period of at least three months", type: "listChild" },
                                    { t: "two passport-size photographs", type: "listChild" },
                                    { t: "proof of travel insurance", type: "listChild" },
                                    { t: "the completed application form", type: "listChild" },
                                    { t: "your employment contract", type: "listChild" },
                                ],
                                style: { paddingLeft: "6%" }
                            },
                            {
                                t: "Requirements on the employer’s side:",
                                type: "text"
                            },
                            {
                                t: "",
                                type: "list",
                                data: [
                                    { t: "letter of application for the residence permit", type: "listChild" },
                                    { t: "Certificate to Commence Business", type: "listChild" },
                                    { t: "Certificate of Incorporation", type: "listChild" },
                                    { t: "company’s Code", type: "listChild" },
                                    { t: "tax clearance certificate", type: "listChild" },
                                    { t: "approval letter from Ministry of Interior", type: "listChild" },
                                ],
                                style: { paddingLeft: "6%" }
                            },
                        ],
                    },
                ],
                linkText: "",
                path: "",
                style: "multipleDropDown",
                externalUrlName: "",
                externalUrl: "",
                noHR: true,
                HR2: true,
                hrStyle: { width: "80%" },
                externalUrlIcon: "fa fa-plus-square-o"
            },
            {
                title: "We would love to be the bridge that you use to make the crossover into Ghana. Contact us for additional information about relocation.",
                titleStyle: { fontSize: "2.4rem", lineHeight: 1.6, width: "100%", paddingLeft: window.innerWidth > 991 ? "8%" : "2%", paddingRight: window.innerWidth > 991 ? "8%" : "2%", textAlign: window.innerWidth > 1021 ? "left" : "center", },
                text: [
                    { t: "Name", value: name, type: "input", url: "", style: { width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25,} },
                    { t: "Email", value: email_, type: "input", url: "", style: { width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25, } },
                    { t: "Subject", type: "input", url: "", style: { width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25, } },
                    { t: "Comment or Message", type: "textInput", url: "", style: { width: "100%", height: 200, backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25, } },
                ],
                linkText: "",
                path: "",
                style: "dynamicForm",
                externalUrlName: "",
                externalUrl: "",
            }
        ]
        this.setState({headings})
    }
}

export default ThPtwyTGnaScreen;
