import React from "react";

export default class ImageComp extends React.Component {

    constructor() {
        super();
        this.state = {
            bubble: 0,
            pageJustOpened: true,
            showInfo: false,
        }
    }

    componentDidMount() {
        // this.animObjects()
    }


    render() {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const { bubble, showInfo, slides } = this.state;
        const {imgUrl} = this.props
        return (
            <header class="HeaderAnimComp">
                <img src={imgUrl} style={{width: "100%"}}/>
            </header>
        )
    }
}

