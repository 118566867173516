import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class TripPackages_ZANZIBAR extends Component {
    constructor() {
        super();
        this.state = {
            // activeSlide: 1
        }
    }

    render() {
        const windowsInnerWidth = window.innerWidth
        const windowsInnerHeight = window.innerHeight
        return (
            <div style={{ width: "100%", overflowX: "hidden", position: "relative" }}>
                <div className='ZANZIBAR_section'>
                    <div className='ZANZIBAR_'></div>
                </div>

                <div className='ZANZIBAR_cont'>
                    <div className='ZANZIBAR_cont_'>
                        <div className='Z_bg1'></div>
                        <div className='Z_bg2'></div>
                        <div className='Z_bg3'></div>
                        <div className='ZANZIBAR_sectionFoot'>
                            <h3>3 May 2024 - 12 May 2024</h3>
                        </div>

                        <div className='Z_overview'>
                            <div className='Z_O row'>
                                <div className='col-md-5'>
                                    <div className='Z_O_row_img'></div>
                                </div>
                                <div className='col-md-5 Z_O_col5'>
                                    <h5>ACCOMMODATION AND DESTINATIONS</h5>
                                    <h3>MELIA ZANZIBAR</h3>
                                    <p>Kiwengwa</p>
                                    <p>10 Days / 9 Nights</p>
                                    <p><span style={{ fontWeight: "bold" }}>Reference Number:</span> CARDMC-KingdomLiving -05024</p>
                                    <p>10 guests</p>
                                    <p style={{ marginBottom: 15 }}> All Inclusive - Bed and All Meals</p>
                                </div>
                                <div className='col-md-2 Z_O_col2'>
                                    <div className='Z_O_col2_bubble Z_O_col2_bubble1 scaleOnHover'><i class="fa fa-star animated"></i> </div>
                                    <div className='Z_O_col2_bubble Z_O_col2_bubble2 scaleOnHover'><i class="fa fa-shield animated"></i></div>
                                    <div className='Z_O_col2_bubble Z_O_col2_bubble3 scaleOnHover'><i class="fa fa-heart animated"></i> </div>
                                    <div className='Z_O_col2_bubble Z_O_col2_bubble4 scaleOnHover'><i class="fas fa-check z_fas"></i> </div>
                                    {/* <div className='Z_O_col2_bubble Z_O_col2_bubble5 scaleOnHover'><i class="fas fa-check z_fas"></i> </div> */}
                                </div>
                                {/* <div className='col-md-3'></div> */}
                            </div>
                            <div className='Z_overview_higlight'>
                                <h1>Overview</h1>
                            </div>
                        </div>
                        <div className='Z_overview_sectionFoot'>
                            {/* <h3>3 May 2024 - 12 May 2024</h3> */}
                        </div>
                    </div>
                    <div className='Z_overview_sectionFoot_'></div>
                </div>


                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 0 }}>
                    <h1 style={{ fontFamily: "cursive", fontWeight: "bold", textAlign: "center" }}>FULL PACKAGE INCLUDES:</h1>
                    <div style={{ width: "40%", height: 2, backgroundColor: "rgba(58, 8, 7, 0.4)", borderRadius: 20, marginBottom: 0, marginTop: 0 }}></div>

                    <div class="row" style={{ paddingLeft: windowsInnerWidth > 750 ? "10vw" : "0vw", width: "100%" }}>
                        <div class='col-md-6' style={{ padding: windowsInnerWidth > 750 ? "0vw" : "10vw", paddingTop: windowsInnerWidth > 750 ? "3vw" : "10vw", paddingBottom: windowsInnerWidth > 750 ? "0vw" : "0vw", }}>
                            <ul style={{ fontFamily: "Gill Sans", color: "rgba(0,0,0,0.8)" }}>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Experienced and knowleadgeable English-Speaking Driver/Guide</li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Three (3) meals (Breakfast, lunch + dinner) for the entire stay
                                    {/* <br /> */}
                                    {/* <span style={{ paddingLeft: 30, fontSize: "smaller" }}>Radisson Blu Hotel Waterfront, Cape Town</span> <br /> */}
                                    {/* <span style={{ paddingLeft: 30, fontSize: "smaller" }}>2 Nights at Ridge Royal Hotel Cape Coast</span> */}
                                </li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Pick-up and drop-off Zanzibar International Airport</li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Room Categorty at Melia Zanzibar: <span style={{ fontWeight: "bold" }}>Garden view / sharing accommodation</span></li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Unlimited bottled water during transfers</li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Tourism Development Levy and VAT 18%</li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> All accommodation under the Program</li>
                                {/* <li class="Zpackage_list"><i class="fas fa-check"></i> The Door of No Return</li> */}
                            </ul>
                        </div>
                        <div class='col-md-6' style={{ padding: windowsInnerWidth > 750 ? "0vw" : "10vw", paddingTop: windowsInnerWidth > 750 ? "3vw" : "0vw", paddingBottom: windowsInnerWidth > 750 ? "0vw" : "0vw" }}>
                            <ul style={{ fontFamily: "Gill Sans", marginTop: 0, paddingTop: 0, color: "rgba(0,0,0,0.8)" }}>
                                <li style={{ marginTop: 0, paddingTop: 0 }} class="Zpackage_list"> <i class="fas fa-check z_fas"></i> Inclusive Package (refer to brochure attached under documents - All Inclusive Package)</li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Medical evacuation</li>
                                <li class="Zpackage_list"><i class="fas fa-check z_fas"></i> Two ways transfers</li>
                                {/* <li class="Zpackage_list"><i class="fas fa-check"></i> Contemporary Art and Design Tour</li>
                                <li class="Zpackage_list"><i class="fas fa-check"></i> Walking Tour through Elmina township</li>
                                <li class="Zpackage_list"><i class="fas fa-check"></i> Farewell Dinner</li>
                                <li class="Zpackage_list"><i class="fas fa-check"></i> All Entrance Fees</li>
                                <li class="Zpackage_list"><i class="fas fa-check"></i> All Ground Transportation</li> */}
                            </ul>
                            <div style={{ width: "20%", height: 2, backgroundColor: "rgba(58, 8, 7, 0.4)", borderRadius: 20, marginBottom: 0, marginTop: 20 }}></div>
                        </div>
                    </div>

                    <Link to="/CheckOut_Page" state={{ actn: "ZA", code: "GWHFZA8765" }} class="Z_link tp_a" style={{ margin: 10 }}>
                        Book Trip Now
                    </Link>
                </div>

                <div className='YTD_top'></div>
                <div className='YourTrip_Destination ZabsoluteImage'>
                    <div className='YTD_Heading_cont'>
                        <h1>YOUR TRIP - DESTINATION:</h1>
                    </div>
                    <div className='YTD_Body'>
                        <h3>TANZANIA</h3>
                        <div className='row'>
                            <div className='col-md-6'>
                                <p>
                                    The name Tanzania conjures up images of wildebeest stampeding across vast savannah,
                                    rain forests teeming with monkeys and birdlife, and great plains brimming with
                                    legions of game. All of these natural wonders and more are on offer in this
                                    exceptionally diverse African nation. Visitors typically visit Tanzania to partake
                                    in at least one of the four well known Tanzanian tourist experiences: a relaxing
                                    seaside vacation on the picturesque island paradise of Zanzibar, an underwater tour
                                    of some of the world’s most renowned dive sites around the gorgeous Spice Islands, a
                                    safari adventure in some of Africa’s most impressive game reserves, or a hiking excursion
                                    around Mount Kilimanjaro National Park.
                                </p>
                            </div>
                            <div className='col-md-6'>
                                <p>
                                    Whichever of these incredible holidays you choose, you will undoubtedly be welcomed by some
                                    fabulously friendly and peaceful inhabitants who, despite being divided into 120 different
                                    ethnic groups and cultures, live in harmony with one another and provide some of the most
                                    wonderfully exotic local cuisine you could imagine. With all of this diversity on offer,
                                    the most difficult part of your Tanzanian holiday experience is likely to be deciding where to go!
                                </p>
                            </div>
                        </div>

                        <h3 style={{ paddingTop: 40 }}>KIWENGWA, 3 MAY 2024 - 12 MAY 2024</h3>
                        <div className='row'>
                            <div className='col-md-6'>
                                <p>
                                    Situated on the spectacular northeastern coast of Tanzania’s Unguja island, between the quaint
                                    coastal villages of Pwani Mchangani and Pongwe, Kiwengwa is best known for its scenic beach of
                                    glittering white sands, lapped by azure crystalline waters. Visitors can explore the local village,
                                    and enjoy a host of water-based activities including snorkelling, diving, kitesurfing, and boating.
                                </p>
                            </div>
                            <div className='col-md-6'>
                                <p>
                                    Sample the local flavours of Zanzibar’s unique cuisine, sip on a delicious cocktail at a beachfront
                                    bar, and discover the fascinating underwater wonderland that lies offshore. Visitors should be able
                                    to spot turtles, starfish, octopuses and seahorses quite easily. Animal lovers will also delight in
                                    the chance to visit the nearby Cheetah Rock Conservation Centre, which protects lions, zebras,
                                    and many other rescued animals.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='YTD_bottom'></div>

                <div class="acm_heading" style={{ marginTop: 0, backgroundImage: "linear-gradient(to left, rgb(255, 204, 0), #076469ac, rgb(96, 32, 0), #076469ac)" }}>
                    <div className='absolute_YTD'></div>
                    <h1 style={{ textAlign: "center", fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "uppercase", color: "white", fontSize: "xx-large", position: "relative" }}>Hotel Accommodations</h1>
                    <div className='absolute_YTD__'></div>
                </div>

                {/* <div class='CP_absolute_image_holder CPabsoluteImage'>
                    <div className='CP_absolute_image_holder_'>
                        <div style={{ width: "100%", display: "flex", padding: 15, justifyContent: "space-evenly", paddingLeft: windowsInnerWidth > 750 ? "3vw" : 10 }}>
                            <img className='scaleOnHover boxShadowClass' style={{ height: "50vh", width: "23%", objectFit: "cover" }} src={require('../../assets/img/kingdomlivingwebsitecontent/16256-113963-f70781386_3xl.jpg')} />
                            <img className='scaleOnHover boxShadowClass' style={{ height: "50vh", width: "23%", objectFit: "cover" }} src={require('../../assets/img/kingdomlivingwebsitecontent/16256-113963-f79129092_3xl.jpg')} />
                            <img className='scaleOnHover boxShadowClass' style={{ height: "50vh", width: "23%", objectFit: "cover" }} src={require('../../assets/img/kingdomlivingwebsitecontent/16256-113963-f68346945_3xl.jpg')} />
                            <img className='scaleOnHover boxShadowClass' style={{ height: "50vh", width: "23%", objectFit: "cover" }} src={require('../../assets/img/kingdomlivingwebsitecontent/16256-113963-f62721058_3xl.jpg')} />
                        </div>
                    </div>
                </div> */}

                <div class="acm_body row">
                    <h1 style={{ textAlign: "center", fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "capitalise", marginTop: 0, position: "relative", zIndex: 1 }}>Melia Zanzibar Hotel - Kiwengwa, Tanzania</h1>
                    <div style={{ width: "20%", height: 2, backgroundColor: "rgba(179, 19, 16, 0.4)", borderRadius: 20, marginBottom: 40, marginTop: 10 }}></div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="acm_card">
                                <img src={require('../../assets/img/kingdomlivingwebsitecontent/zanzibarMelia-ZA-4.jpg')} style={{ objectFit: "cover" }} />
                                <div class="acm_card_div"></div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="acm_card">
                                <img src={require('../../assets/img/kingdomlivingwebsitecontent/zanzibar01ZZMK-IM0701-melia-zanzibar-1475.jpg')} style={{ objectFit: "cover" }} />
                                <div class="acm_card_div"></div>
                            </div>
                            <div style={{ width: window.innerWidth, height: 1, backgroundColor: "rgba(0,0,0,0)" }}></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="acm_card">
                                <img src={require('../../assets/img/kingdomlivingwebsitecontent/zanzibarMelia-Zanzibar-Hotel-Kiwengwa-Exterior-2.JPEG')} />
                                <div class="acm_card_div"></div>
                            </div>
                            <div style={{ width: window.innerWidth * 0.1, height: 1, backgroundColor: "rgba(0,0,0,0)" }}></div>
                        </div>
                        <div class="col-md-6">
                            <div class="acm_textcard" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", height: "100%", paddingLeft: "1.5vw" }}>
                                <p style={{ color: "rgba(0,0,0,0.8)", fontSize: 18, lineHeight: 1.7, marginBottom: 20 }}>
                                    Built on a 40-acre estate with a beautiful beach of pearlescent white sand lapped by warm ocean waters, Meliá
                                    Zanzibar is an all-inclusive luxury resort on the northeast coast of the exotic island of Zanzibar, also known as the Spice Island.
                                </p>
                                <p style={{ color: "rgba(0,0,0,0.8)", fontSize: 18, lineHeight: 1.7, marginBottom: 20 }}>
                                    Accommodation consists of spacious guest rooms and suites, each equipped with air conditioning, a television, a minibar
                                    and a private terrace or balcony. Guests can enjoy dining at any one of the onsite restaurants or unwind with a
                                    refreshing drink at the bar. Additional amenities include meeting facilities and a spa with six private treatment
                                    rooms, a fitness centre, sauna and an infinity lap pool.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="acm_card">
                                <img src={require('../../assets/img/kingdomlivingwebsitecontent/zanzibarmelia-zanzibar-hotel-1.jpg')} style={{ objectFit: "cover" }} />
                                <div class="acm_card_div"></div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="acm_card">
                                <img src={require('../../assets/img/kingdomlivingwebsitecontent/zanzibarbeach-v5882458-1440.jpg')} style={{ objectFit: "cover" }} />
                                <div class="acm_card_div"></div>
                            </div>
                            <div style={{ width: window.innerWidth, height: 1, backgroundColor: "rgba(0,0,0,0)" }}></div>
                        </div>
                    </div>

                    <div style={{ width: "20%", height: 2, backgroundColor: "rgba(179, 19, 16, 0.4)", borderRadius: 20, marginBottom: 40, marginTop: 10 }}></div>
                </div>

                <div class="rtn" style={{ backgroundColor: "grey", backgroundImage: "linear-gradient(to right, rgb(255, 204, 0), #076469ac, rgb(96, 32, 0), #076469ac)", position: "relative" }}>
                    <div className='absolute_YTD'></div>
                    <div class="rtn_container" style={{ textAlign: "center", position: "relative" }}>
                        <h4>Reserve Your Trip Now</h4>
                        <h1>Book your spot</h1>
                        <div class="pck_container row">
                            <div class="pck_ ">
                                <h3>To accommodate everyone's preferences, we have both double and single occupancy options available:</h3>
                                <div class="cool_hr" style={{ marginTop: 10, marginBottom: 20, backgroundColor: "rgb(58, 8, 7)" }}></div>
                                <p>Single: $3869.20 per person, $500.00 deposit </p>
                                <p>Double: $2895.20 per person, $500.00 deposit </p>
                                <Link className='Z_link tp_a' to="/CheckOut_Page" state={{ actn: "ZA", code: "GWHFZA8765" }}>BOOK NOW</Link>
                            </div>
                            {/* <div class="pck_ col-md-5">
                                <h3>Land Only-No Flight From Atlanta</h3>
                                <div class="cool_hr" style={{ marginTop: 10, marginBottom: 20, backgroundColor: "rgb(58, 8, 7)" }}></div>
                                <p>Single: $4239.00 per person</p>
                                <Link className='Z_link tp_a' to="/CheckOut_Page" state={{ actn: "CO", code: "TPCT10315" }}>BOOK NOW</Link>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 70 }}>
                    <h1 style={{ fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "uppercase", fontSize: "xx-large" }}>Additional Information</h1>

                    <div class="row" style={{ padding: "6vw", width: "100%", paddingTop: 0, paddingBottom: 0 }}>
                        <div class='col-md-6' style={{ padding: "2vw", paddingTop: windowsInnerWidth > 750 ? "3vw" : "2vw", paddingBottom: windowsInnerWidth > 750 ? "0vw" : "0vw", }}>
                            <h4 style={{ fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "uppercase" }}>WHAT’S NOT INCLUDED:</h4>
                            <ul style={{ fontFamily: "Gill Sans", color: "rgba(0,0,0,0.8)", fontSize: 16, lineHeight: 1.5 }}>
                                <li class="">All activities and excursion while in Zanzibar</li>
                                <li class="">An increase of taxes and/or park fees</li>
                                <li class="">Tips for the Driver + Hotel Staffs</li>
                                <li class="">All beverages (where not mentioned) as well as premium brands.</li>
                                <li class="">Lunches and Dinners (where not mentioned)</li>
                                <li class="">Gratuities, Tourism Levies & National Park conservation levies (where not specified) tours & transfers.</li>
                                <li class="">International & Domestic flights, Private Charter Flights, Travel and Medical Insurance; Visas</li>
                                <li class="">
                                    All items of a personal nature and not mentioned in the inclusions will be for the client's own account
                                    including bar bills, mini bar fridge, telephone calls, laundry, room service, curio & gift purchases and spa treatments.
                                </li>
                                {/* <li class="">Beverage during meals</li> */}
                                {/* <li class="">Additional excursion offered by tour guide</li> */}
                                {/* <li class="">Mining Indaba Registration</li> */}
                            </ul>

                            <h4 style={{ fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "uppercase" }}>WHAT’S NOT INCLUDED:</h4>
                            <ul style={{ fontFamily: "Gill Sans", color: "rgba(0,0,0,0.8)", fontSize: 16, lineHeight: 1.5 }}>
                                <li class="">
                                    Missing a vacation is bad enough. Losing the money you paid for your vacation is even worse. Trip
                                    insurance is therefore recommended. You can purchase travel insurance on your own.
                                    <ul>
                                        <li class=""><a style={{ color: "#0158CC", fontSize: 16, lineHeight: 1.5 }} href='http://www.dpbolvw.net/click-9018181-10583388?fbclid=IwAR2zRn9hoiE8lohb_iTkLB6iOQZadnG_aA4WFfXu8RzQEh_Xy2aJJ2cgdic'>Travel Guard</a></li>
                                        <li class=""><a style={{ color: "#0158CC", fontSize: 16, lineHeight: 1.5 }} href='https://www.allianztravelinsurance.com/'>Allianz</a></li>
                                        <li class=""><a style={{ color: "#0158CC", fontSize: 16, lineHeight: 1.5 }} href='http://www.kqzyfj.com/click-9018181-13165240?fbclid=IwAR0ntVeNE0DNs2JjhZYni3GE2p-tFZiMviXAPeRewDzRU19LDsxe9YpaDoc'>TravelEx</a></li>
                                    </ul>
                                </li>
                            </ul>

                            <h4 style={{ fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "uppercase" }}>TRAVEL NOTES:</h4>
                            <ul style={{ fontFamily: "Gill Sans", color: "rgba(0,0,0,0.8)", fontSize: 16, lineHeight: 1.5 }}>
                                <li class="">
                                    Traveling abroad requires a passport valid for six months beyond travel dates. Check your
                                    passport to ensure your passport is valid for this tour.
                                </li>
                            </ul>
                        </div>

                        <div class='col-md-6' style={{ padding: "2vw", paddingTop: windowsInnerWidth > 750 ? "3vw" : "0vw", paddingBottom: windowsInnerWidth > 750 ? "0vw" : "0vw" }}>
                            <ul style={{ fontFamily: "Gill Sans", color: "rgba(0,0,0,0.8)", fontSize: 16, lineHeight: 1.5 }}>
                                <li class="">
                                    All prices are based on the rates of exchange in effect at the time of price quotation. In
                                    the event that the US dollar devalues, Kingdom Living Travel & Tours and Dating African
                                    reserves the right to increase prices accordingly. Your final invoice will reflect
                                    increases resulting from currency devaluation.
                                </li>
                                <li class="">
                                    Roommate Matching is offered for this tour. If you want to be matched, please enter
                                    “Roommate Requested” on the reservation form where it asks for your roommate name.
                                    Also, please note roommate matching does not take place until after 75% of the cost
                                    of the package is paid.
                                </li>
                                <li class="">
                                    Full Itinerary will be emailed to you 60 Days in advance to plan your free time.
                                </li>
                            </ul>

                            <h4 style={{ fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "uppercase" }}>AUTOMATIC BILLING PLAN:</h4>
                            <p style={{ fontFamily: "Gill Sans", color: "rgba(0,0,0,0.8)", fontSize: 16, lineHeight: 1.5 }}>
                                You can enroll in our automatic billing and relax knowing that your future payments will be
                                made automatically. When you book, we’ll calculate an evenly divided automatic billing plan
                                for you, and you will know in advance the day of every month that your payment will be processed.
                                Your initial deposit is due at the time of booking, and your future payments will be charged
                                according to the schedule you see on your invoice. Automatic billing is available at no charge.
                            </p>
                        </div>
                    </div>

                    <div style={{ width: "40%", height: 2, backgroundColor: "rgba(179, 19, 16, 0.4)", borderRadius: 20, marginBottom: 0, marginTop: 0 }}></div>

                </div>



                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 70, fontWeight: "600" }}>
                    <h1 style={{ fontFamily: "Gill Sans MT", fontWeight: "bold", textTransform: "uppercase", fontSize: "xx-large" }}>TERMS & POLICIES</h1>

                    <div class="row" style={{ padding: "6vw", width: "100%", paddingTop: 0, paddingBottom: 0 }}>
                        <div class='col-md-6' style={{ padding: "2vw", paddingTop: windowsInnerWidth > 750 ? "3vw" : "2vw", paddingBottom: windowsInnerWidth > 750 ? "0vw" : "0vw", }}>
                            <ul style={{ fontFamily: "Gill Sans", fontSize: 16, lineHeight: 1.5, color: "black" }}>
                                <li class="">
                                    Making reservations for this package signifies your acceptance of our Terms and Conditions.
                                </li>
                                <li class="">
                                    All payments to Kingdom Living Travel & Tours by Dating African, LLC are non-refundable and
                                    non-transferrable.  This is because Dating African d/b/a Kingdom Living Travel & Tours has
                                    contractual agreements with hotels, airlines and other vendors that will not allow us to
                                    obtain any refunds. This way we can keep our package prices low and allow you to make monthly
                                    payments on your vacation.
                                </li>
                                <li class="">
                                    Passengers are responsible for ensuring that they have the proper travel documents and MUST
                                    CHECK with the respective consulate(s) or visa agency to determine whether any visas or
                                    passports are required. Passports are required to be valid for at least 6 months after
                                    the date of travel. Some countries require a full blank “VISA” page in the passport for
                                    stamping purposes. Any information provided on travel description pertains to US citizens
                                    only. Non-US citizens should check with the respective consulate of the country(s) to be
                                    visited for current entry requirements.  Reservations must be made in your FULL NAME as
                                    it appears on your passport.
                                </li>
                                <li class="">
                                    Missing a vacation is bad enough. Losing the money you paid for your vacation is even worse;
                                    therefore, we recommend Travel Protection that helps provide coverage for Trip Cancellation,
                                    Interruption, Baggage Loss or Delay, Medial Express, and more.  Our preferred travel insurance
                                    vendors: Allianz Travel Insurance, Travel Guard, TravelEx, CSA Travel Protection, Travel Safe.
                                </li>
                            </ul>
                        </div>

                        <div class='col-md-6' style={{ padding: "2vw", paddingTop: windowsInnerWidth > 750 ? "3vw" : "0vw", paddingBottom: "4vw" }}>
                            <ul style={{ fontFamily: "Gill Sans", fontSize: 16, lineHeight: 1.5, color: "black" }}>
                                <li class="">
                                    Seat assignments ARE at the discretion of the airline.  Seats for this group tour are only
                                    located in economy class. If you require a certain seat, we recommend booking the land only
                                    package.
                                </li>
                                <li class="">
                                    Prices are subject to change without notice. Please make your reservations today at this
                                    low price to be locked in.
                                </li>
                                <li class="">
                                    Monthly payments are required to keep your reservations current unless you make larger
                                    payments than what is required of your monthly payment plan.  Your invoice will clearly
                                    note your payment plan. We suggest you put this payment plan on your personal calendar.
                                </li>
                                <li class="">
                                    You must ensure your roommate, if applicable, is also making timely monthly payments.
                                </li>
                                <li class="">
                                    Roommate Matching is available.
                                </li>
                                <li class="">
                                    You can log-in to our client portal to view your account 24 hours a day. A link to your group
                                    page will be emailed to you after your initial deposit is paid.
                                </li>
                                <li class="">
                                    Name changes are allowed with a $200 name change fee up until the final payment date and $250
                                    after final payment date plus any vendor fees.
                                </li>
                                <li class="">
                                    Late payment of $250 per person is required after the final due date listed on your payment
                                    plan.  Late payment fees are automatically added to your reservation the day after your final
                                    due date.
                                </li>
                                <li class="">
                                    All late payment requests must be approved by management before the final payment
                                    deadline on your invoice.
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default TripPackages_ZANZIBAR;