import React, { Component } from 'react';
import '../../App.css';
import FadingGradient from '../components/FadingGradient';
import Heading from '../components/Heading';
import ImageComp from '../components/ImageComp';
import LatestNews from '../components/LatestNews';



class AboutScreen extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            headings: [
                {
                    title: "DATING AFRICAN",
                    titleStyle: { marginTop: 0, textAlign: "left", width: "100%", paddingLeft: "6%" },
                    text: [{ t: `There is something unique about the ability to travel and explore unseen geographical locations. Travel is adventurous whether it is locally or internationally. Kingdom Living by Dating African, LLC was established based on extensive travels through parts of the continent. We have been afforded the opportunity to experience the hospitality of the Continent. We journey to provide 5-star travel experiences for the clients we serve. We offer the opportunity for our travel customers to explore what has been hidden from the eyes of the people for hundreds of years. Africa has so much beauty, wealth and resources! We are inviting others to experience the hidden treasures of this country. `, type: "text", url: "", style: { textAlign: "left" } }],
                    linkText: "",
                    path: "",
                    style: "boldDialogue2",
                    externalUrlName: "",
                    externalUrl: "",
                    noHR: true,
                },
                {
                    title: "",
                    text: [
                        { t: "Africa is a unique continent. It is the world’s second largest and second-most populous continent, after Asia in both cases. There are about 1250-3000 native languages. How intriguing is that? There is no geographical location like it. During my first visit to the continent I fell in love with the sounds, the vibrant colors, the hospitable people, and the vivacious cultures. I have had a wealth of experiences that I desire to share with you (Business partnerships gone wrong, engagement gone wrong, building my business, language barriers, and more). I will continue to blog and share my experiences as I continue to journey through the continent.", type: "text", url: "" },
                        { t: "I have always wanted to create an inspirational blog site that empowers others to take control of their destinies. I just never knew what to really blog about that would hold my own personal attention and focus, LOL. One night I had a dream about exactly what I was to blog about. It was clear that I was to blog about Africa and my experiences. When it is time for you to do something, the Holy Spirit will always provide you with instructions that you cannot deny. It is my greatest hope that you will moved into action and that this blog will ignite a fire in you to explore other nations as I have been exploring Africa. This is “Dating African”!!!!", type: "text", url: "" }
                    ],
                    linkText: "",
                    path: "",
                    style: "boldUniqueDialogue",
                    externalUrlName: "",
                    externalUrl: "",
                },
                {
                    title: "",
                    noTitle: true,
                    text: [
                        { t: "", type: "image", url: require('../../assets/img/20210523_114708.jpg'), style: { width: "100%" } },
                        { t: "Meet LaTangela Rogers, CTA", type: "heading", url: "", style: { textAlign: "left", color: "white", fontSize: 30, paddingTop: 10, paddingBottom: 7 } },
                        { t: `LaTangela’s journey through Africa is what pushed her to become a certified travel agent. She began to travel through Africa in 2010 as a missionary and she has continued to follow the path set before her. She received her Travel Agent Vocational Certificate with California State University, San Bernardino.`, url: "", style: { textAlign: "left", color: "white", paddingTop: 10, paddingBottom: 7, letterSpacing: "0.25rem", fontSize: 15, lineHeight: 2.2 } },
                        { t: "Our Kingdom Services", type: "text", url: "", style: { textAlign: "left", color: "white", fontSize: 15, paddingTop: 15, paddingBottom: 7, fontWeight: "900" } },
                        {
                            t: "", type: "list", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, paddingLeft: 100 }, data: [
                                { t: "Africa Tours", type: "listChild", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontFamily: "Verdana" } },
                                { t: "African Safaris", type: "listChild", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontFamily: "Verdana" } },
                                { t: "Women’s Retreats", type: "listChild", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontFamily: "Verdana" } },
                                { t: "Group Travel", type: "listChild", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontFamily: "Verdana" } },
                                { t: "Faith Based Group Travel", type: "listChild", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontFamily: "Verdana" } },
                                { t: "Family Trips", type: "listChild", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontFamily: "Verdana" } },
                                { t: "Corporate Travel", type: "listChild", url: "", style: { textAlign: "left", color: "white", fontSize: 13, paddingTop: 10, paddingBottom: 7, fontFamily: "Verdana" } },
                            ]
                        },
                    ],
                    linkText: "",
                    path: "",
                    style: "ColumnRead",
                    columnType: ['col-md-3', 'col-md-8'],
                    columnType_isArray: true,
                    columnArray: [{ start: 0, finish: 1 }, { start: 1, finish: 11 }],
                    externalUrlName: "",
                    externalUrl: "",
                    CSS: { backgroundColor: "rgba(219,53,57,0.1)", paddingBottom: 20, paddingTop: 20 },
                    noHR: true,
                },
                {
                    title: "Royal Customer Service",
                    titleStyle: { textAlign: "left", width: "100%", paddingLeft: "6%" },
                    text: [
                        { t: "We offer a faster, more personalized travel planning experience. Our services include, but are not limited to:", type: "text", url: "", style: { textAlign: "left" } },
                        {
                            t: "", type: "list", url: "", style: { marginLeft: "5rem" }, data: [
                                { t: "Unparalleled inventory from consumer, direct and industry channels to guarantee the best rates and fares.", type: "listChild", url: "", style: { fontFamily: "Verdana", } },
                                { t: "Next-generation itinerate planning using our advanced technology system to arrange hotel and lodging accommodations, ground transportation, air travel, and business services arrangements. ", type: "listChild", url: "", style: { textAlign: "left", fontFamily: "Verdana" } },
                                { t: "Seamless connectivity to our custom app to provide mobile and desktop use. This will ensure easy itinerary management and rapid agent response. ", type: "listChild", url: "", style: { textAlign: "left", fontFamily: "Verdana" } },
                                { t: "We offer 24/7/365 global support through in-house, expert travel agents", type: "listChild", url: "", style: { textAlign: "left", paddingLeft: 0, fontFamily: "Verdana" } },
                                { t: "Powerful administration and communication capabilities for international duty-of-care. ", type: "listChild", url: "", style: { textAlign: "left", fontFamily: "Verdana" } },
                            ]
                        },
                    ],
                    linkText: "",
                    path: "",
                    style: "boldDialogue2",
                    externalUrlName: "",
                    externalUrl: "",
                    noHR: true,
                },
                {
                    title: "Meet LaTangela Rogers, CTA",
                    titleStyle: { marginTop: 0, textAlign: "left", width: "100%", paddingLeft: "20%", fontSize: 30 },
                    text: [{ t: "LaTangela’s journey through Africa is what pushed her to becoming a certified travel agent. She began to travel through Africa in 2010 as a missionary and she has continued to follow the path set before her. She received her Travel Agent Vocational Certificate with California State University, San Bernardino. ", type: "text", url: "", style: { textAlign: "left" } }],
                    linkText: "",
                    path: "",
                    style: "boldDialogue",
                    externalUrlName: "",
                    externalUrl: "",
                    noHR: true,
                },
                {
                    title: "ABOUT THE BLOGGER",
                    text: [
                        { t: "LaTangela Rogers, a woman of faith and integrity. She is passionate and committed to her calling as a community activist and entrepreneur.", type: "text", url: "" },
                        { t: "LaTangela has served her community for more than 15 years as a public speaker, teacher, radio personality, and program facilitator. She is the author of “Lord, Build Me a House”. She is the voice behind the online broadcast entitled “Morning Encounters, From His heart to our heart”. She previously hosted an online radio online radio broadcast on K.E.B.N Radio entitled “Fire”. She has now embarked on a new journey launching Kingdom Media Empowerment Network (K.M.E.N.) K.M.E.N, is a subsidiary brand under the 501(c)3 nonprofit organization Rose of Jericho Community Development. Rose of Jericho Community Development has been serving communities locally and internationally since 2015.", type: "text", url: "" },
                        { t: "LaTangela has a B.A. in Management and a M.A. in Human Behavior. She is currently pursuing her Ph.D. in Organizational Leadership-Behavioral Health.", type: "text", url: "" }
                    ],
                    linkText: "",
                    path: "",
                    style: "boldUniqueDialogue",
                    externalUrlName: "Follow on Instagram",
                    externalUrl: "https://www.instagram.com/inspiringtangie/",
                }
            ]
        }
    }

    render() {
        const { headings } = this.state
        const FirstHeading = headings.slice(0, 1);
        const secondHeading = headings.slice(2, 4);
        return (
            <>
                {/* <ImageComp imgUrl={require('../../assets/img/about.jpg')} /> */}
                {/* <FadingGradient color1="white" color2="rgb(207,207,207)" /> */}
                <section class="heading AboutMe">
                    <div className='AB_imgBG'>
                        <div className='AB_imgBG_child1 AB_imgBG_child'></div>
                        <div className='AB_imgBG_child2 AB_imgBG_child'></div>
                        <div className='AB_imgBG_child3 AB_imgBG_child'></div>
                        <div className='AB_imgBG_child4 AB_imgBG_child'></div>
                    </div>
                    <div className='AB_Head_glass1'></div>
                    <div className='AB_Head_glass2'></div>
                    <div className='AB_Head_glass3'>
                        <h1>LaTangela Rogers</h1>
                        <h2>Bridging Worlds of Travel and Investment</h2>
                    </div>
                </section>

                <section class="heading ABM_secton1Container">
                    <div className='ABM_secton1 row'>
                        <div className='ABM_S1_Img_Cont col-md-6'>
                            <div className='ABM_S1_Img'></div>
                        </div>
                        <div className='ABM_S1_Info_Cont col-md-6'>
                            <div className='ABM_S1_Info_Cont_'></div>
                            <p>
                                LaTangela Rogers is a dynamic and visionary entrepreneur whose passion for both the
                                realms of travel and investments has ignited a unique and impactful journey.
                                With a keen eye for opportunity and a heart that beats for exploration, she has
                                carved a distinctive path that merges these two worlds, creating a novel avenue
                                for travel enthusiasts to not only explore diverse destinations but also embark
                                on lucrative investment ventures.
                            </p>
                            <p>
                                In 2018, LaTangela's odyssey in the travel industry began, marked by her unyielding
                                commitment to redefine the way individuals perceive and engage with travel. Armed
                                with a vision to empower individuals with the ability to traverse the globe while
                                simultaneously forging pathways to financial prosperity, she embarked on a mission
                                that would shape her career trajectory in unforeseen ways.
                            </p>

                            <Line color="white" marginBottom={"3vw"}/>

                            <p>
                                LaTangela's pioneering spirit is deeply rooted in her aspiration to cultivate connections
                                between travelers and the world of investments. With unwavering determination, she recognized the
                                immense potential to unite these seemingly disparate spheres, thereby inspiring her to
                                create a bridge that offers unique opportunities for travel enthusiasts to become astute
                                investors and vice versa.
                            </p>
                            <p>
                                A testament to her dedication and expertise, LaTangela Rogers earned the prestigious
                                designation of Certified Travel Associate (CTA), a distinction bestowed upon her by
                                the Travel Institute. This certification stands as a testament to her mastery of the
                                intricacies of the travel industry, a testament to her unwavering commitment to
                                delivering unparalleled experiences to her clients.
                            </p>
                        </div>
                    </div>
                </section>

                <section class="heading ABM_secton2Container">
                    <div className='ABM_secton2 row'>
                        <div className='row'>
                            <div className='ABM_secton2_image1 col-md-6'>
                                <div className='ABM_secton2_image1_'></div>
                            </div>
                            <div className='ABM_secton2_col col-md-6'>
                                <p>
                                    Moreover, LaTangela's educational pursuits underscore her commitment to excellence.
                                    Her acquisition of a Travel Agent Vocational Certificate from California State
                                    University San Bernardino not only solidifies her knowledge base but also showcases
                                    her determination to continually expand her skill set and stay attuned to the
                                    ever-evolving landscape of the travel and investment sectors.
                                </p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='ABM_secton2_col col-md-6'>
                                <p>
                                    As a visionary trailblazer, LaTangela Rogers has seamlessly woven her passion,
                                    education, and experience into a tapestry that is as intricate as it is
                                    transformative. Her ingenuity lies in her ability to conceptualize and actualize
                                    a harmonious synergy between travel and investments, thereby creating a platform
                                    that beckons both novice and seasoned explorers to embark on a journey that
                                    transcends geographical boundaries and financial horizons.
                                </p>
                            </div>
                            <div className='ABM_secton2_image2 col-md-6'>
                                <div className='ABM_secton2_image2_'></div>
                            </div>
                        </div>
                        <div style={{paddingTop: 20}}>
                            <p>
                                In the ever-evolving world of travel and investments, LaTangela Rogers stands
                                as a beacon of innovation, a driving force behind a paradigm shift that is set
                                to redefine the way individuals engage with the world around them. With every
                                venture she undertakes, she continues to unravel new avenues, empowering others
                                to embrace the boundless possibilities that await at the crossroads of travel
                                and investment.
                            </p>
                        </div>
                    </div>
                </section>

                {/* <section class="heading ABM_secton3Container">
                    <div className='ABM_secton3 row'>
                        <div className='ABM_S3_Info_Cont col-md-6'>
                            <p>
                                In the ever-evolving world of travel and investments, LaTangela Rogers stands
                                as a beacon of innovation, a driving force behind a paradigm shift that is set
                                to redefine the way individuals engage with the world around them. With every
                                venture she undertakes, she continues to unravel new avenues, empowering others
                                to embrace the boundless possibilities that await at the crossroads of travel
                                and investment.
                            </p>
                        </div>
                        <div className='ABM_S3_Img_Cont col-md-6'>
                            <div className='ABM_S3_Img'></div>
                        </div>
                    </div>
                </section> */}
            </>
        );
    }
}



class Line extends React.Component {
    render() {
        const { width, height, color, marginTop, marginBottom } = this.props
        // const style = {}
        return (
            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: marginTop ? marginTop : 10, marginBottom: marginBottom ? marginBottom : 10 }}>
                <div style={{ width: width ? width : "50%", height: height ? height : 1, backgroundColor: color ? color : "brown", borderRadius: 2 }}></div>
            </div>
        )
    }
}


export default AboutScreen;
