import React, { useRef } from "react";
import { Link } from "react-router-dom";
import SearchField from "react-search-field";
import SearchBar from 'search-bar-react'


export default class LogIn_System extends React.Component {
    constructor() {
        super();
        this.state = {
            page: "logIn",
            feild_1: "",
            feild_2: "",
            feild_3: false,
            subscribe: false,
        }
    }

    changePage = (page) => {
        this.setState({page});
    }

    render_LogIn = () => {
        const { feild_1, feild_2, feild_3 } = this.state
        const { showLogIn, showLogIn_ } = this.props;
        return (
            <>
                <div class="col-md-8" style={{ opacity: showLogIn_ ? 1 : 0 }}>
                    <p class="Heading">Log In</p>
                    <p>Email</p>
                    <input class="log_in_box_input real_login" type="email" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1}></input>
                    <p>Password</p>
                    <input class="log_in_box_input real_login" type="password" onChange={(e) => { this.setState({ feild_2: e.target.value }) }} value={feild_2}></input>
                    <div style={{display: "flex", alignItems: "center", paddingLeft: 12}}>
                        <input style={{marginBottom: 5, marginLeft: 5, marginRight: 5}} type="checkbox" onChange={(e) => { this.setState({ feild_3: !feild_3 }) }} value={feild_3}></input>
                        <p style={{paddingLeft: 0, fontSize: "smaller"}}>Remember me</p>
                    </div>
                    <p class="LoginButton">Log In</p>
                    <p class="">Don't have an account yet ? <span onClick={() => {this.changePage("Register")}}>Register</span></p>

                </div>
                <div class="col-md-4">
                    <div></div>
                </div>
            </>
        )
    }

    render_Register = () => {
        const { feild_1, feild_2, feild_3, subscribe } = this.state
        const { showLogIn, showLogIn_ } = this.props;
        return (
            <>
                <div class="col-md-8" style={{ opacity: showLogIn_ ? 1 : 0 }}>
                    <p class="Heading">Register</p>
                    <p>Email</p>
                    <input class="log_in_box_input real_login" type="email" onChange={(e) => { this.setState({ feild_1: e.target.value }) }} value={feild_1}></input>
                    <p>Password</p>
                    <input class="log_in_box_input real_login" type="password" onChange={(e) => { this.setState({ feild_2: e.target.value }) }} value={feild_2}></input>
                    <p>Confirm Password</p>
                    <input class="log_in_box_input real_login" type="password" onChange={(e) => { this.setState({ feild_2: e.target.value }) }} value={feild_2}></input>
                    <div style={{display: "flex", alignItems: "flex-start", paddingLeft: 12}}>
                        <input style={{marginTop: 7, marginLeft: 5, marginRight: 5}} type="checkbox" onChange={(e) => { this.setState({ subscribe: !subscribe }) }} value={subscribe}></input>
                        <p style={{paddingLeft: 0, fontSize: "smaller"}}>Subscribe to our news update, to get notified of offers immediately they are made available</p>
                    </div>
                    <div style={{display: "flex", alignItems: "center", paddingLeft: 12}}>
                        <input style={{marginBottom: 5, marginLeft: 5, marginRight: 5}} type="checkbox" onChange={(e) => { this.setState({ feild_3: !feild_3 }) }} value={feild_3}></input>
                        <p style={{paddingLeft: 0, fontSize: "smaller"}}>Remember me</p>
                    </div>
                    <p class="LoginButton">Register</p>
                    <p class="">Already have an account ? <span onClick={() => {this.changePage("logIn")}}>Log In</span></p>

                </div>
                <div class="col-md-4">
                    <div></div>
                </div>
            </>
        )
    }



    render() {
        const { blog, page } = this.state
        const { showLogIn__, showLogIn_ } = this.props;
        const boxShadowClass = showLogIn__ ? " boxShadowClass" : ""
        return (
            <div class={"LogIn_System " + "LogIn_System_" + page + boxShadowClass} style={{ opacity: showLogIn_ ? 1 : 0, }}>
                <div class="LogIn_System_" style={{ width: showLogIn_ ? "100%" : "0%" }}>
                    {
                        page === "logIn" ?
                            this.render_LogIn()
                            :
                            this.render_Register()
                    }
                </div>
            </div>
        )
    }
}


