import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import '../../App.css';
import HeaderAnimComp from '../components/HeaderAnimComp';
import Heading from '../components/Heading';
import LatestNews from '../components/LatestNews';

const height = window.innerHeight;
const width = window.innerWidth;



class StartupView extends Component {
    constructor() {
        super();
        this.state = {
            thedarkMode: false,
            glassOpacity: 1,
            NavigateToHome: false,
            img_Position: -height,
            button_Position: height,
            Progress: 0,
            showCountDown: false,
            countDown: 10,
            countDownClass: "",
            videoMuted: true,
            showView: false
        }
    }

    componentDidMount = () => {
        this.startCountDown()
        setTimeout(() => {
            this.setState({
                img_Position: 0,
                button_Position: 0
            })
        }, 300);
        setTimeout(() => {
            this.setState({ glassOpacity: 0 })
        }, 3000);
    }

    onload = () => {
        setTimeout(() => {
            this.linkClick.click()
        }, 1000);
    }

    playVide = () => {
        this.pauseVid();
        setTimeout(() => {
            this.setState({videoMuted: false, showView: true})
            this.VideoRef.play();
        }, 1400);
        // alert("hello")
    }

    startCountDown = () => {
        const currentTime = this.VideoRef.currentTime;
        const countDown = this.state.countDown
        // const duration = 59
        // const currentProgress = (currentTime / duration) * 100;

        this.setState({
            Progress: Math.floor(currentTime)
        })
        if (currentTime >= 48) {
            this.setState({
                countDownClass: "countDown_show",
                showCountDown: true,
                countDown: countDown,
            })
            setTimeout(() => {
                this.setState({
                    countDownClass: "countDown_hide"
                })
                setTimeout(() => {
                    this.setState({
                        countDownClass: "countDown_show",
                        countDown: countDown-1,
                    })
                    this.startCountDown_v2()
                }, 700);
            }, 300);
        } else {
            setTimeout(() => {
                this.startCountDown()
            }, 1000);
        }
    }

    startCountDown_v2 = () => {
        const currentTime = this.VideoRef.currentTime;
        const countDown = this.state.countDown
        // const duration = 59
        // const currentProgress = (currentTime / duration) * 100;

        this.setState({
            Progress: Math.floor(currentTime)
        })
        setTimeout(() => {
            this.setState({
                countDownClass: "countDown_hide"
            })
            setTimeout(() => {
                this.setState({
                    countDownClass: "countDown_show",
                    countDown: countDown-1,
                })
                this.startCountDown_v2()
            }, 700);
        }, 300);


        setTimeout(() => {
            this.startCountDown_v2()
        }, 1000);
    }

    goHome = () => {
        this.setState({ NavigateToHome: true })
    }

    pauseVid = () => {
        this.VideoRef.pause()
    }
    handleProgress = (e) => {
        const currentProgress = this.VideoRef.currentTime;
        this.setState({
            Progress: currentProgress
        })
        // alert(currentProgress)
        // if (!isNaN(e.target.duration)){ 
        //     this.setState({
        //         Progress: (e.target.currentTime / e.target.duration) * 100
        //     })
        // }
    };

    render() {
        const { 
            glassOpacity, NavigateToHome, img_Position, button_Position, 
            countDownClass, showCountDown, countDown, videoMuted, showView, 
        } = this.state
        const divClass = showView ? "link_tST" : "";
        const divClass2 = showView ? "" : "link_tST";
        return (
            <header class="HeaderAnimComp" style={{ height, backgroundColor: "black" }}>
                {NavigateToHome ? <Navigate to={"/Home"} /> : null}

                <video
                    class="StartupVideo"
                    src={"https://dating-african.com/VideosArch/Dating_African.mp4"}
                    autoPlay={true}
                    // muted
                    onEnded={() => { this.goHome() }}
                    ref={VideoRef => this.VideoRef = VideoRef}
                    // onProgress={() => {this.handleProgress()}}
                    style={{height, width: "100%", objectFit: "cover"}}
                ></video>
                <div class="startUp_info">
                    <div class="glass" style={{ opacity: glassOpacity }}></div>
                    <img onLoad={()=>{ this.onload()}} src={require('../../assets/img/logo_startUp.png')} style={{ top: img_Position }} />
                    <div class="link_toStartUp_view"  style={{ top: button_Position }}>
                    <Link to={"/Home"} class={"link_toStartUp " + divClass2} onClick={() => { this.pauseVid() }}>Explore More! <i class="fa fa-arrow-right"></i> </Link>
                    <div class={"link_toStartUp " + divClass} onClick={() => { this.playVide() }}>Start <i class="fa fa-arrow-right"></i> </div>
                    </div>
                    {/* <h1 ref={linkClick => this.linkClick = linkClick} onClick={() => {this.playVide()}}>Hello</h1> */}
                    {showCountDown ? <div class={"countDown " + countDownClass}>
                        <p>Begin Exploring in</p>
                        <h1 style={{ color: "white" }}>{countDown}</h1>
                    </div> : null}
                </div>
            </header>
        );
    }
}

export default StartupView;
