import React, { Component } from 'react';
import AdsSystem from '../components/AdsSystem';
import FadingGradient from '../components/FadingGradient';
import Footer from '../components/Footer';
import READ from '../components/READ';
import AdvBenft from '../display/AdvBenft';
import NavBar from '../navBar';
import { useParams, } from "react-router";
import ElementCard from '../components/ElementCard';
import { OFFLINE_DB } from '../../presets';
import Heading from '../components/Heading';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class Blog extends Component {
    constructor() {
        super();
        this.state = {
            value: {},
            id_: false,
            data: false,
            headings: [
                {
                    title: "Leave a Reply",
                    text: [
                        { t: "Comment or Message", type: "textInput", url: "", style: { width: "100%", height: 200, backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25 } },
                        { t: "Name", type: "input", url: "", style: { width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25 } },
                        { t: "Email", type: "input", url: "", style: { width: "100%", backgroundColor: "rgba(255,255,255,0.2)", border: "1px solid rgba(0,0,0,0.2)", padding: 12, borderRadius: 7, marginBottom: 25 } },
                    ],
                    linkText: "",
                    path: "",
                    style: "dynamicForm",
                    externalUrlName: "",
                    externalUrl: "",
                }
            ]
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        let { id } = this.props.params;
        if (id) {
            const rV = id.replace('_', ' ');
            this.startProcess(rV);
        } else {
            this.setTo404();
        }
    }

    startProcess = (id) => {
        const DATA = OFFLINE_DB.filter(data => data.Title === id);
        if (DATA.length === 0) {
            this.setState({ id: true, data: false, })
        } else {
            this.setState({ value: DATA[0], id: true, data: true, })
        }
    }

    setTo404 = () => { }

    render() {
        const { value, data, headings } = this.state
        return (
            <>
                <FadingGradient color1="rgb(219,53,57)" color2="rgb(207,207,207)" />
                <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)" />
                <FadingGradient color1="rgb(207,207,207)" color2="rgb(207,207,207)" height={40} />
                <section class="heading" style={{ paddingBottom: 50 }}>
                    <div class={"section container lsec READ"}>
                        {
                            data ?
                                <div className="readSection">
                                    <ElementCard boostrapWidth={true} value={value} index={0} parentData={value} />
                                </div>
                                :
                                null
                        }
                    </div>
                    {headings.map((value, index, array) => {
                            return (
                                <Heading value={value} />
                            )
                        })}
                </section>
                <Footer parent="blog" styles={{ marginTop: 0 }} />
                <NavBar parent="blog" />
            </>
        );
    }
}

export default withParams(Blog);
