import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import dollarsToCents from "dollars-to-cents";

import CheckoutForm from "./CheckoutForm";
import "./App.css";

const stripePromise = loadStripe("pk_live_51LpN5uLX4fP9nDzXZXgKLhoCv5lupd26263LzoQv2SFaze0sYMkCW1oyDEh99YWihsWz1aRK9gilGlbHvyYB2ti800yKVm6xcF");

export default function ServerStripe({ props }) {
  const { selectedOption1, selectedOption2, selectedOption3, total, subTotal, checkOut } = props
  const [clientSecret, setClientSecret] = useState("");


  useEffect(() => {
    const formData = new FormData();
    const price = dollarsToCents(total);
    formData.append('price', JSON.stringify(price));
    const endpoint = "https://www.afrobuystore.com/AFROBUY/sample/create.php";
    axios.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(async (responseJson) => {
        // alert(JSON.stringify(responseJson.data))
        if (responseJson.data) {
          const clientSecret = responseJson.data;
          setClientSecret(clientSecret)
          // console.log(clientSecret)
        }
        // setClientSecret(data.clientSecret);
      })
      .catch(async (error) => {
        alert(error)
      })
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && stripePromise && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}