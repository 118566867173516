'use strict';

import React from 'react';
// import Home from './Site1/home'
import windowSize from 'react-window-size';
import Nav from './nav';
import Settings from './settings'


class Settingss extends React.Component {
  constructor() {
    super();
    this.state = {
      lang: "en",
      user: "",
      userData: "",
      showDialog: false,
      showSettings: false
    }
  }

  addElement =()=> {
    alert("newELEmet added");
  }


  render() {
    const { lang, user, userData, showDialog, showSettings, newSDH, newSDW, offlinePreset } = this.props.propss;
    const { fn_showDialog, fn_showSettings, hideSettings, logOut, hideDialog, change_settingsFullScreen } = this.props.fns;
    const halfWheight = window.innerHeight / 2;
    const fllScreen = offlinePreset.settingsFullScreen
    


    const newSetH = window.innerWidth > 991 ? newSDH : window.innerHeight - 20;
    const newSetW = window.innerWidth > 991 ? newSDW : window.innerWidth - 20;
    const newbottom = window.innerWidth > 991 ? 100 : 20;
    const newRight = window.innerWidth > 991 ? newSDW + 30 : 20;
    const boostrapWidth = fllScreen
    return (
      <>
        {
          user === "" ?
            null
            :
            <div
              style={{
                position: 'fixed', bottom: 65, right: showDialog === true ? -100 : 10,
                height: 40, width: 40, backgroundColor: "green", borderRadius: "100%",
                display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s",
                zIndex: 1000000
              }}
              onClick={() => { fn_showDialog() }}
            >
              <p><i class="fa fa-power-off"></i></p>
            </div>
        }
        {
          user === "" ?
            null
            :
            <div
              style={{
                position: 'fixed', bottom: 15, right: showDialog === true ? -160 : 70,
                height: 40, width: 40, backgroundColor: "green", borderRadius: "100%",
                display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s",
                zIndex: 1000000
              }}
              onClick={() => { fn_showSettings() }}
            >
              <p><i class="fa fa-plus"></i></p>
            </div>
        }

        <div
          style={{
            position: 'fixed', bottom: 25, right: showDialog === true ? 10 : -210,
            height: 50, width: 200, backgroundColor: "green", borderRadius: 6,
            display: "flex", justifyContent: "center", alignItems: "center", transition: "0.6s",
            zIndex: 10000000, boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.5)"
          }}
        >
          <p style={{ color: "whitesmoke", fontWeight: "bolder", fontSize: 16 }}>Log out</p>
          <p onClick={() => { logOut() }} style={{ color: "whitesmoke", marginLeft: 9, fontSize: 25 }}><i class="fa fa-check-circle"></i></p>
          <p onClick={() => { hideDialog() }} style={{ color: "whitesmoke", marginLeft: 9, fontSize: 25 }}><i class="fa fa-times-circle"></i></p>
        </div>

        <div
          style={{
            position: 'fixed',
            bottom: showSettings === true ? 10 : 30,
            right: showSettings === true ? 10 : 90,
            height: showSettings === true ? newSetH : 0,
            width: showSettings === true ? newSetW : 0,
            backgroundColor: "rgba(245, 245, 245, 0.95)",
            borderRadius: showSettings === true ? 6 : 400,
            display: "flex", justifyContent: "center",
            alignItems: "center", transition: "0.2s",
            zIndex: 20000000, boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.5)",
            overflow: "hidden"
          }}
          class="settingsBox"
        >
          <div
            style={{
              opacity: showSettings === true ? 1 : 0, transition: "0.6s",
              height: "100%", width: "100%", overflow: "hidden"
            }}
          >
            <Settings boostrapWidth={boostrapWidth}/>
            <Nav fn={{change_settingsFullScreen, hideSettings, fllScreen, addElement: this.addElement}}/>

          </div>
        </div>
        {/* <div class="settings_edit" style={{bottom: newbottom, right: newRight}}></div> */}
      </>
    );
  }
}

export default windowSize(Settingss);
// export default App;




